@import '/src/styles/media.scss';

.boardpagenationBtn {
  display: flex;
  justify-content: center;
  margin: 24px 0 80px 0;

  button {
    font-size: var(--fs_14);
    padding: 0 8px;
    @include tablet {
      font-size: var(--fs_18);
      padding: 0 10px;
    }

    &.current,
    &:hover {
      color: var(--white_ff);
      background-color: var(--main_green);
    }
    &.goFirst,
    &.goPrev,
    &.goNext,
    &.goEnd {
      width: 24px;
      height: 24px;
      @include tablet {
        width: 32px;
        height: 32px;
      }
    }
    &.goFirst,
    &.goEnd {
      background: url('/assets/svgs/containers/ox/buySemen/end_button.svg')
        no-repeat;
      background-size: 7px 13px;
      @include tablet {
        background-size: 9px 19px;
      }
    }
    &.goPrev,
    &.goNext {
      background: url('/assets/svgs/containers/ox/buySemen/count_button.svg')
        no-repeat;
      background-size: 7px 13px;
      @include tablet {
        background-size: 9px 17px;
      }
    }
    &.goEnd,
    &.goNext {
      transform: rotateZ(180deg);
    }
    &.goFirst {
      margin-right: 6px;
      background-position: center 7px;
      @include tablet {
        background-position: center 9px;
      }
    }
    &.goPrev {
      margin-right: 12px;
      background-position: center 7px;
      @include tablet {
        background-position: center 9px;
      }
    }
    &.goNext {
      margin-left: 12px;
      background-position: center 5px;
      @include tablet {
        background-position: center 6px;
      }
    }
    &.goEnd {
      margin-left: 12px;
      background-position: center 5px;
      @include tablet {
        background-position: center 6px;
      }
    }
  }
}

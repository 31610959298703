@import '/src/styles/media.scss';
@keyframes scrollAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 30px));
  }
}

.banner {
  border-top: solid 1px var(--main_gray);
  display: flex;
  gap: 30px;
  overflow: hidden;
  &:hover ul {
    animation-play-state: paused;
    &.shadow {
      animation-play-state: paused;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 10px 0;
    animation: scrollAnimation 20s linear infinite;
    
    @include tablet {
      padding: 20px 0;
    }
  
    li {

      &:nth-child(1) {
        a {
          width: 128px;
          height: 23px;
          background: url('/assets/images/common/banner/KMPA_mo.png') no-repeat center/cover;
          @include tablet {
            width: 158px;
            height: 28px;
            background: url('/assets/images/common/banner/KMPA_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(2) {
        a {
          width: 93px;
          height: 22px;
          background: url('/assets/images/common/banner/MoA_mo.png') no-repeat center/cover;
          @include tablet {
            width: 119px;
            height: 28px;
            background: url('/assets/images/common/banner/MoA_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(3) {
        a {
          width: 99px;
          height: 18px;
          background: url('/assets/images/common/banner/NH_mo.png') no-repeat center/cover;
          @include tablet {
            width: 109px;
            height: 20px;
            background: url('/assets/images/common/banner/NH_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(4) {
        a {
          width: 114px;
          height: 24px;
          background: url('/assets/images/common/banner/NIoAS_mo.png') no-repeat center/cover;
          @include tablet {
            width: 139px;
            height: 29px;
            background: url('/assets/images/common/banner/NIoAS_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(5) {
        a {
          width: 74px;
          height: 22px;
          background: url('/assets/images/common/banner/RDA_mo.png') no-repeat center/cover;
          @include tablet {
            width: 87px;
            height: 26px;
            background: url('/assets/images/common/banner/RDA_mo.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(6) {
        a {
          width: 132px;
          height: 27px;
          background: url('/assets/images/common/banner/NAQMA_mo.png') no-repeat center/cover;
          @include tablet {
            width: 186px;
            height: 38px;
            background: url('/assets/images/common/banner/NAQMA_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(7) {
        a {
          width: 51px;
          height: 20px;
          background: url('/assets/images/common/banner/theMA_mo.png') no-repeat center/cover;
          @include tablet {
            width: 97px;
            height: 38px;
            background: url('/assets/images/common/banner/theMA_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(8) {
        a {
          width: 89px;
          height: 29px;
          background: url('/assets/images/common/banner/DPA_mo.png') no-repeat center/cover;
          @include tablet {
            width: 122px;
            height: 40px;
            background: url('/assets/images/common/banner/DPA_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(9) {
        a {
          width: 112px;
          height: 25px;
          background: url('/assets/images/common/banner/FLQH_mo.png') no-repeat center/cover;
          @include tablet {
            width: 171px;
            height: 39px;
            background: url('/assets/images/common/banner/FLQH_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(10) {
        a {
          width: 121px;
          height: 26px;
          background: url('/assets/images/common/banner/FDS_mo.png') no-repeat center/cover;
          @include tablet {
            width: 167px;
            height: 36px;
            background: url('/assets/images/common/banner/FDS_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(11) {
        a {
          width: 56px;
          height: 21px;
          background: url('/assets/images/common/banner/NSO_mo.png') no-repeat center/cover;
          @include tablet {
            width: 94px;
            height: 36px;
            background: url('/assets/images/common/banner/NSO_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(12) {
        a {
          width: 150px;
          height: 22px;
          background: url('/assets/images/common/banner/KDBA_mo.png') no-repeat center/cover;
          @include tablet {
            width: 221px;
            height: 32px;
            background: url('/assets/images/common/banner/KDBA_pc.png') no-repeat center/cover;
          }
        }
      }
      &:nth-child(13) {
        a {
          width: 87px;
          height: 23px;
          background: url('/assets/images/common/banner/KRERI_mo.png') no-repeat center/cover;
          @include tablet {
            width: 130px;
            height: 34px;
            background: url('/assets/images/common/banner/KRERI_pc.png') no-repeat center/cover;
          }
        }
      }
    }
  }
}
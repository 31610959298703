@import '/src/styles/media.scss';

.visit {
  .subTitle {
    @include desktop {
      margin: 100px 0 40px 0;
    }
  }
  #map {
    height: 400px;
    border: solid 1px var(--gray_de);
  }
  .mapBtn {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 12px;
    border: solid 1px var(--gray_de);
    background-color: var(--gray_f8);

    a,
    button {
      display: block;
      font-size: var(--fs_14);
      text-decoration: underline;
      @include tablet {
        font-size: var(--fs_16);
      }
    }
  }
  .visitInfo {
    margin: 40px 0 80px 0;
    text-align: center;
    @include desktop {
      display: flex;
      justify-content: flex-end;
    }

    > img {
      margin-bottom: 24px;
      max-width: 165px;
      max-height: 40px;
    }

    .visitInfoList {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      width: 100%;
      > li {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h4 {
          text-align: start;
          color: var(--main_green);
        }
        .visitInfoItem {
          display: flex;
          flex-direction: column;
          gap: 16px;

          li {
            display: flex;
            align-items: center;
            gap: 24px;
            text-align: start;
            font-size: var(--fs_14);
            @include tablet {
              font-size: var(--fs_16);
            }

            img {
              width: 38px;
              height: 38px;
              align-self: flex-start;
            }
          }
        }
      }
    }
  }
}

@import '/src/styles/media.scss';

.department {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px;
  margin: 40px 20px 80px 20px;
  background-color: var(--gray_fb);
  border: solid 1px var(--sub_gray);
  font-size: var(--fs_14);
  @include tablet {
    flex-direction: row;
    gap: 30px;
    padding: 15px 20px;
  }
  @include desktop {
    max-width: 1440px;
    margin: 40px auto;
  }

  li {
    dl {
      display: flex;
      gap: 36px;

      dt {
        position: relative;
        padding-left: 30px;
        
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-40%);
          right: -16px;
          width: 1px;
          height: 12px;
          background-color: var(--main_right_gray);
        }
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 22px;
          height: 22px;
          background: url('/assets/svgs/containers/performance/call.svg') no-repeat center/cover;
        }
      }
    }
    &:last-child {
      dt {
        &::after {
          background: url('/assets/svgs/containers/performance/department.svg') no-repeat center/cover;
        }
      }
    }
  }
}
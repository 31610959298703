@import '/src/styles/media.scss';

.buySemen {
  
  .subTitle {
    display: none;
  }
  .buySemenTab {
    display: flex;
    justify-content: center;
    gap: 14px;
    margin-top: 24px;
    @include tablet {
      justify-content: flex-start;
      margin-top: 40px;
      padding-left: 20px;
    }
    @include desktop {
      padding-bottom: 30px;
      max-width: 1440px;
      margin: 40px auto 0;
      padding-left: 0;
    }

    li {
      button,
      a {
        display: inline-block;
        font-weight: var(--fw_bold);
        font-size: var(--fs_12);
        color: var(--white_ff);
        border: solid 1px var(--main_green);
        border-radius: 4px;
        padding: 4px 8px;
        background-color: var(--main_green);

        &:hover {
          color: var(--main_green);
          background-color: var(--white_ff);
        }
        @include tablet {
          font-size: var(--fs_20);
          border: solid 2px var(--main_green);
        }
      }
    }
  }
  .buySemenInner {
    background-color: var(--gray_fb);
    padding: 20px 20px 40px 20px;
    margin: 20px 0 40px 0;
    @include tablet {
      padding: 40px 20px 40px 20px;
      margin: 40px 0;
    }
    @include desktop {
      padding: 90px 0;
      margin: 0 0 40px 0;
    }

    h5 {
      font-size: var(--fs_14);
      color: var(--main_right_gray);
      margin-bottom: 20px;
      @include tablet {
        font-size: var(--fs_20);
        margin-bottom: 24px;
      }
      @include desktop {
        max-width: 1440px;
        margin: 0 auto 30px;
      }
    }
    .tablebox {
      @include desktop {
        max-width: 1440px;
        margin: 0 auto;
      }
      thead {
        tr {
          th {
            &:nth-child(1) {
              width: 8%;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 10%;
            }
            &:nth-child(5) {
              width: 10%;
            }
            &:nth-child(6) {
              width: 12%;
            }
            &:nth-child(7) {
              width: 30%;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            button {
              color: var(--main_green);
              text-decoration: underline;
              font-size: 12px;
              @include tablet {
                font-size: 14px;
              }
              @include desktop {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .buySemenBtn {
      position: relative;
      margin-top: 20px; 
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      @include desktop {
        flex-direction: row;
        justify-content: center;
        max-width: 1440px;
        margin: 50px auto 0;
      }

      .pagenationBtn {
        display: flex;
        button {
          width: 24px;
          height: 24px;
          background-color: var(--gray_fb);
          font-size: var(--fs_14);
          @include tablet {
            width: 32px;
            height: 32px;
            font-size: var(--fs_18);
          }

          &.current,
          &:hover {
            color: var(--white_ff);
            background-color: var(--main_green);
            font-weight: var(--fw_bold);
          }
          &.goFirst,
          &.goEnd {
            background: url('/assets/svgs/containers/ox/buySemen/end_button.svg') no-repeat;
            background-size: 7px 13px;
            @include tablet {
              background-size: 9px 19px;
            }
          }
          &.goPrev,
          &.goNext {
            background: url('/assets/svgs/containers/ox/buySemen/count_button.svg') no-repeat;
            background-size: 7px 13px;
            @include tablet {
              background-size: 9px 17px;
            }
          } 
          &.goEnd,
          &.goNext{
            transform: rotateZ(180deg);
          }
          &.goFirst{
            margin-right: 6px;
            background-position: center 7px;
            @include tablet {
              background-position: center 9px;
            }
          }
          &.goPrev {
            margin-right: 12px;
            background-position: center 7px;
            @include tablet {
              background-position: center 9px;
            }
          }
          &.goNext {
            margin-left: 12px;
            background-position: center 5px;
            @include tablet {
              background-position: center 6px;
            }
          }
          &.goEnd {
            margin-left: 12px;
            background-position: center 5px;
            @include tablet {
              background-position: center 6px;
            }
          }
        }
      }
      .buySemenOrderBtn {
        color: var(--white_ff);
        font-size: 18px;
        font-weight: var(--fw_bold);
        padding: 12px 20px;
        background-color: var(--main_blue);
        @include desktop {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    } 
  }
  .inputPassword {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.7);
    z-index: var(--2000);

    .inputPasswordInner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--gray_f8);
      width: 90%;
      padding: 40px 0 30px 0;
      @include tablet {
        width: 60%;
      }
      @include desktop {
        width: 600px;
      }

      p {
        font-size: var(--fs_14);
        text-align: center;
        color: var(--main_gray);
        margin-bottom: 20px;
        @include tablet {
          font-size: var(--fs_18);
        }
        @include desktop {
          margin-bottom: 30px;
          font-size: var(--fs_24);
        }
      }
      .inputArea {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        white-space: nowrap;
        gap: 8px;

        input {
          flex: 1;
          border: solid 1px var(--gray_dd);
        }
        button {
          font-size: var(--fs_12);
          padding: 4px 8px;
          background-color: var(--main_blue);
          color: var(--gray_f8);
          @include tablet {
            font-size: var(--fs_16);
            padding: 6px 12px;
          }
          @include desktop {
            font-size: var(--20);
            padding: 8px 14px;
          }
        }
      }
      .close {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 12px;
        height: 12px;
        background: url('/assets/svgs/common/X_button.svg') no-repeat center / cover;
        @include tablet {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.newNoninschkviewPdf {
    position: relative;
    max-width: 1440px;
    min-width: 793px;
    min-height: 1122px;
    margin: 0 auto;
    padding: 40px 30px 80px;
    background-color: var(--white_ff);
  
    h1 {
      text-align: center;
      font-size: var(--fs_28);
      font-weight: var(--fw_normal);
      margin-bottom: 20px;
    }
    
    .pdfTable {
      margin-top: 20px;

      thead {
        th {
          border:solid 1px var(--main_black)
        }
      }
      tbody {
        tr {
          td {
            border:solid 1px var(--main_black);
          
          }
        }
      }   
    }

    .barChartArea {
      background-color: var(--gray_df);

    
      .noninschkLabel {
        width: 60%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        gap: 4px;
        margin: 20px auto 0;
        font-size: var(--fs_14);

        li {
          position: relative;
          padding-left: 20px;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }
          &:nth-child(1) {
            &::before {
              background-color: violet;
            }
          }
          &:nth-child(2) {
            &::before {
              background-color: red;
            }
          }
          &:nth-child(3) {
            &::before {
              background-color: green;
            }
          }
          &:nth-child(4) {
            &::before {
              background-color: yellow;
            }
          }
          &:nth-child(5) {
            &::before {
              background-color: blue;
            }
          }
          &:nth-child(6) {
            &::before {
              background-color: gray;
            }
          }
          &:nth-child(7) {
            &::before {
              background-color: purple;
            }
          }
          &:nth-child(8) {
            &::before {
              background-color: cyan;
            }
          }
          &:nth-child(9) {
            &::before {
              background-color: darkblue;
            }
          }
        }
      }
    }
    .exp {
      margin-top:20px;
      font-size:var(--fs_14);
    }


  }
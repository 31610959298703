@import '/src/styles/media.scss';

.oxData {
  // 공통
  table {
    th {
      background-color: var(--sub_gray);
      color: var(--main_black);
      font-weight: var(--fw_normal);
    }
  }
  .subTitle {
    display: none;
  }
  .oxDataTab {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 24px;
    @include tablet {
      justify-content: flex-start;
      margin-top: 40px;
      gap: 16px;
    }
    @include desktop {
      max-width: 1440px;
      margin: 40px auto 0;
      padding-left: 0;
    }

    li {
      button {
        font-weight: var(--fw_bold);
        font-size: var(--fs_12);
        padding: 4px 8px;
        border: solid 1px var(--main_green);
        border-radius: 4px;
        color: var(--main_green);
        background-color: var(--white_ff);
        &:hover,
        &.current {
          color: var(--white_ff);
          background-color: var(--main_green);
        }
        @include tablet {
          font-size: var(--fs_20);
          border: solid 2px var(--main_green);
        }
      }
    }
  }
  .ox1 {
    .ox1Title {
      margin: 24px 0;
      @include desktop {
        margin: 120px 0 30px 0;
      }

      dt {
        font-size: var(--fs_14);
        color: var(--main_right_gray);
        font-weight: var(--fw_bold);
        margin-bottom: 16px;
        @include tablet {
          font-size: var(--fs_20);
          margin-bottom: 24px;
        }
      }
      dd {
        font-weight: var(--fw_bold);
        @include tablet {
          font-size: var(--fs_40);
        }

        span {
          display: block;
          @include desktop {
            display: inline;
          }
        }
      }
    }
    img {
      width: 100%;
    }
    .ox1Info {
      display: flex;
      flex-direction: column;
      gap: 80px;
      margin: 24px 0 80px 0;
      @include desktop {
        margin: 30px 0 120px 0;
      }

      > li {
        @include tablet {
          display: flex;
          gap: 40px;
        }
        @include desktop {
          gap: 200px;
        }
        > span {
          display: inline-block;
          font-size: var(--fs_14);
          font-weight: var(--fw_bold);
          margin-bottom: 8px;
          @include tablet {
            font-size: var(--fs_20);
          }
        }
        p,
        ul {
          @include tablet {
            flex: 1;
          }
        }
        p {
          font-size: var(--fs_14);
          line-height: var(--lh_170);
          @include tablet {
            font-size: var(--fs_18);
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 50px;
          @include tablet {
            gap: 80px;
          }
          @include desktop {
            flex: none;
            width: 955px;
            margin-left: auto;
          }

          li {
            font-size: var(--fs_14);
            @include tablet {
              font-size: var(--fs_18);
            }

            img {
              width: 100%;
              max-width: 347px;
            }
            strong {
              display: block;
              color: var(--main_green);
              margin: 16px 0 8px 0;
              @include tablet {
                font-size: var(--fs_20);
                margin: 24px 0 16px 0;
              }
              @include desktop {
                margin: 30px 0;
              }
            }
            p {
              line-height: var(--lh_170);
              color: var(--main_gray);

              span {
                font-weight: var(--fw_medium);
                color: var(--main_black);
              }
            }
          }
        }
      }
    }
  }
  .ox2 {
    margin-bottom: 40px;
    @include desktop {
      margin-bottom: 120px;
    }
    strong {
      display: block;
      font-size: var(--fs_14);
      font-weight: var(--fw_bold);
      color: var(--main_right_gray);
      margin: 24px 0;
      @include tablet {
        margin: 80px 0 24px 0;
        font-size: var(--fs_20);
      }
      @include desktop {
        margin: 120px 0 40px 0;
      }
    }

    .oxBook {
      width: 100%;
      @include tablet {
        background-color: var(--gray_fb);
        border: solid 1px var(--gray_dd);
        padding: 65px 0 45px 0;
        text-align: center;
      }
      img {
        width: 600px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 40px;
      @include desktop {
        margin-top: 20px;
      }

      li {
        dl {
          dt {
            font-size: var(--fs_14);
            font-weight: var(--fw_bold);
            color: var(--main_gray);
            @include tablet {
              font-size: var(--fs_18);
            }

            span {
              display: inline-block;
              text-align: center;
              line-height: 20px;
              width: 20px;
              height: 20px;
              margin-right: 4px;
              font-size: 12px;
              border-radius: 50%;
              background-color: var(--main_green);
              color: var(--white_ff);
              @include tablet {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 24px;
                margin-right: 16px;
              }
              @include desktop {
                margin-right: 80px;
              }
            }
          }
          dd {
            font-size: var(--fs_14);
            line-height: var(--lh_170);
            padding-left: 20px;
            margin-top: 8px;
            @include tablet {
              font-size: var(--fs_18);
              padding-left: 60px;
              margin-bottom: 20px;
            }
            @include desktop {
              padding-left: 120px;
            }

            &.unique {
              span {
                display: block;
                color: var(--main_blue);
                font-weight: var(--fw_bold);
              }
            }

            img {
              width: 100%;
              max-width: 586px;
            }
          }
        }
      }
    }
  }
  .ox3 {
    margin-bottom: 40px;
    @include tablet {
      margin-bottom: 60px;
    }
    @include desktop {
      margin-bottom: 80px;
    }
    strong {
      display: block;
      margin: 24px 0;
      font-size: var(--fs_14);
      color: var(--main_right_gray);
      @include tablet {
        margin: 80px 0 24px 0;
        font-size: var(--fs_20);
      }
      @include desktop {
        margin: 120px 0 30px 0;
      }
    }
    .ox3Title {
      font-weight: var(--fw_bold);
      @include tablet {
        font-size: var(--fs_40);
      }

      span {
        display: block;
        @include desktop {
          display: inline;
        }
      }
    }
    .ox3Text {
      font-size: var(--fs_14);
      line-height: var(--lh_170);
      margin-top: 8px;
      @include tablet {
        font-size: var(--fs_18);
        margin-top: 24px;
      }
      @include desktop {
        width: 955px;
      }
    }
    .ox3Titleimg {
      text-align: center;
      margin: 40px 0 80px 0;
      @include tablet {
        margin: 60px 0 120px 0;
      }

      img {
        width: 100%;
        max-width: 575px;
      }
    }
    .plan {
      > span {
        display: block;
        font-size: var(--fs_14);
        font-weight: var(--fw_bold);
        margin-bottom: 8px;
        @include tablet {
          font-size: var(--fs_20);
          margin-bottom: 16px;
        }
      }
      > ul {
        display: flex;
        flex-direction: column;
        gap: 60px;
        @include desktop {
          gap: 120px;
        }

        li {
          > span {
            display: block;
            position: relative;
            font-size: var(--fs_14);
            padding-left: 24px;
            margin-bottom: 12px;
            @include tablet {
              font-size: var(--fs_20);
              margin-bottom: 36px;
            }
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 7px;
              width: 9px;
              height: 9px;
              border-radius: 50%;
              background-color: var(--main_green);
            }
          }

          &.oxSquence {
            img {
              width: 100%;
              max-width: 728px;
            }
          }
          &.manyTable {
            ul {
              li {
                &:nth-child(1) {
                  margin-bottom: 40px;
                  @include desktop {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .tablebox {
                      width: 60%;
                    }
                  }
                }
                small {
                  display: block;
                  font-size: var(--fs_14);
                  color: var(--main_blue);
                  padding-left: 20px;
                  @include tablet {
                    font-size: var(--fs_18);
                  }
                }
                .imgbox {
                  text-align: center;
                  margin-bottom: 40px;
                  img {
                    max-width: 307px;
                    margin: 8px 0 24px 0;
                  }
                }
                .tableArea {
                  margin-top: 8px;
                }
              }
            }
          }
          &.oxGuide {
            ul {
              background-color: var(--gray_fb);
              padding: 10px;
              font-size: var(--fs_14);
              line-height: var(--lh_170);
              display: flex;
              flex-direction: column;
              gap: 12px;
              @include tablet {
                font-size: var(--fs_18);
              }

              li {
                position: relative;
                padding-left: 8px;

                &::before {
                  content: '';
                  position: absolute;
                  top: 10px;
                  left: 0;
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background-color: var(--main_green);
                  @include tablet {
                    top: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
    img {
      width: 100%;
    }
  }
}

@import '/src/styles/media.scss';

.sideBarMo {
  width: 100%;
  
  .sideBarList {
    display: flex;
    align-items: stretch;

    .sideBarItem {
      position: relative;
      width: calc(50% - 15px);
      flex: 1;
      display: flex;
      flex-direction: column;
      @include tablet {
        width: calc(50% - 20px);
      }
      
      &.on {
        .currentTwoDepth,
        .currentThreeDepth {
          &::before {
            transform: translateY(-50%) rotateZ(180deg);
          }
        }
        .sideBarTwoDepth,
        .sideBarThreeDepth {
          transition: all .5s;
          max-height: 1000px;
          box-shadow: 2px 2px 4px 1px rgba(0,0,0,.1);
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
      &.home {
        max-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        background-color: var(--main_blue);
        @include tablet {
          max-width: 40px;
        }
        
        a {
          padding: 7px 8px;
          display: inline-flex;
          @include tablet {
            padding: 9px 10px; 
          }

          img {
            width: 14px;
            height: 16px;
            @include tablet {
              width: 20px;
              height: 21px;
            }
          }
        }
      }

      .currentTwoDepth {
        border-right: solid 1px var(--sub_gray);
      }
      .currentTwoDepth,
      .currentThreeDepth {
        height: 30px;
        position: relative;
        width: 100%;
        padding: 5px 20px 5px 5px;
        background-color: var(--white_ff);
        border-bottom: solid 1px var(--sub_gray);
        text-align: start;
        font-size: var(--fs_14);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include tablet {
          height: 40px;
          padding: 0;
          text-align: center;
          font-size: var(--fs_18);
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%) rotateZ(0);
          width: 13px;
          height: 8px;
          background: url('/assets/svgs/common/v_icon_gray.svg') no-repeat center/cover;
        }
      }

      .sideBarTwoDepth,
      .sideBarThreeDepth {
        max-height: 0;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: var(--white_ff);
        @include tablet {
          top: 40px;
        }
    
        a {
          padding: 6px 5px;
          font-size: var(--fs_14);
          @include tablet {
            padding: 10px;
            font-size: var(--fs_18);
          }

          &:hover {
            background-color: var(--main_blue);
            color: var(--white_ff);
          }
        }
      }
    }
  }

  @include desktop {
    display: none;
  }
}
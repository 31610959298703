@import '/src/styles/media.scss';
.cowInfoCon {
  padding: 0 20px;
  position: relative;
  @include desktop {
    min-width: calc(100% - 43%);
    padding: 0;
  }
  .cow-info-container {
    background-color: #edeff4;
    border-radius: 16px;
    padding: 20px 15px;
    @include desktop {
      padding: 0;
    }
    .controls {
      margin-top: 10px;
      @include desktop {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 30px;
        margin-right: 40px;
      }
      button {
        padding: 5px 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}

@import '/src/styles/media.scss';

.genePrint {
  .genePrintHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--gray_fb);

    h1 {
      width: 128px;
      height: 30px;
      background: url('/assets/images/common/header_logo_mo.png') no-repeat
        center / cover;
      @include desktop {
        width: 165px;
        height: 40px;
        background: url('/assets/images/common/header_logo_pc.png') no-repeat
          center / cover;
      }
    }
    button {
      padding: 4px 8px;
      background-color: var(--admin_black);
      color: var(--white_ff);
      border-radius: 4px;
    }
  }
  .genePrintInner {
    padding: 10px;

    .genePrintInfo {
      display: flex;
      flex-direction: column;

      li {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
        padding-left: 15px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: var(--main_green);
        }

        h2 {
          font-weight: var(--fw_normal);
        }
      }
    }
    .genePrintTable {
      margin: 20px 0;
      border-collapse: collapse;
      border: solid 1px var(--admin_gray);
      text-align: center;
      
      th {
        padding: 4px 0;
        font-size: var(--fs_16);
        font-weight: var(--fw_medium);
        border: solid 1px var(--admin_gray);
        background-color: var(--main_blue);
        color: var(--white_ff);
      }
      td {
        font-size: var(--fs_14);
        padding: 2px 0;
        border: solid 1px var(--admin_gray);
      }
    }
    .genePrintChart {
      > span {
        font-size: var(--fs_16);
      }
      
      .genePrintChartTable {
        border-collapse: collapse;
        border: solid 1px var(--admin_gray);
        text-align: center;
        
        th {
          padding: 4px 0;
          font-size: var(--fs_16);
          font-weight: var(--fw_medium);
          border: solid 1px var(--admin_gray);
          background-color: var(--main_blue);
          color: var(--white_ff);
        }
        td {
          position: relative;
          font-size: var(--fs_14);
          padding: 2px 0;
          border: solid 1px var(--admin_gray);

          span {
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 80%;
          }
        }
        thead {
          tr {
            th {
              &:nth-child(1) {
                width: 10%;
              }
              &:nth-child(2) {
                width: 20%;
              }
              &:nth-child(3) {
                width: 10%;
              }
              &:nth-child(4) {
                width: 8%;
              }
              &:nth-child(5) {
                width: 7%;
              }
              &:nth-child(6) {
                width: 7%;
              }
              &:nth-child(7) {
                width: 7%;
              }
              &:nth-child(8) {
                width: 7%;
              }
              &:nth-child(9) {
                width: 7%;
              }
              &:nth-child(10) {
                width: 7%;
              }
              &:nth-child(11) {
                width: 10%;
              }
            }
          }
        }
      }
    }
  }
}
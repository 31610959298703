@import '/src/styles/media.scss';

.sideBarPc {
  display: none;
}
@include desktop {
  .sideBarPc {
    display: block;
    width: 1440px;
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    font-size: var(--fs_18);
    font-weight: var(--fw_bold);
    z-index: var(--998);
    a {
      color: var(--main_gray);
    }

    .sideBarList {
      display: flex;
      align-items: stretch;
      
      .sideBarItem {
        width: 255px;
        position: relative;

        &.home {
          width: 41px;
          a {
            padding: 0 20px 0 0;
          }
        }
        &:hover {
          cursor: pointer;
        }
        &::before {
          content: '';
          width: 1px;
          height: 20px;
          background-color: var(--white_ff);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
        &.on {
          .currentTwoDepth,
          .currentThreeDepth {
            &::before {
              transform: translateY(-50%) rotateZ(180deg);
            }
          }
          .sideBarTwoDepth,
          .sideBarThreeDepth {
            box-shadow: 2px 2px 4px 1px rgba(0,0,0,.1) ;
            transition: max-height linear .5s;
            max-height: 600px;
          }
        }

        .currentTwoDepth,
        .currentThreeDepth {      
          width: 100%;
          padding: 0 20px;
          color: var(--white_ff);
          font-size: var(--fs_18);
          font-weight: var(--fw_bold);
          text-align: start;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotateZ(0);
            right: 20px;
            width: 15px;
            height: 9px;
            background: url('/assets/svgs/common/v_icon_white.svg') no-repeat center/cover;
          }
        }
        .sideBarTwoDepth,
        .sideBarThreeDepth {
          position: absolute;
          left: 0;
          right: 0;
          top: calc(100% + 10px);
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-color: var(--white_ff);
          overflow: hidden;
          max-height: 0;

          a {
            padding: 5px 10px;
            transition: all .3s;
            &:hover {
              background-color: var(--main_gray);
              color: var(--gray_f8);
            }
          }
        }
      }
    }
  }
}
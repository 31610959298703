.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-top: 1rem;

  .page-btn {
    min-width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    border: none;
    background: none;
    color: #4a5568;
    font-size: 0.813rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s;

    &:hover:not(:disabled) {
      background: #f7fafc;
      color: #2d3748;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.active {
      background: #2d3748;
      color: white;
      font-weight: 600;
    }

    &.first::before {
      content: '<<';
    }

    &.prev::before {
      content: '<';
    }

    &.next::before {
      content: '>';
    }

    &.last::before {
      content: '>>';
    }
  }

  .page-numbers {
    display: flex;
    gap: 0.25rem;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

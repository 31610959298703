@import '/src/styles/media.scss';

.orderInfoSemen {
  padding: 0 20px;
  @include desktop {
    padding: 0;
    max-width: 1440px;
    margin: 0 auto;
  }

  .tablebox {
    td {
      background-color: var(--white_ff);
      &.yellow {
        background-color: #ffffe0;
      }
    }
  }
  .orderInfo {
    margin: 40px auto 0;
    border: solid 1px var(--gray_dd);
    @include tablet {
      width: 80%;
    }
    @include desktop {
      width: 100%;
    }

    li {
      display: flex;
      width: 100%;

      span {
        position: relative;
        width: 25%;
        display: block;
        font-size: var(--fs_14);
        background-color: var(--gray_f8);
        text-align: center;
        padding: 16px 0;
        border: solid 1px var(--gray_de);
        @include tablet {
          width: 20%;
        }
        @include desktop {
          width: 15%;
        }

        span {
          color: var(--main_red);
        }
      }
      p {
        flex: 1;
        border: solid 1px var(--gray_de);
        padding: 4px;
      }
      &.orderTwo {
        ul {
          width: 100%;

          @include desktop {
            display: flex;

            li {
              span {
                width: 30%;
              }
            }
          }
        }
      }
    }
  }
  .orderInfoSemenBtn {
    text-align: center;
    margin-top: 40px;

    button {
      font-weight: var(--fw_bold);
      color: var(--main_gray);
      background-color: var(--white_ff);
      padding: 12px 20px;
      border: solid 1px var(--sub_gray);
    }
  }
}

@import '/src/styles/media.scss';

.geneAnalysis {

  // 공통
  h5 {
    margin-bottom: 15px;
    @include tablet {
      font-size: var(--fs_24);
      margin-bottom: 25px; 
    }
  }
  .commonInner {
    .commonForm {
      ul {
        li {
          &:nth-child(2) {
            label {
              padding-left: 23px;
              &::before {
                width: 18px;
                height: 11px;
                transform: translateY(-40%);
                background: url('/assets/svgs/containers/performance/password.svg');
              }
            }
          }
        }
      }
    }
  }
}
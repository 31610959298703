@import '/src/styles/media.scss';

.milkPlanMating {
  padding: 10px 15px;
  @include laptop {
    padding: 25px 30px;
  }

  h2 {
    font-weight: var(--fw_medium);
    margin-bottom: 15px;
    font-size: var(--fs_18);
    @include laptop {
      font-size: var(--fs_24);
    }
  }
  span {
    font-size: var(--fs_14);
  }
  .milkPlanBtn {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 10px;

    button {
      padding: 4px 8px;
      background-color: var(--main_green);
      color: var(--white_ff);
      border-radius: 4px;
    }
  }
  .planMatingTable {
    overflow: auto;

    .mating {
      border-top: solid 2px var(--admin_black);
      min-width: 600px;
      thead {
        th {
          border: solid 1px var(--gray_df);
        }
        tr {
          &:nth-child(1) {
            th {
              &:nth-child(1) {
                width: 10%;
              }
              &:nth-child(2) {
                width: 10%;
              }
              &:nth-child(5) {
                width: 10%;
              }
              &:nth-child(6) {
                width: 6%;
              }
            }
          }
          &:nth-child(2) {
            th {
              &:nth-child(2) {
                width: 8%;
              }
              &:nth-child(3) {
                width: 8%;
              }
              &:nth-child(4) {
                width: 8%;
              }
              &:nth-child(5) {
                width: 8%;
              }
              &:nth-child(6) {
                width: 8%;
              }
              &:nth-child(7) {
                width: 8%;
              }
              &:nth-child(8) {
                width: 8%;
              }
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: solid 1px var(--gray_df);
  
          td {
            &.matingCheck {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;
            }
          }
        }
      }
    }
    .result {
      min-width: 600px;
      border: solid 2px var(--admin_black);
      margin-top: 50px;
    
      thead {
        th {
          &:nth-child(1) {
            width: 20%; 
          }
          &:nth-child(2) {
            width: 8%; 
          }
          &:nth-child(3) {
            width: 8%; 
          }
          &:nth-child(4) {
            width: 8%; 
          }
          &:nth-child(5) {
            width: 8%; 
          }
          &:nth-child(6) {
            width: 8%; 
          }
          &:nth-child(7) {
            width: 8%; 
          }
          &:nth-child(8) {
            width: 8%; 
          }
          &:nth-child(9) {
            width: 10%; 
          }
          &:nth-child(10) {
            width: 6%; 
          }
        }
      }
      tr {
        &:nth-child(odd) {
          background-color: var(--gray_f8);
        }
        td,th {
          border: solid 1px var(--gray_df);
        }
      }
    }
  }
}
@import '/src/styles/media.scss';
.video-con {
  .thumbnail {
    height: 200px;
  }
  background-color: #fbfbfb;
  @include desktop {
    // background-color: #fff;
    // margin-top: 80px;
  }
  z-index: 9;
  margin-bottom: 60px;
  padding: 0 20px;
  padding-bottom: 40px;
  @include tablet {
    // padding: 0 20px;
  }
  @include desktop {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0;
    // margin-top: 80px;
    &::before {
      background-image: url(../../../assets/images/containers/main/circle1.png);
      width: 5000px;
      height: 194px;
      left: 50%;
      margin-left: -920px;
      bottom: 145px;
    }
  }
  .social-media-gallery-container {
    @include desktop {
      border: 1px solid #dddddd;
      background: #fff;
      position: relative;
      z-index: 9;
      padding: 20px;
    }
    .video-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include tablet {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
      }
      svg {
        // margin-right: 30px;
        cursor: pointer;
        font-size: 24px;
        @include tablet {
          margin-right: 20px;
        }
      }
    }
    @include tablet {
      // border: 1px solid #dddddd;
      border-radius: 16px;
      background-color: #edeff4;
      @include desktop {
        background-color: #fff;
      }
    }
    .video-group {
      @include tablet {
        padding: 0 20px;
        margin-bottom: 20px;
      }
    }
    text-align: left;

    h2 {
      // padding-top: 20px;
      font-size: 16px;
      @include tablet {
        font-size: 24px;
        // padding-top: 40px;
      }
      @include desktop {
        margin: 0;
        margin-bottom: 20px;
        padding-left: 20px;
      }
      margin: 20px 0;
      margin-left: 20px;
      span {
        font-weight: 500;
      }
    }

    .thumbnails-container {
      display: flex;
      flex-direction: column;
      iframe {
        width: 100%;
        height: 200px;
      }
      // overflow: hidden;
      gap: 30px;

      @include tablet {
        padding: 0 20px;
        padding-bottom: 60px;
        @include desktop {
          padding-bottom: 0;
        }
        display: flex;
        flex-direction: row;
      }

      .thumbnail {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        transition: transform 0.3s ease;
        width: 100%; // Set thumbnail width to full width for mobile
        margin: 0 0 20px 0; // Add some margin between thumbnails
        @include tablet {
          // Adjust width, height, or other properties for tablet
          // For example, if you want three in a row:
          width: calc(33.333% - 10px); // Subtract the gap from the width
          height: auto; // Adjust height for tablet if needed
        }
        .overlay-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 36px; // 아이콘의 크기를 12px로 설정
          height: 36px; // 아이콘의 크기를 12px로 설정
          background: url('/assets/images/containers/main/youtube.png')
            center/cover no-repeat;
          background-size: contain; // 추가: 배경 이미지를 컨테이너 안에 맞게 조정
          @include desktop {
            width: 72px;
            height: 52px;
          }

          .play-icon {
            width: 48px; // Adjust to match your design
            height: 33px; // Adjust to match your design
            background: url('/assets/images/containers/main/youtube.png')
              center/cover no-repeat; // Ensure this path is correct
          }
        }
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
          height: 200px;
          @include desktop {
            width: 100%;
            height: 100%;
          }
        }

        &:hover {
          transform: scale(1.1);
          z-index: 1; /* 추가된 부분: 다른 요소들 위에 레이어하기 위해 */
        }

        .img-box {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }

          .overlay {
            position: absolute;
            top: -44px;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.3s ease;
            opacity: 1;
            visibility: visible;

            &:hover {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .more-icon-link {
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #34495e;
    }
  }
}

@import '/src/styles/media.scss';

.tableCon {
  .tableCon_title {
    font-size: 14px;
    @include tablet {
      font-size: 20px;
    }
    margin-bottom: 20px;
  }
  // .scroll {
  //   color: var(--main_right_gray);
  // }
}
.table_container {
  font-size: 14px;
  @include desktop {
    font-size: 18px;
  }
  overflow-x: auto;
  // padding: 1rem;
  background-color: #fff;
  margin-bottom: 80px;

  .table_box {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #ddd;
      background-color: #fff;
      text-align: center;
    }

    th,
    td:nth-child(1) {
      padding: 12px 20px; // Padding for '구분' column
      white-space: nowrap;
    }
    th:nth-child(1) {
      width: 160px;
      @include desktop {
        width: 220px;
      }
    }

    td:nth-child(2) {
      text-align: left;
      padding: 10px 20px; // Standard padding for the content column
      background-color: #fff;

      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.navPc {
  margin-top: 20px;

  .gnb {
    display: flex;
    font-family: NanumSquare;
    font-weight: var(--fw_bold);
    font-size: var(--fs_18);

    .gnbTwoDepth {

      &.on {
        > a {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: var(--main_green);
          }
        }
        .gnbThreeDepth {
          border-top: solid 1px var(--sub_gray);
          border-bottom: solid 1px var(--sub_gray);
          max-height: 1000px;
          transition: max-height .5s;
        }
      }
      > a {
        position: relative;
        padding: 20px 25px 40px 25px;
      }
      .gnbThreeDepth {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: var(--white_ff);
        max-height: 0;
        overflow: hidden;
        
        .gnbThreeDepthInner {
          display: flex;
          justify-content: space-between;
          max-width: 1440px;
          margin: 0 auto;

          span {
            display: block;
            width: 220px;
            padding-top: 20px;
            border-right: solid 1px var(--sub_gray);
            font-size: var(--fs_24);
          }
          ul {
            flex: 1;
            display: inline-flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: flex-start;
            column-gap: 100px;
            padding: 15px 0 25px 80px;
            max-height: 350px;

            li {
              a {
                padding: 8px 0;
                font-weight: var(--fw_normal);
                line-height: 100%;

                &:hover {
                  color: var(--main_green);
                }
              }
            }
          }
          .gnbImg {
            align-self: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            padding: 25px 0;

            p {
              font-family: globalFont;
              font-size: var(--fs_14);
              font-weight: var(--fw_normal);
              color: var(--main_gray);
            }
          }
        }
      }
    }
  }
}
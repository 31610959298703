.dcicGraph {

  .dcicGraphTab {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    background-color: var(--white_ff);

    li {
      display: flex;

      button {
        width: 100%;
        padding: 4px 0;
        border: solid 1px var(--sub_gray);
        text-align: center;

        &.current {
          border: solid 1px var(--main_blue);
          background-color: var(--main_blue);
          color: var(--white_ff);
        }
      }
    }
  }
}
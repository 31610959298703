:root {

  // 모달
  --2000: 2000; // 최상단 모달

  // 헤더 영역
  --1000: 1000; // 네비
  --999: 999; // 헤더
  --998: 998; // 사이드네비

  // 배경용
  --10: 10;
}
@import '/src/styles/media.scss';


.relatedSites.subCon {


  @include desktop {

    padding-top:100px;

  }
  


.relatedsites_list {

    
    padding-bottom:50px;
    padding-left:20px;
    padding-right:20px;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;

    @include desktop {

      padding-top:30px;

    }
    
    .list_name {
      overflow:hidden;
      
      width:100%;
      max-width:1440px;
      text-align:center;
    
  
      dd {
        display:grid;
        position:relative;
        float:left; 
        width:50%;
        height:40px;
        border:1px solid var(--gray_dd);

        :hover {
          background-color:var(--main_green);
          font-weight:var(--fw_bold);
          color:var(--white_ff);
        }

        @include tablet {
          width:25%;
          height:60px;
        }
      
        
            
        a{      
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;           
          
          font-size : var(--fs_14);

          @include desktop{
            font-size : var(--fs_18);
          }
          
          
          }

          @include tablet {
           font-size : var(--fs_18);
          }

          .selected {
            background-color:var(--main_green);
            color:var(--white_ff);
            font-weight:var(--fw_bold);
          }

      
        
                  
        }
      }
 
  
}

}
@import '/src/styles/media.scss';
.newsCon {
  z-index: var(--10);
  @include tablet {
    padding: 0 20px;
    margin-bottom: 60px;
  }
  @include desktop {
    padding: 0;
    padding-top: 60px;
    width: 100%;
  }
}
.main-news-container {
  padding: 0 20px;
  margin-bottom: 40px;
  @include desktop {
    margin-bottom: 0;
  }
  background-color: #fff;
  @include desktop {
    border: 1px solid #dddddd;
    padding: 0;
    border-radius: 16px;
    .news-info-group {
      @include desktop {
        padding: 20px 50px;
      }
    }
  }
  .news-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dddddd;
    @include desktop {
      margin-bottom: 40px;
    }

    h2 {
      text-align: center;
      font-size: 16px;
      @include tablet {
        font-size: 24px;
      }
      padding-bottom: 10px;
    }

    .more-icon {
      font-size: 24px;
      cursor: pointer;
    }
  }

  .news-slider {
    display: flex;
    overflow-x: auto;
    align-items: center;
    gap: 20px;
    img {
      width: 130px;
      height: 90px;
      @include tablet {
        width: 300px;
        height: 200px;
      }
      @include desktop {
      }
    }
    .news-content {
      @include desktop {
        width: 224px;
      }
      width: 100%;
      p {
        margin-top: 10px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; // 두 줄만 표시
        overflow: hidden;
        text-overflow: ellipsis;
        @include tablet {
          font-size: 16px;
        }
        @include desktop {
          // margin-top: 30px;
        }
      }
    }
  }
  .more-con {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: #3498db;

      .more-info {
        text-decoration: underline;
      }
    }

    .more-icon {
      margin-right: 8px;
    }
  }

  .more-icon-link,
  .news-header-link {
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #34495e;
    }
  }
}

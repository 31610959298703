.newCowDcicAnalPdf {
    position: relative;
    max-width: 1440px;
    min-width: 793px;
    min-height: 1122px;
    margin: 0 auto;
    padding: 40px 30px 80px;
    background-color: var(--white_ff);
  
    h1 {
      text-align: center;
      font-size: var(--fs_28);
      font-weight: var(--fw_normal);
      margin-bottom: 20px;
    }
   

    .exp {
      margin-top: 20px;
      font-size : var(--fs_16);
    }
    
    .pdfTable {
      margin-top: 20px;
    
      thead {
        .tablestart {
          width:90px;        
          background-image: linear-gradient(to left bottom, transparent calc(50% - 0.8px), #000, transparent calc(50% + 0.8px));
        }
        tr,th {
        border : solid 1px var(--main_black)
        }
      }
      tbody {
        tr {
          td {
            &:nth-child(1) {
              width: 9%;
            }
            &:nth-child(2) {
              width: 3%;
            }
            &:nth-child(3) {
              width: 7%;
            }
            &:nth-child(4) {
              width: 7%;
            }
            &:nth-child(5) {
              width: 7%;
            }
            &:nth-child(6) {
              width: 7%;
            }
            &:nth-child(7) {
              width: 7%;
            }
            &:nth-child(8) {
              width: 7%;
            }
            &:nth-child(9) {
              width: 7%;
            }
            &:nth-child(10) {
              width: 7%;
            }
            &:nth-child(11) {
              width: 7%;
            }
            &:nth-child(12) {
              width: 7%;
            }
            &:nth-child(13) {
              width: 7%;
            }
          }
        }
      }
    }

    
  }
@import '/src/styles/media.scss';

// 검정성적 공통 레이아웃
.commonInner {
  background-color: var(--gray_fb);
  padding: 0 20px 40px 20px;
  @include tablet {
    padding: 0 20px 50px 20px;
  }

  .subTitle {
    margin: 0;
    padding: 24px 0;
    @include tablet {
      padding: 46px 0;
    }
    @include desktop {
      max-width: 1440px;
      margin: 0 auto;
      padding-top: 100px;
      padding-bottom: 0;
    }
  }
  .commonInfo {
    font-size: var(--fs_14);
    font-weight: var(--fw_medium);
    line-height: var(--lh_170);
    color: var(--main_right_gray);
    @include tablet {
      font-size: var(--fs_18);
    }
    @include desktop {
      max-width: 1440px;
      margin: 0 auto;
    }

    strong {
      position: relative;
      font-weight: var(--fw_medium);
      padding-left: 8px;
      @include tablet {
        padding-left: 12px;
      }
      @include desktop {
        display: block;
        width: 590px;
        margin-left: auto;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--main_green);
        @include tablet {
          top: 13px;
        }
      }
    }
    ul {
      @include desktop {
        display: block;
        width: 590px;
        margin-left: auto;
      }
      li {
        padding-left: 15px;
      }
    }
  }

  .commonForm {
    margin-top: 25px;
    background-color: var(--white_ff);
    padding: 20px 10px ;
    border: solid 1px var(--sub_gray);
    border-radius: 24px;
    @include tablet {
      margin-top: 40px;
      padding: 40px 20px;
    }
    @include desktop {
      max-width: 1440px;
      margin: 50px auto 0;
      padding: 50px 0;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }
    
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;
      @include tablet {
        flex-direction: row
      };
      @include desktop {
        gap: 20px;
      }

      li {
        display: flex;
        align-items: center;
        gap: 10px;
        @include desktop {
          gap: 20px;
        }
        input {
          flex: 1;
          width: 100%;
          font-size: var(--fs_14);
          font-weight: var(--fw_medium);
          color: var(--main_gray);
          border: solid 1px var(--admin_gray);
          padding: 4px;
          &:focus {
            border: solid 1px var(--main_black);
          }
          @include tablet {
            font-size: var(--fs_18);
          }
        }
        label {
          position: relative;
          font-size: var(--fs_14);
          font-weight: var(--fw_medium);
          width: 120px;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
          }
          @include tablet {
            width: auto;
          }
          @include desktop {
            font-size: var(--fs_18);
          }
        } 
        &:nth-child(1) {
          label {
            padding-left: 24px;
            &::before {
              width: 19px;
              height: 12px;
              transform: translateY(-40%);
              background: url('/assets/svgs/containers/performance/barcode.svg');
            }
          }
        }
        &:nth-child(2) {
          label {
            padding-left: 18px;
            &::before {
              width: 13px;
              height: 16px;
              transform: translateY(-50%);
              background: url('/assets/svgs/containers/performance/calendar.svg');
            }
          }
        }
        &:nth-child(3) {
          label {
            padding-left: 23px;
            &::before {
              width: 18px;
              height: 11px;
              transform: translateY(-40%);
              background: url('/assets/svgs/containers/performance/password.svg');
            }
          }
        }
      }
    }
    .commonFormBtn {
      display: flex;
      gap: 10px;
      justify-content: center;
      text-align: center;
      margin-top: 15px;
      @include desktop {
        margin-top: 0;
      }

      button {
        color: var(--gray_f8);
        font-size: var(--fs_16);
        padding: 10px 18px;
        background-color: var(--main_blue);
        border-radius: 5px;
  
        span {
          position: relative;
          display: inline-block;
          padding-left: 30px;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 15px;
            height: 15px;
            background: url('/assets/svgs/containers/performance/search_Icon.svg');
          }
        }
      }
    }
  }
}
.commonCon {
  margin-top: 80px;
  padding: 0 20px;
  @include desktop {
    padding: 0;
    max-width: 1440px;
    margin: 120px auto 0;
  }

  p {
    font-size: var(--fs_16);
    font-weight: var(--fw_bold);
    margin-bottom: 15px;
    @include tablet {
      font-size: var(--fs_20);
      margin-bottom: 24px;
    }
    @include desktop {
      margin-bottom: 30px;
    }

    &:nth-of-type(2) {
      color: var(--main_green);
      margin-top: 50px;
      @include tablet {
        margin-top: 80px;
      }
    }
  }
  .commonDocument {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--white_ff);
    font-size: var(--fs_14);
    @include tablet {
      flex-direction: row;
      font-size: var(--fs_12);
    }
    @include desktop {
      font-size: var(--fs_18);
      gap: 60px;
    }

    .commonDocumentInner {
      background-color: rgba(0,0,0,.7);
      padding: 40px 20px;
      height: 100%;
      @include desktop {
        padding: 60px;
      }
    }
    > li {
      &:nth-child(1) {
        background: url('/assets/images/containers/performance/performanceCheck/farm_back.png') no-repeat center/cover;
      }
      &:nth-child(2) {
        background: url('/assets/images/containers/performance/performanceCheck/expert_back.png') no-repeat center/cover;
      }
      &:nth-child(3) {
        background: url('/assets/images/containers/performance/performanceCheck/check_back.png') no-repeat center/cover;
      }
      @include tablet {
        width: 33%;
      }

      
      strong {
        display: block;
        padding: 0 0 15px 10px;
        border-bottom: solid 1px var(--white_ff);
        @include desktop {
          font-size: var(--fs_20);
          padding: 0 0 25px 10px;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 35px;
        @include tablet {
          gap: 8px;
        }
        @include desktop {
          gap: 20px;
          margin-top: 40px;
        }

        li {
          position: relative;
          padding-left: 20px;
          width: 95%;
          margin: 0 auto;
          
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--white_ff);
          }
        }
      }
    }
  }
  .commonNotice {
    display: flex;
    flex-direction: column;
    gap: 20px; 
    background-color: var(--gray_fb);
    border: solid 1px var(--sub_gray);
    padding: 20px 10px;
    font-size: var(--fs_14);
    @include tablet {
      font-size: var(--fs_18);
    }

    li {
      position: relative;
      padding-left: 10px;

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--main_green);
        @include tablet {
          top: 12px; 
        }
      }
    }
  }
}
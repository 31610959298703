@import '/src/styles/media.scss';

.favoriteCon {
  margin-bottom: 10px;
  padding: 0 20px;
  @include tablet {
    margin-bottom: 40px;
  }
  @include desktop {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0;
  }
  .title_group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @include desktop {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      gap: 60px;
    }
  }
  .sub_title {
    display: none;
    @include tablet {
      display: block;
      color: #999999;
      font-size: 14px;
      font-weight: bold;
    }
    @include desktop {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.favoriteCon_title {
  font-size: 16px;
  @include tablet {
    font-size: 24px;
  }
  @include desktop {
    font-size: 32px;
  }
  margin-bottom: 20px;
  @include tablet {
    margin-bottom: 30px;
  }
  @include desktop {
    margin-bottom: 50px;
  }
  span {
    font-weight: 500;
  }
}
.favorite-menu-grid {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  @include desktop {
    flex-wrap: nowrap;
  }
  justify-content: center;
  gap: 10px;
  @include tablet {
    gap: 20px;
  }
  .imgbox {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%; // 이미지의 최대 너비를 imgbox의 너비에 맞춤
      max-height: 100%; // 이미지의 최대 높이를 imgbox의 높이에 맞춤
      object-fit: contain; // 이미지의 원본 비율을 유지하면서 채우기
    }
  }

  .menu-item {
    flex: 1 1 calc(50% - 10px);
    @include tablet {
      flex: 1 1 calc(33.333% - 10px); // 768px 이상에서 한 줄에 세 개
    } // 한 줄에 두 개의 아이템이 들어갈 수 있도록 설정
    height: 166px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #dddddd;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition:
      transform 0.3s ease,
      background-color 0.3s ease; // 부드러운 전환 효과
    &:hover {
      transform: translateY(-6px); // 위로 6px만큼 이동
      background-color: #34495e;
      box-shadow: 0px 32px 16px 0px rgba(0, 0, 0, 0.1);

      p {
        color: #fff;
      }
    }
    p {
      font-size: 14px;
      @include tablet {
        font-size: 16px;
      }
      @include desktop {
        font-size: 18px;
        font-weight: 500;
      }
      color: #333;
      text-align: center;
      margin: 0;
    }
  }
}

@import '/src/styles/media.scss';

.Artificial,
.Breeding,
.Consultation,
.Fertilized,
.International,
.Medicine,
.Organization {
  width: 100%;
}

.webpage {
  width: 100%;
  height: auto;
  padding: 0 20px;

  .webpage_list {
    width: 100%;
    max-width: 1440px;
    margin: 35px auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    justify-items: center;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include desktop {
      grid-template-columns: repeat(4, 1fr);
      gap: 40px 20px;
    }

    dd {
      width: 100%;
      max-width: 280px;

      .webpage_banner {
        width: 100%;
        height: 188px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--gray_de);
        background-color: #fff;

        img {
          width: auto;
          max-width: 90%;
          height: auto;
          max-height: 90%;
          object-fit: contain;
        }
      }

      p {
        width: 100%;
        text-align: center;
        font-size: var(--fs_16);
        padding-top: 10px;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

@import 'styles/media.scss';

.modalBackground{

    position:fixed;
    top:0;
    left:0;
    z-index: 9999;
    width : 100%;
    height : 100%;
    background-color:rgba(0,0,0,0.5);

    .errorModal {
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 20px 80px;
    border: solid 2px var(--sub_gray);
    border-radius: 25px;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
    font-size: var(--fs_14);
    text-align: center;
    word-break:keep-all;
    background-color: var(--white_ff);
    

        @include desktop {
            padding: 30px 100px;
            font-size:var(--fs_18);
        }


    }

    p {
        padding-bottom:20px;
    }

    .closeButton {
        
    color: var(--gray_f8);
    font-size: var(--fs_16);
    padding: 10px 18px;
    background-color: var(--main_blue);
    border-radius: 5px;

    }

}
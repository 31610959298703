@import '/src/styles/media.scss';

div.greetings {
  position: relative;
  margin-bottom: 100px;
  @include tablet {
    margin-bottom: 200px;
  }
  @include desktop {
    margin: 100px auto 500px;
  }

  .subTitle {
    @include desktop {
      transform: translateY(150%);
    }
  }
  .greetingsInner {
    @include desktop {
      width: 1080px;
      margin-left: auto;
    }

    dl {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @include desktop {
        gap: 40px;
      }
  
      dt {
        font-weight: var(--fw_bold);
        @include tablet {
          font-size: var(--fs_40);
        }
      }
      dd {
        font-size: var(--fs_14);
        color: var(--main_gray);
        line-height: var(--lh_170);
        @include desktop {
          font-size: var(--fs_18);
        }
      }
    }
  
    .bossSign {
      display: flex;
      gap: 15px;
      align-items: center;
      margin-top: 24px;
      @include tablet {
        margin-top: 40px;
      }
  
      p {
        font-size: var(--fs_14);
        color: var(--main_gray);
        @include tablet {
          font-size: var(--fs_18);
        }

        span {
          font-size: 18px;
          font-weight: var(--fw_bold);
          @include tablet {
            font-size: 24px;
          }
        }
      }
    }
    .greetingsBack {
      @include tablet {
        position: absolute;
        right: 20px;
        bottom: 0;
        width: 30%;
        transform: translateY(50%);
      }
      @include desktop {
        transform: translateY(80%);
        width: 50%;
      }
  
      img {
        width: 100%;
      }
    }
  }
}
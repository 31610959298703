@import '/src/styles/media.scss';

.milkHeader {
  z-index: var(--1000);
  @include desktop {
    padding-top: 100px;
  }

  .milkNav {
    > p {
      display: none;
      margin: 15px 0;
      padding-left: 20px;
      font-weight: var(--fw_bold);
      color: var(--white_ff);
      @include desktop {
        display: block;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      @include desktop {
        gap: 5px;
      }

      li { 
        a {
          padding: 5px 0 5px 10px;
          font-size: var(--fs_14);
          color: var(--gray_f8);
          text-align: center;
          background-color: var(--main_blue);

          @include desktop {
            padding: 10px 20px; 
            font-size: var(--fs_16);
            text-align: start;
            border-bottom-left-radius: 25px;
            border-top-left-radius: 25px;
          }

          &:hover,
          &.current {
            background-color: var(--sub_blue2);
          }
        }
      }
    }
  }
}
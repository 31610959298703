.newGraphMilkPdf {
  position: relative;
  max-width: 1440px;
  min-width: 1122px;
  min-height: 793px;
  margin: 0 auto;
  padding: 40px 30px 80px;
  background-color: var(--white_ff);

  h1 {
    text-align: center;
    font-size: var(--fs_28);
    font-weight: var(--fw_normal);
    padding: 10px 0;
    margin-top: 10px;
    border: solid 1px var(--gray_de);
    background-color: antiquewhite;
  }
  .dotChartArea {
    padding: 20px 0;
    margin-bottom: 20px;
    background-color: var(--gray_de);
  }
}
:root{
  //main
  --main_green: #4E995A;
  --main_yellow: #FCC800;
  --main_blue: #34495E;
  --main_black: #212121;
  --main_gray: #555;
  --main_right_gray: rgba(134,134,134,.86);
  --main_red: #ff033e;

  //sub
  --sub_gray: #f3f3f3;
  --sub_blue: #0066ff;
  --sub_blue2: #3b76c1;

  //dark
  --dark_00: #000;
  --dark_11: #111;
  --dark_22: #222;
  --dark_33: #333;
  --dark_44: #444;
  --dark_55: #555;
  --dark_66: #666;
  --dark_77: #777;
  --dark_88: #888;
  --dark_99: #999;
  
  //gray
  --gray_f2: #f2f2f2;
  --gray_f8: #f8f8f8;
  --gray_de: #dedede;
  --gray_df: #dfdfdf;
  --gray_fb: #fbfbfb;
  --gray_dd: #ddd;
  --gray_e6: #e6e6e6;
  --gray_e8: #e8e8e8;

  //white
  --white_ff: #fff;

  //admin
  --admin_black: #1F263E;
  --admin_gray: #979797;
  --admin_right_blue: #dfe5f9;
  --admin_orange: #ff6551;
  --admin_incar: #fff4c0;
  
  --admin_bg: #f3f6fe;
}
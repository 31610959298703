.newNoticeCowlistV1 {
  position: relative;
  max-width: 1440px;
  min-width: 1122px;
  min-height: 793px;
  margin: 0 auto;
  padding: 40px 30px 80px;
  background-color: var(--white_ff);

  h1 {
    text-align: center;
    font-size: var(--fs_28);
    font-weight: var(--fw_normal);
    padding: 10px 0;
    margin-top: 10px;
    border: solid 1px var(--gray_de);
    background-color: antiquewhite;
    margin-bottom: 20px;
  }
  .pdfTable {
    tr {
      &:nth-child(2) {
        border-top: solid 1px var(--main_blue);
        th {
          &:nth-child(1) {
            border-left: solid 1px var(--main_blue);
          }
        }
      }
    }
  }
  ul {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;

    li {
      width: 49%;

      h2 {
        font-size: var(--fs_20);
        margin-bottom: 10px;
      }
    }
  }
}
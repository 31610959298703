@import '/src/styles/media.scss';

.superiorCowSearch {

  .superiorCowSearchInner {
    background-color: var(--gray_fb);
    padding: 20px;
    @include tablet {
      padding: 30px 20px;
    }
    @include desktop {
      padding: 40px 0;
    }
  
    .subTitle {
      margin: 0;
      margin-bottom: 20px;
      @include tablet {
        margin-bottom: 50px;
      }  
      @include desktop {
        max-width: 1440px;
        margin: 0 auto 50px;
      }
    }
    .superiorCowSearchForm {
      display: flex;
      flex-direction: column;
      gap: 16px;
      background-color: var(--white_ff);
      border-radius: 12px;
      padding: 20px 10px;
      @include tablet {
        padding: 20px 100px;
      }
      @include desktop {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1440px;
        margin: 0 auto;
        column-gap: 130px;
        row-gap: 35px;
        padding: 30px 100px;
      }

      > li {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        @include tablet {
          gap: 24px;
        }
        @include desktop {
          flex: 1;
        }

        label {
          display: block;
          width: 124px;
          position: relative;
          font-size: var(--fs_12);
          font-weight: var(--fw_medium);
          color: var(--main_gray);
          padding-left: 36px;
          white-space: nowrap;
          @include tablet {
            width: 200px;
            font-size: var(--fs_18);
            padding-left: 70px;
            line-height: 40px;
          }
          @include desktop {
            text-align: center;
          }
          
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 20px;
            height: 20px;
            @include tablet {
              width: 40px;
              height: 40px;
            }
          }
  
          &.searchCode::before{
            background: url('/assets/svgs/containers/progency/semenCode.svg') no-repeat center/cover;
          }
          &.searchYears::before{
            background: url('/assets/svgs/containers/progency/year.svg') no-repeat center/cover;
          }
          &.searchDegree::before{
            background: url('/assets/svgs/containers/progency/degree.svg') no-repeat center/cover;
          }
          &.searchCondition::before{
            background: url('/assets/svgs/containers/progency/boolean.svg') no-repeat center/cover;
          }
        }
        > input,
        > select {
          flex: 1;
          border: solid 1px var(--gray_dd);
          font-size: var(--fs_10);
          padding-left: 4px;
          &:focus {
            border: solid 1px var(--main_black);
          }
          @include tablet {
            font-size: var(--fs_18);
            height: 40px;
          }
        }
        .inputs {
          flex: 1;
          display: flex;
          justify-content: space-between;
          gap: 10px;
        
          span {
            font-size: var(--fs_12);
            @include tablet {
              font-size: var(--fs_18);
            }
          }
          input {
            font-size: var(--fs_10);
            width: 45%;
            border: solid 1px var(--gray_dd);
            padding-left: 4px;
            @include tablet {
              font-size: var(--fs_18);
              height: 40px;
            }
          }
        }
      }
    }
    .superiorCowBtn {
      text-align: center;
      margin-top: 20px;
      @include tablet {
        margin-top: 30px;
      }

      button {
        font-size: var(--fs_10);
        color: var(--gray_f8);
        background-color: var(--main_blue);
        padding: 7px 11px;
        border-radius: 5px;
        @include tablet {
          font-size: var(--fs_20);
          padding: 10px 20px;
        }
  
        span {
          position: relative;
          display: inline-block;
          padding-left: 18px;
          padding-left: 36px;
        
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 9px;
            height: 9px;
            background: url('/assets/svgs/containers/performance/search_Icon.svg') no-repeat center/cover;
            @include tablet {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
    
  }
  .superiorCowTable {
    margin: 30px 20px;

    @include tablet {
      margin: 40px 20px;
    }
    @include desktop {
      max-width: 1440px;
      margin: 60px auto;
    }

    table {
      border-top: solid 2px var(--main_green);
      
      thead {
        border-bottom: solid 2px var(--main_gray);
  
        th {
          font-size: var(--fs_12);
          font-weight: var(--fw_normal);
          background-color: var(--gray_f8);
          color: var(--main_gray);
          @include tablet {
            font-size: var(--fs_14);
          }
          @include desktop {
            font-size: var(--fs_18);
          }
        }
      }
      tbody {
        td {
          font-size: var(--fs_12);
          color: var(--dark_66);
          white-space: pre;
          @include tablet {
            font-size: var(--fs_14)
          }
          @include desktop {
            font-size: var(--fs_18);
          }
        }
      }
    }
  }
}
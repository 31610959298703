@import 'styles/media.scss';

.geneResult {
  .geneResultInner {
    .geneSearch {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 4px;
      margin-bottom: 30px;
      @include desktop {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }

      span {
        font-size: var(--fs_14);
      }
      ul {
        display: flex;
        align-items: center;
        gap: 4px;
        li {
          display: flex;
          align-items: center;
        } 
      }
      .searchBox {
        display: flex;
        align-items: center;
        gap: 4px;
        input {
          border: solid 1px var(--gray_df);
          padding: 2px 4px;
        }
        button {
          padding: 2px 4px;
          background-color: var(--main_green);
          color: var(--white_ff);
          border-radius: 4px;
        }
      }
    }
    table {
      min-width: 720px;
      thead {
        th {
          background-color: var(--gray_f2);
          button {
            font-size: 14px;
            
            &.current {
             text-decoration: underline; 
            }
          }
          &:nth-child(1) {
            width: 8%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 8%;
          }
          &:nth-child(4) {
            width: 10%;
          }
          &:nth-child(5) {
            width: 8%;
          }
          &:nth-child(6) {
            width: 6%;
          }
          &:nth-child(7) {
            width: 8%;
          }
          &:nth-child(8) {
            width: 6%;
          }
          &:nth-child(9) {
            width: 8%;
          }
          &:nth-child(10) {
            width: 10%;
          }
          &:nth-child(11) {
            width: 6%;
          }
          &:nth-child(12) {
            width: 6%;
          }
          &:nth-child(13) {
            width: 6%;
          }
        }
      }
    }
    .tableBody {
      max-height: 420px;
      min-width: 720px;
      overflow-y: auto;
      overflow-x: auto;
      border-bottom: solid 1px var(--gray_df);
      border-left: solid 1px var(--gray_df);
      border-right: solid 1px var(--gray_df);
      @include desktop {
        min-height: 600px;
      }
      &::-webkit-scrollbar {
        display: none;
      }

      > li {
        &:nth-child(even) {
          background-color: var(--gray_e8);
        }
        ul {
          display: flex;
          border-bottom: solid 1px var(--gray_df);
          
          li {
            font-size: var(--fs_14);
            padding: 6px 2px;
            border-right: solid 1px var(--gray_df);
            text-align: center;
            &.numberCon {
              text-align: end;
            }
            button {
              color: var(--sub_blue);
              text-decoration: underline;
            }
            &:last-child {
              border-right: none;
            }
            &:nth-child(1) {
              width: 8%;
            }
            &:nth-child(2) {
              width: 10%;
            }
            &:nth-child(3) {
              width: 8%;
            }
            &:nth-child(4) {
              width: 10%;
            }
            &:nth-child(5) {
              width: 8%;
            }
            &:nth-child(6) {
              width: 6%;
            }
            &:nth-child(7) {
              width: 8%;
            }
            &:nth-child(8) {
              width: 6%;
            }
            &:nth-child(9) {
              width: 8%;
            }
            &:nth-child(10) {
              width: 10%;
            }
            &:nth-child(11) {
              width: 6%;
            }
            &:nth-child(12) {
              width: 6%;
            }
            &:nth-child(13) {
              width: 6%;
            }
          }
        }
      }
    }
  }
}
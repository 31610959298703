@import '/src/styles/media.scss';

.banner-slider {
  padding-top: 35px;
  @include tablet {
    padding-top: 0;
  }
  @include desktop {
    padding-top: 0;
  }
  margin-bottom: 20px;
  position: relative;

  .slick-slide {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      max-height: 100vh;
      object-fit: cover;
      @include tablet {
        height: 750px;
      }
      @include desktop {
        height: 100vh;
      }
    }
  }

  .slick-dots,
  .slick-arrow {
    z-index: 10;
  }

  .slick-dots {
    bottom: 7px;
  }

  .slick-arrow.slick-next {
    right: 10px;
  }
  .slick-arrow.slick-prev {
    left: 10px;
  }

  .bannerTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    @include tablet {
      // justify-content: flex-start;
      // position: absolute;
      // left: 50%;
      // margin-left: -314px;
      // top: 56%;
      // margin-top: -155px;
      // text-align: left;
    }
    @include desktop {
      justify-content: flex-start;
      position: absolute;
      left: 50%;
      margin-left: -314px;
      top: 56%;
      margin-top: -155px;
      text-align: left;
    }
    strong {
      font-size: 10px;
      @include tablet {
        font-size: 15px;
      }
      @include desktop {
        font-weight: 400;
        letter-spacing: 8px;
        font-size: 18px;
        position: absolute;
        top: -21px;
        left: 9px;
      }
    }
    h2 {
      font-size: 10px;
      @include tablet {
        font-size: 28px;
        margin-bottom: 15px;
      }
      @include desktop {
        font-size: 58px;
      }
    }
    p {
      display: none;
      @include tablet {
        display: block;
        font-size: 14px;
      }
      @include desktop {
        display: block;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 40px;
        padding-left: 5px;
      }
    }
    button {
      display: none;
      @include tablet {
        display: block;
        border-radius: 8px;
        border: 1px solid #fff;
        padding: 11px;
        color: #fff;
        font-size: 20px;
        margin-top: 20px;
        margin-left: 131px;
        &:hover {
          background-color: #fff; // Hover background color
          .buttonColor {
            color: #000; // Hover text color
          }
          transition: ease-in-out 300ms;
        }
      }
      @include desktop {
        display: block;
        border: 1px solid #fff;
        padding: 11px;
        color: #fff;
        font-size: 20px;
        border-radius: 8px;
        margin-left: 0;
        &:hover {
          background-color: #fff; // Hover background color
          .buttonColor {
            color: #000; // Hover text color
          }
          transition: ease-in-out 300ms;
        }
      }
    }
    .buttonColor {
      color: #fff; // Base text color
      &:hover {
        color: #000; // This ensures the hover state is also captured here
        transition: ease-in-out 300ms;
      }
    }
    br {
      display: block;
      @include tablet {
        display: none;
      }
    }
  }
}

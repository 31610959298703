@import '/src/styles/media.scss';

.girlcowability {
  padding: 10px 15px;
  @include laptop {
    padding: 25px 30px;
  }
  
  h2 {
    font-weight: var(--fw_medium);
    margin-bottom: 15px;
    font-size: var(--fs_18);
    @include laptop {
      font-size: var(--fs_24);
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;

    li {
      .girlcowBtn {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        @include laptop {
          margin-bottom: 25px;
        }
      }
      p {
        position: relative;
        padding-left: 17px;
        font-weight: var(--fw_medium);
        color: var(--sub_blue2);
        margin-bottom: 10px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 7px;
          height: 7px;
          background-color: var(--sub_blue2);
          border-radius: 1px;
        }
      }
      > span {
        display: block;
        margin-top: 10px;
        font-size: var(--fs_14);
      }
      .girlcowAbilityTable {
        overflow-y: auto;
        table {
          min-width: 500px;
          th {
            border: solid 1px var(--gray_df);
          }
          tbody {
            tr {
              border-bottom: solid 1px var(--gray_df);
  
              td {
                &.current {
                  font-weight: var(--fw_bold);
                  color: var(--sub_blue);
                  background-color: var(--admin_incar);
                }
                &.error {
                  color: var(--main_red);
                }
              }
            }
          }
        }
      }
      button {
        margin-left: auto;
        padding: 4px 8px;
        border-radius: 5px;
        background-color: var(--sub_blue2);
        color: var(--white_ff);
        font-size: var(--fs_12);
        @include laptop {
          font-size: var(--fs_16);
        }
      }
    }
  }
}
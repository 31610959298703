@import '/src/styles/media.scss';

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); // 아이콘이 위로 20px 움직입니다.
  }
}

@keyframes drop {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    transform: translateY(15px);
    opacity: 0;
  }
  60% {
    transform: translateY(10px);
    opacity: 0;
  }
}

.animation {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
  bottom: 50px;
  left: 42%;
  @include tablet {
    left: 44%;
  }
  @include desktop {
    left: 47%;
  }

  &::before {
    content: 'SCROLL';
    color: #fff;
    font-size: 10px;
    @include tablet {
      font-size: 15px;
    }
    @include desktop {
      font-size: 24px;
    }
  }

  .scroll-icon {
    background-color: none;
  }
}

.circle {
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include desktop {
    width: 30px;
    height: 30px;
  }
}

.dot {
  width: 3px;
  height: 3px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 10%;
  animation: drop 1.5s infinite;
  @include desktop {
    width: 6px;
    height: 6px;
  }
}

.titleCon {
  height: auto;
  margin-bottom: 80px;

  @include tablet {
    margin-bottom: 130px;
  }

  position: relative;
  padding: 0 20px;

  @include desktop {
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 120px;
    padding: 0;
  }

  width: 100%;
  text-align: center;

  .title_box {
    @include desktop {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  h2 {
    font-size: 16px;
    margin-bottom: 30px;

    @include tablet {
      font-size: 24px;
      margin-bottom: 50px;
    }

    @include desktop {
      font-size: 40px;
      line-height: 170%;
      text-align: left;
      margin-bottom: 0px;
    }

    span {
      color: #34495e;
    }
  }

  p {
    font-size: 10px;
    color: #999999;

    @include tablet {
      font-size: 16px;
    }

    @include desktop {
      text-align: left;
      font-size: 17px;
      font-weight: bold;
      line-height: 170%;
    }
  }

  .bluecircle {
    display: none;

    @include tablet {
      display: block;
      position: absolute;
      bottom: 30px;
      right: 40px;
    }

    @include desktop {
      display: block;
      position: absolute;
      top: 0px;
      right: 0;
    }
  }
}

.notice-and-slideshow-row {
  margin-bottom: 40px;

  @include desktop {
    flex: 1 1 auto;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    padding-top: 100px;
  }
}

.news-and-alert-row {
  @include tablet {
    padding: 0;
  }

  position: relative;

  @include desktop {
    margin-bottom: 700px;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    margin-bottom: 0px;
    align-items: center;
  }
}

.circle-item {
  display: none;

  @include tablet {
    display: block;
    position: absolute;
    bottom: 30px;
    right: 40px;
  }

  @include desktop {
    display: block;
    position: absolute;
    top: 0px;
    right: 140px;
  }
}

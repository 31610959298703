@import '/src/styles/media.scss';

.dictionary {
  &.subCon {
    margin-bottom: 40px;
    @include tablet {
      margin-bottom: 60px;
    }
    @include desktop {
      margin-bottom: 80px;
    }
  }

  .subTitle {
    @include desktop {
      margin: 100px 0 30px 0;
    }
  }

  .dictionaryInner {
    display: flex;
    gap: 20px;
    @include tablet {
      gap: 50px;
    } 
    @include desktop {
      gap: 100px;
    }

    .dictionaryNav {
      width: 120px;
      @include tablet {
        width: 180px;
      }
      @include desktop {
        width: 225px;
      }
      
      strong {
        display: block;
        font-size: var(--fs_16);
        color: var(--white_ff);
        background-color: var(--main_blue);
        padding: 10px 0;
        text-align: center;
        @include tablet {
          font-size: var(--fs_18);
          padding: 14px 0;
        }
        @include desktop {
          font-size: var(--fs_24);
        }
      }
  
      .dictionaryList {
        border: solid 1px var(--gray_dd);
        max-height: 550px;
        overflow-y: scroll;
        .dic2{ 
          &:hover {
            > button {
              color: var(--white_ff);
              background-color: var(--main_green);
              svg {
                transform: rotateZ(0);
                path {
                  fill: var(--white_ff);
                }
              }
            }
          }
          > button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 8px;
            font-size: var(--fs_16);
            &.open {
              color: var(--white_ff);
              background-color: var(--main_green);
              svg {
                transform: rotateZ(0);
                path {
                  fill: var(--white_ff);
                }
              }
            }
            @include tablet {
              padding: 8px 16px;
            }
            @include desktop {
              padding: 12px 24px;
              font-size: var(--fs_18);
            }
            
            svg {
              transform: rotateZ(90deg);
  
              path {
                fill: var(--main_black);
              }
            }
          }
  
          ul {
            max-height: 0;
            background-color: var(--sub_gray);
            transition: all .5s;
            overflow: hidden;
            &.open {
              max-height: 1000px;
            }
            li {
              &:hover {
                background-color: var(--gray_f8);
                color: var(--white_ff);
              }
              button {
                text-align: start;
                width: 100%;  
                padding: 4px 8px;
                font-size: var(--fs_16);
                @include tablet {
                  padding: 8px 16px;
                }
                @include desktop {
                  padding: 12px 24px;
                  font-size: var(--fs_18);
                }
              }
            }
          }
        }
      }
    }
  
    .dictionaryCon {
      flex: 1;

      .dictionarySearch {
        display: flex;
        gap: 8px;
        margin-bottom: 20px;

        @include desktop {
          margin-bottom: 30px;
        }
        select {
          font-size: var(--fs_14);
          padding: 2px 8px;
          border: solid 1px var(--gray_dd);
          @include tablet {
            font-size: var(--fs_16);
          }
          @include desktop {
            font-size: var(--fs_18);
          }
        }
        input {
          width: 100px;
          height: inherit;
          border: solid 1px var(--gray_de);
          border-radius: 5px;
          @include desktop {
            width: 300px;
          }
        }
        button {
          font-size: var(--fs_14);
          background-color: var(--main_blue);
          color: var(--white_ff);
          padding: 0 8px;
          border-radius: 4px;
          @include desktop {
            font-size: var(--fs_16);
          }
        }
      }
      .dictionaryInfo {
        > strong {
          position: relative;
          display: block;
          padding-left: 24px;
          font-size: var(--fs_16);
          margin-bottom: 20px;

          @include tablet {
            padding-left: 32px;
            font-size: var(--fs_18);
            margin-bottom: 40px;
          }
          @include desktop {
            font-size: var(--fs_24);
            padding-left: 48px;
            margin-bottom: 60px;
          }
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 15px;
            height: 9px;
            background: url('/assets/svgs/containers/data/dictionary/dic_list_icon.svg') no-repeat center/cover;
            @include tablet {
              width: 22px;
              height: 13px;
            }
            @include desktop {
              width: 27px;
              height: 15px;
            }
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 80px;
          @include tablet {
            gap: 40px;
          }
          li {
            strong {
              position: relative;
              display: block;
              color: var(--main_green);
              margin-bottom: 8px;
              padding-left: 18px;
              font-size: var(--fs_14);
              @include tablet {
                padding-left: 24px;
                font-size: var(--fs_16);
              }
              @include desktop {
                padding-left: 30px;
                font-size: var(--fs_18);
              }
              &::before {
                content: '';
                position: absolute;
                top: 3px;
                left: 0;
                width: 16px;
                height: 16px;
                background: url('/assets/svgs/containers/data/dictionary/dic_check_icon.svg') no-repeat center/cover;
                @include tablet {
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                }
                @include desktop {
                  width: 21px;
                  height: 21px;
                }
              }
            }
            p {
              font-size: var(--fs_14);
              @include tablet {
                font-size: var(--fs_16);
              }
              @include tablet {
                font-size: var(--fs_18);
              }
            }
          }
        }
      }
    }
  }
}
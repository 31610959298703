@import '/src/styles/media.scss';

.companyIntro {
  .subTitle {
    padding: 0 20px;
    @include desktop {
      padding: 0;
      max-width: 1440px;
      margin: 100px auto 0;
      transform: translateY(150%);
    }
  }
  .introduce {
    padding: 0 20px;
    @include desktop {
      padding: 0;
      max-width: 1440px;
      margin: 0 auto;
    }
    
    dt,
    dd {
      @include desktop {
        width: 1080px;
        margin-left: auto;
      }
    }
    dt {
      font-weight: var(--fw_bold);
      margin-bottom: 24px;
      @include tablet {
        font-size: var(--fs_40);
        margin-bottom: 40px;
      }
    }
    dd {
      font-size: var(--fs_14);
      line-height: var(--lh_170);
      @include tablet {
        font-size: var(--fs_18);
      }
    }
  }
  .introduceBack {
    margin: 32px 0 40px 0;
    width: 100%;
    @include tablet {
      margin: 45px 0 60px 0;
    }
  }
  .goHistory {
    margin: 24px 0 80px 20px;
    @include desktop {
      max-width: 1440px;
      margin: 24px auto 80px;
    }

    .goHistoryInner {
      @include desktop {
        width: 1080px;
        margin-left: auto;
      }
      
      a {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding: 10px 30px 10px 10px;
        font-size: var(--fs_16);
        font-weight: var(--fw_bold);
        color: var(--white_ff);
        background-color: var(--main_green);
        border-radius: 5px;
    
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotateZ(270deg);
          right: 10px;
          width: 9px;
          height: 6px;
          background: url('/assets/svgs/common/v_icon_white.svg') no-repeat center/cover;
        }
      }
    }
  }
  .technicalGuidance {
    padding: 30px 20px 80px 20px;
    background-color: var(--gray_fb);
    @include tablet {
      padding: 60px 20px 80px 20px;
    }

    .technicalGuidanceInner {
      @include desktop {
        max-width: 1440px;
        margin: 0 auto;
      }

      h5 {
        font-weight: var(--fw_bold);
        @include tablet {
          font-size: var(--fs_20);
        }
      }
      p {
        font-size: var(--fs_14);
        line-height: var(--lh_170);
        margin: 24px 0 32px 0;
        @include tablet {
          font-size: var(--fs_18);
          margin: 24px 0 60px 0;
        }
      }
      .guidanceList {
        display: grid;
        gap: 50px;
        @include tablet {
          gap: 20px;
          grid-template-columns: 1fr 1fr;
        }
        @include desktop {
          grid-template-columns: repeat(4,1fr);
        }
  
        li {
          text-align: center;
          
          img {
            width: 100%;
          }
          dl {
            padding-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
  
            dt {
              font-size: var(--fs_16);
              font-weight: var(--fw_medium);
              @include tablet {
                font-size: var(--fs_20);
              }
            }
            dd {
              position: relative;
              padding-left: 20px;
              color: var(--main_gray);
              &::before {
                content: '';
                position: absolute;
                top: 12px;
                left: 6px;
                width: 6px;
                height: 6px;
                background-color: var(--main_green);
                border-radius: 50%;
              } 
            }
          }
        }
      }
    }
  }
}
@import '/src/styles/media.scss';

.geneHowto {
  h2 {
    margin-bottom: 20px;
  }
  .howtoList {
    display: flex;
    flex-direction: column;
    gap: 20px;

    > li {
      img {
        margin-bottom: 20px;
        width: 100%;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 4px;

        li {
          font-size: var(--fs_16);
          span {
            color: #c103f1;
            font-weight: var(--fw_bold);
          }
        }
      }
    }
  }
  .howtoInfo {
    margin-top: 40px;
    h3 {
      font-size: var(--fs_18);
    }
    p {
      font-size: var(--fs_14);
      color: #c103f1;
    }
  }
}
@import '/src/styles/media.scss';
.quickMenuContainer {
  margin-bottom: 80px;
  @include desktop {
    margin-bottom: 0;
  }
  .rowCon {
    @include tablet {
      display: flex;
      flex-direction: column;
    }
  }
  @include desktop {
    padding: 120px 232px;
  }
  .quickMenuHeader {
    font-size: 14px;
    color: #000;
    text-align: center;
    padding: 30px 20px;
    @include desktop {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    strong {
      display: inline-block;
      background-color: #34495e;
      color: #fff;
      padding: 10px;
      border-radius: 26px;
      margin-bottom: 24px;
    }
    span {
      font-weight: 500;
    }
    h2 {
      margin: 0;
      @include desktop {
        font-size: 32px;
      }
    }
    p {
      margin-top: 24px;
      color: #555555;
      @include desktop {
        font-size: 20px;
        width: 467px;
      }
    }
  }
  .quickMenuGrid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(3, 1fr); // 태블릿에서 한 줄에 3개씩
    }
  }
  .quickMenuItem {
    font-size: 14px;
    font-weight: bold;
    color: #555555;
    background-color: white;
    border-radius: 16px;
    text-align: center;
    text-decoration: none;
    color: #555555;
    cursor: pointer;
    transition:
      transform 0.3s ease,
      background-color 0.3s ease; // 부드러운 전환 효과
    &:hover {
      background-color: #4e995a;
      color: #fff;
      transform: translateY(-6px); // 위로 6px만큼 이동
      box-shadow: 0px 32px 16px 0px rgba(0, 0, 0, 0.1);
    }

    @include desktop {
      padding: 10px 100px;
    }
    border: 1px solid #dddddd;

    .quickMenuIcon {
      padding: 20px;
      img {
        width: 50px; // 이미지 크기는 필요에 따라 조정
      }
    }

    .quickMenuTitle {
      margin: 0;
      padding: 10px 0;
      @include desktop {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

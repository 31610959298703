@import '/src/styles/media.scss';

.cooperPointInner {

  h5 {
    padding-left: 20px;
  }
  .cooperPointTab {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 4px;
    padding: 30px 20px;
    margin: 30px 0 15px 0;
    background-color: var(--gray_fb);
    @include tablet {
      margin: 36px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 18px;
    }

    li {
      @include tablet {
        width: 15%;
      }
      @include desktop {
        width: 9%;
      }
    
      button {
        width: 100%;
        font-size: var(--fs_16);
        background-color: var(--white_ff);
        border: solid 1px var(--main_right_gray);
        padding: 4px 8px;
        border-radius: 20px;
        &:hover,
        &.current {
          background-color: var(--main_green);
          color: var(--gray_f8);
        }
        @include tablet {
          font-size: var(--fs_18);
        }
      }
    }
  }
  > P {
    font-size: var(--fs_14);
    text-align: center;
    padding: 0 20px;
    line-height: var(--lh_170);
    @include tablet {
      font-size: var(--fs_18);
    }
    strong{
      font-weight: var(--fw_normal);
      color: var(--main_green);
    }
  }
  .cooperPointTable {
    margin: 30px 0 60px 0;
    padding: 0 20px;
    @include tablet {
      margin: 40px 0 80px 0;
    }
    @include desktop {
      margin: 60px 0 120px 0;
      padding: 0;
    }
    li {
      p {
        font-weight: var(--fw_bold);
        color: var(--main_green);
        margin-bottom: 20px;

        @include desktop {
          font-size: var(--fs_20);
          margin-bottom: 30px;
        }
      }
    }
  }
}

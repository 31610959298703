@import '/src/styles/media.scss';

.pedigree {
  padding: 10px 15px;
  @include laptop {
    padding: 25px 30px;
  }

  h2 {
    font-weight: var(--fw_medium);
    margin-bottom: 15px;
    font-size: var(--fs_18);
    @include laptop {
      font-size: var(--fs_24);
    }
  }
  .static {
    tbody {
      tr {
        td {
          border-right: solid 1px var(--gray_df);
          border-bottom: solid 1px var(--gray_df);
          button {
            color: var(--sub_blue);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  > p {
    margin: 40px 0 10px 0;
    font-size: var(--fs_14);
    font-weight: var(--fw_medium);
  }
  .pedigreeList {
    overflow-y: auto;
    .pediHead {
      display: flex;
      min-width: 734px;
      @include tablet {
        padding-right: 18px;
      }

      li {
        text-align: center;
        font-size: var(--fs_14);
        font-weight: var(--fw_medium);
        padding: 10px 0;
        background-color: var(--gray_f2);

        &:nth-child(1) {
          width: 10%;
        }
        &:nth-child(2) {
          width: 10%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 15%;
        }
        &:nth-child(6) {
          width: 15%;
        }
        &:nth-child(7) {
          width: 20%;
        }
        &:nth-child(8) {
          width: 10%;
        }
      }
    }
    .pediBody {
      max-height: 500px;
      overflow-y: scroll;
      min-width: 734px;

      > li {
        border-bottom: solid 1px var(--gray_f2);
        
        &:nth-child(even) {
          background-color: var(--gray_e8);
        }

        ul {
          display: flex;
          
          li {
            font-size: var(--fs_14);
            text-align: center;
            padding: 6px 0;
            
            &:nth-child(1) {
              width: 10%;
            }
            &:nth-child(2) {
              width: 10%;
            }
            &:nth-child(3) {
              width: 10%;
            }
            &:nth-child(4) {
              width: 10%;
            }
            &:nth-child(5) {
              width: 15%;
            }
            &:nth-child(6) {
              width: 15%;
            }
            &:nth-child(7) {
              width: 20%;
            }
            &:nth-child(8) {
              width: 10%;
            }
          }
        }
      }
    }
  }
}
.oxPrint {

  max-width:1000px;
  
  
h1 {
  background-color: var(--main_blue);
  color: var(--white_ff);
  padding: 10px 20px;
  font-size: var(--fs_24);
  max-width:1000px;
  

  span {
    display: inline-block;
    margin-left: 20px;
    font-size: var(--fs_16);
    font-weight: var(--fw_normal);
  }
}

h2 {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
  }
}

.oxPrintInner {
  
  max-width:1000px;
  margin:0 auto;  
  border:1px solid #f2f2f2;  
  margin-bottom:60px;
  padding:20px;
  box-shadow:0,0,0.2;
  box-shadow:0 1rem 1rem rgba(0,0,0,0.1);

  .oxPrintInfo {
    margin: 20px 0;

    li {
      position: relative;
      padding-left: 15px;
    
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--main_green);
      }
    }
  }
}
.oxPic {
  display: flex;
  background-color: var(--sub_gray);
   
  li {
    width: 50%;
    text-align: center;
    padding: 6px 0;

    img {
      width:100%;
      height:100%
    }

  }
}
.oxDetail {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 30px;

    h3 {
      margin-bottom: 10px;

      span {
        font-size: var(--fs_14);
        color: var(--main_green);
        font-weight: var(--fw_normal);
      }
    }
    p {
      font-size: var(--fs_16);
      color: var(--main_gray);
    }

            
    > li:nth-child(3) {      
      flex:1;
      table {
        width:40%;

      }
    }

    > li:nth-child(4) {
      flex:1;          
      table {
        width:50%;
      }
    }

    .rowtable {
      display:flex;
      flex-direction:row;
      justify-content:flex-start;
      gap:50px;
    }

    
    table {
      
    th {
      width: 20%;
      background-color: var(--main_blue);
    }

    td {
      text-align:left;
      padding-left:10px;        
    }
    tr {
      &:nth-child(odd) {
        background-color: transparent;
      }
    }
  }
  .oxDetailSub {
    p {
      position: relative;
      padding-left: 12px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--dark_99);
      }
    }
  }
  .oxChart {
  
    position: relative;
    font-size: var(--fs_14);

    ul {
      border: solid 1px var(--gray_de);
      
      li {
        display: flex;
        justify-content: space-between;
        &.oxChartHead {
          background-color: var(--gray_e6);
        }
        &:nth-child(even) {
          background-color: var(--gray_f8);
        }

        .oxChartLeft {
          display:  flex;
          width: 25%;
          text-align: center;
          span {
            &:nth-child(1) {
              width: 70%;
            }
            &:nth-child(2) {
              width: 30%;
            }
          }
        }
        .oxChartRight {
          width: 25%;
          display:  flex;
          text-align: center;
          span {
            &:nth-child(1) {
              width: 30%;
            }
            &:nth-child(2) {
              width: 70%;
            }
          }
        }
        .oxBarChart {
          position: relative;
          flex: 1;
          margin: 0 20px;

          .oxBar {
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--main_green);
            height: 80%;
          }
        }
      }
    }
  }
}
}
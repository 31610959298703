.newGraphTotal {
  position: relative;
  max-width: 1440px;
  min-width: 1122px;
  min-height: 793px;
  margin: 0 auto;
  padding: 40px 30px 80px;
  background-color: var(--white_ff);

  h1 {
    text-align: center;
    font-size: var(--fs_14);
    font-weight: var(--fw_normal);
    padding: 4px 0;
    border: solid 1px var(--gray_de);
    background-color: antiquewhite;
  }
  .dotChartArea {
    padding: 5px 0;
    background-color: var(--gray_de);
    height: 261px;

    tspan {
      font-size: var(--fs_12);
    }
  }
  .dotChartAreaDual {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px 0;
    background-color: var(--gray_de);
    height: 261px;

    tspan {
      font-size: var(--fs_12);
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    gap: 10px;

    li {
      &.totalScc01 {
        .dotChartArea {
          .recharts-bar {
            font-size: var(--fs_12);
          }
          .fatLabel {
            li {
              &:nth-child(1) {
                &::before {
                  background-color: green;
                }
              }
              &:nth-child(2) {
                &::before {
                  background-color: yellow;
                }
              }
              &:nth-child(3) {
                &::before {
                  background-color: blue;
                }
              }
              &:nth-child(4) {
                &::before {
                  background-color: red;
                }
              }
            }
          }
        }
        .dotChartAreaDual {          
          .recharts-bar {
            font-size: var(--fs_12);            
          }
          .flexbox:before {
            content: "";                        
            flex-basis: 100%;
            height: 5px;
          }
          .fatLabel {            
            flex-basis: 100%;
            li {
              &:nth-child(1) {
                &::before {
                  background-color: green;
                }
              }
              &:nth-child(2) {
                &::before {
                  background-color: yellow;
                }
              }
              &:nth-child(3) {
                &::before {
                  background-color: blue;
                }
              }
              &:nth-child(4) {
                &::before {
                  background-color: red;
                }
              }
            }
          }
        }
      }
      &.totalFat01 {
        .dotChartArea {
          .recharts-bar {
            font-size: var(--fs_12);
          }
          .fatLabel {
            li {
              &:nth-child(1) {
                &::before {
                  background-color: green;
                }
              }
              &:nth-child(2) {
                &::before {
                  background-color: yellow;
                }
              }
              &:nth-child(3) {
                &::before {
                  background-color: blue;
                }
              }
              &:nth-child(4) {
                &::before {
                  background-color: red;
                }
              }
            }
          }
        }
      }
      &.totalProt01 {
        .dotChartArea {
          .recharts-bar {
            font-size: var(--fs_12);
          }
          .fatLabel {
            li {
              &:nth-child(1) {
                &::before {
                  background-color: blue;
                }
              }
              &:nth-child(2) {
                &::before {
                  background-color: red;
                }
              }
              &:nth-child(3) {
                &::before {
                  background-color: purple;
                }
              }
              &:nth-child(4) {
                &::before {
                  background-color: orange;
                }
              }
            }
          }
        }
      }
      &.totalMun01 {
        .dotChartArea {
          .recharts-bar {
            font-size: var(--fs_12);
          }
          .fatLabel {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-content: center;
            gap: 0;
            margin: 0;
            font-size: var(--fs_12);
            li {
              &:nth-child(1) {
                &::before {
                  background-color: black;
                }
              }
              &:nth-child(2) {
                &::before {
                  background-color: red;
                }
              }
              &:nth-child(3) {
                &::before {
                  background-color: green;
                }
              }
              &:nth-child(4) {
                &::before {
                  background-color: yellow;
                }
              }
              &:nth-child(5) {
                &::before {
                  background-color: blue;
                }
              }
              &:nth-child(6) {
                &::before {
                  background-color: gray;
                }
              }
              &:nth-child(7) {
                &::before {
                  background-color: purple;
                }
              }
              &:nth-child(8) {
                &::before {
                  background-color: orange;
                }
              }
              &:nth-child(9) {
                &::before {
                  background-color: brown;
                }
              }
            }
          }
        }
      }
    }
  }
  
  .fatLabel {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
    font-size: var(--fs_12);

    li {
      position: relative;
      padding-left: 20px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
  h2 {
    font-size: var(--fs_16);
    font-weight: var(--fw_normal);
    margin: 20px 0 10px 0;
  }
}
@import '/src/styles/media.scss';

.commonView {
  margin-bottom: 40px;
  @include tablet {
    margin-bottom: 60px;
  }
  @include desktop {
    margin-bottom: 80px;
  }

  .commonViewInner {
    border-top: solid 2px var(--main_gray);
    border-bottom: solid 1px var(--gray_dd);

    .viewHead {
      padding: 6px;
      border-bottom: solid 1px var(--gray_dd);

      h5 {
        font-size: var(--fs_14);
        @include tablet {
          font-size: var(--fs_18);
        }
        @include tablet {
          font-size: var(--fs_24);
        }
      }
      .viewHeadInfo {
        display: flex;
        justify-content: space-between;
        font-size: var(--fs_12);
        color: var(--main_gray);
        margin-top: 8px;
        @include tablet {
          font-size: var(--fs_14);
        }

        ul {
          display: flex;
          gap: 12px;
          @include tablet {
            gap: 24px;
          }

          li {
            &:first-child {
              position: relative;
              &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-40%);
                right: -6px;
                width: 1px;
                height: 8px;
                background-color: var(--main_gray);
                @include tablet {
                  right: -12px;
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }
    .viewBody {
      padding: 10px;
      @include tablet {
        padding: 20px;
      }

      .fileAttachment {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        .attach {
          position: relative;
          display: inline-block;
          text-decoration: underline;
          padding-left: 20px;
          color: var(--sub_blue);
          font-size: var(--fs_14);
          @include tablet {
            font-size: var(--fs_16);
          }

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-35%);
            left: 0;
            width: 10px;
            height: 11px;
            background: url('/assets/svgs/common/attachedFile.svg') no-repeat
              center/cover;
          }
        }
      }
    }
    .viewFoot {
      border-top: solid 1px var(--gray_dd);
      li {
        display: flex;

        span {
          font-size: var(--fs_14);
          font-weight: var(--fw_bold);
          border-bottom: solid 1px var(--main_black);
          padding: 4px 8px;
          @include tablet {
            font-size: var(--fs_18);
            padding: 6px 12px;
          }
        }
        a {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 4px 8px;
          font-size: var(--fs_12);
          @include tablet {
            font-size: var(--fs_14);
            padding: 6px 12px;
          }
        }
      }
    }
  }
  .viewBtn {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 18px;
    text-align: end;

    li {
      a,
      button {
        display: inline-block;
        font-size: var(--fs_14);
        font-weight: var(--fw_bold);
        color: var(--main_gray);
        border: solid 1px var(--main_gray);
        padding: 4px 8px;
        @include tablet {
          font-size: var(--fs_18);
          padding: 4px 12px;
        }
      }
    }
  }
  .boardPassword {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: var(--2000);

    .boardPasswordInner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--gray_f8);
      width: 90%;
      padding: 40px 0 30px 0;
      @include tablet {
        width: 60%;
      }
      @include desktop {
        width: 600px;
      }

      p {
        font-size: var(--fs_14);
        text-align: center;
        color: var(--main_gray);
        margin-bottom: 20px;
        @include tablet {
          font-size: var(--fs_18);
        }
        @include desktop {
          margin-bottom: 30px;
          font-size: var(--fs_24);
        }
      }
      .boardinputArea {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        white-space: nowrap;
        gap: 8px;

        input {
          flex: 1;
          border: solid 1px var(--gray_dd);
        }
        button {
          font-size: var(--fs_12);
          padding: 4px 8px;
          background-color: var(--main_blue);
          color: var(--gray_f8);
          @include tablet {
            font-size: var(--fs_16);
            padding: 6px 12px;
          }
          @include desktop {
            font-size: var(--20);
            padding: 8px 14px;
          }
        }
      }
      .close {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 12px;
        height: 12px;
        background: url('/assets/svgs/common/X_button.svg') no-repeat center /
          cover;
        @include tablet {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

@import '/src/styles/media.scss';


.siteMap.subCon {
  
  @include desktop {
    padding-top:100px;
  }


.sitemap_menu {
  

  
  @include tablet { 
    display:flex;
    flex-wrap:wrap;
    align-items:flex-start;
    justify-content:center;
  }

  @include desktop {
    
    padding-top:30px;
    
  }



  
    
  li {

    @include tablet {
      flex:0 0 calc(50% - 20px);
      margin-right:20px;
      box-sizing:border-box;
    }

    @include desktop {
      flex: 0 0 calc(25% - 40px);
      margin-right: 40px;
      box-sizing:border-box;
    }

    
    a {
      padding:15px;
      text-align:center;
      color:var(--white_ff);
      font-size:var(--fs_20);
      font-weight:var(--fw_bold);
      border:1px solid var(--main_blue);
      
      background:var(--main_blue);


      @include tablet {
        font-size:var(--fs_24);
      }

    

    }

   
              
    .sitemap_small_menu {
        
      margin-bottom:80px;
       
      li {

        a {
        
          
          text-align:left;
          color:var(--main_gray);
          font-size:var(--fs_16);
          font-weight:var(--fw_normal);
          border:1px solid var(--gray_de);
          background:var(--white_ff);


          @include tablet {
            font-size:var(--fs_18);
            margin-right:-20px;
          }

          @include desktop {
            margin-right:-40px;            
          }


          }

      }
        
        

    }
    
    
  }

}

}
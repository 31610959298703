@import 'styles/media.scss';

.dciCon {
  transform: translateX(0);
  width: 100%;
  min-height: 100vh;
  padding: 10px 20px;

  .dciConInner {
    min-height: calc(100vh - 20px);
    border: solid 1px var(--dark_99);
    background-color: rgb(212, 208, 200);
  }
}

// pdf 공통
.pdfMainArea {
  overflow-x: scroll;
}
.pdfMainHeader {
  display: flex;
  gap: 12px;
  padding: 4px;
  background-color: rgb(241, 241, 241);
  
  > button {
    display: flex;
    align-items: center;
    gap: 12px;
    
    font-size: 14px;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: var(--white_ff);
  }
}
.pdfPagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 4px;
  p {
    font-size: var(--fs_16);
  }
  button {
    padding: 2px 4px;
    background-color: var(--main_green);
    color: var(--white_ff);
    border-radius: 4px;
  }
}
.pdfArea {
  max-height: calc(100vh - 78px);
  overflow-y: scroll;

  .graphHead {
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      gap: 12px;
    }
    button {
      font-size: 16px;
      color: var(--sub_blue);
    }
  }
}
.pdfTable {
  width: 100%;
  border: solid 1px var(--main_blue);

  thead {
    border-bottom: solid 1px var(--main_blue);

    th {
      border-left: solid 1px var(--main_blue);
      background-color: rgba(52, 73, 94, 0.1);
      padding: 4px 0;
      text-align: center;
      font-size: var(--fs_14);
      font-weight: var(--fw_medium);
      @include desktop {
        font-size: var(--fs_16);
      }

      &:first-child {
        border-left: none;
      }
    }
  }
  tbody {
    tr {
      border-bottom: solid 1px var(--main_blue);
      &:nth-child(even) {
        background-color: var(--gray_e8);
      }
      &:last-child {
        border-bottom: none;
      }
  
      td {
        border-left: solid 1px var(--main_blue);
        padding: 0 4px;
        font-size: var(--fs_14);
        text-align: center;
        button {
          font-size: 14px;
          color: var(--sub_blue);

          @include desktop {
            font-size: 16px;
          }
        }
        @include desktop {
          font-size: var(--fs_16);
        }
      
        &:first-child {
          border-left: none;
        }
        &.tdBorderLeft {
          border-left: solid 1px var(--main_blue);
        }
        &.numberData {
          text-align: end;
        }
      }
    }
  }
}
.pdfLogo {
  position: absolute;
  right: 30px;
  bottom: 20px;
}

.dciDoc {
  background-color: var(--gray_f8);
  height: calc(100vh - 22px);
  display: grid;
  place-items: center;
  text-align: center;
  overflow-x: scroll;

  .dciDocInner {
    width: 300px;
    border: solid 1px var(--gray_de);
    background-color: var(--white_ff);
    
    p {
      background-color: var(--main_blue);
      padding: 4px 0;
      color: var(--white_ff);
    }
    span {
      &:nth-of-type(1) {
        font-size: var(--fs_16);
      }
      &:nth-of-type(2) {
        font-size: var(--fs_14);
      }
    }
    .docInput {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      font-size: var(--fs_16);
      margin: 10px 0;

      input {
        height: 30px;
        border: solid 1px var(--gray_de);
        padding-left: 4px;
      }
    }
    button {
      display: block;
      text-align: center;
      width: 80px;
      margin: 0 auto;
      background-color: var(--main_green);
      color: var(--white_ff);
      padding: 4px 0;
      border-radius: 3px;
    }
  }
}
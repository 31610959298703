@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1440px) {
    @content;
  }
}
@import '/src/styles/media.scss';

.subMain {
  position: relative;
  height: 300px;
  padding-bottom: 30px;
  z-index: var(--998);
  @include tablet {
    height: 400px;
    padding-bottom: 40px;
  }
  @include desktop {
    height: 450px;
    padding-bottom: 0;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,20%);
    color: var(--white_ff);
    font-weight: var(--fw_bold);
    font-size: var(--fs_32);
    white-space: nowrap;
    @include tablet {
      transform: translate(-50%);
      font-size: var(--fs_40);
    }
    @include desktop {
      font-size: var(--fs_48);
    }
  }
}
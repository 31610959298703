@import '/src/styles/media.scss';

.staff {
  text-align: center;
  
  .subTitle {
    text-align: start;
    padding-bottom: 15px;
    border-bottom: solid 3px var(--main_green);
    @include desktop {
      margin: 100px 0 30px 0;
    }
  }
  img {
    width: 80%;
    max-width: 400px;
    margin-bottom: 80px;
    @include tablet {
      width: 90%;
      max-width: 900px;
    }
    @include desktop {
      max-width: 1100px;
      transform: translateX(-5%);
    }
  }
}
@import '/src/styles/media.scss';

.promotionalVideo {
  
  .boardTableHead {
    @include tablet {
      display: flex;
      border-bottom: solid 2px var(--main_black);

      li {
        text-align: center;
        font-weight: var(--fw_bold);
        padding: 12px 0;
        &.number {
          @include desktop {
            width: 10%;
          }
        }
        &.title {
          width: 70%;
          @include tablet {
            width: 50%;
          }
        }
        &.write {
          width: 10%;
          @include tablet {
            width: 10%;
          }
        }
        &.date {
          width: 20%;
          @include tablet {
            width: 16%;
          }
        }
        &.view {
          @include tablet {
            width: 8%;
          }
        }
        &.attach {
          @include tablet {
            width: 6%;
          }
        }
      }
    }
  }

  .youtubeBoard {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
    margin-top: 50px;
    margin-bottom: 40px;
    @include tablet {
      gap: 40px;
      margin-top: 60px;
      margin-bottom: 60px;
      grid-template-columns: repeat(auto-fill, minmax(334px, 1fr));
    }
    @include desktop {
      column-gap: 32px;
      row-gap: 40px;
      margin-top: 80px;
      margin-bottom: 80px;
    }

    li {
      .grid-item {
        max-width: 250px;
        width: 100%; /* 그리드 아이템이 최대 너비를 유지하면서 100%를 차지하도록 설정 */
        @include tablet {
          max-width: 334px;
        }
      }

      img {
        width: 100%;
      }
      p {
        margin: 8px 0;
        font-size: var(--fs_12);
        font-weight: var(--fw_bold);
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        @include tablet {
          font-size: var(--fs_16);
        }
      }
      .youtubeAside {
        display: flex;
        justify-content: space-between;
        font-size: var(--fs_10);
        color: var(--main_right_gray);
        @include tablet {
          font-size: var(--fs_14);
        }
      }
    }
  }
}

#m_lnb	{ position:relative; width:100%; height:50px; margin-bottom:40px; padding-left:39px; border-bottom:1px solid #b3b7bb; background:#d2d7dc}
#m_lnb:after	{ content:""; clear:both; display:block;}
#m_lnb .home	{ position:absolute; top:0; left:0; width:39px; height:49px; padding-top:16px; border-right:1px solid #b3b7bb; text-align:center;}
#m_lnb .mn	{ position:relative; float:left; width:33.33%; border-right:1px solid #b3b7bb}
#m_lnb .mn ul	{ display:none;}

#m_lnb .mn.active	{ background:#47505f}
#m_lnb .mn.active ul	{ display:block; position:absolute; top:49px; left:0; width:100%; padding:10px 15px; background:#47505f; z-index:10}
#m_lnb .mn.active li a	{ display:block; color:#fff; font-size:14px; letter-spacing:-1px; line-height:130%; margin-bottom:10px}



#body>section	{ position:relative; margin-bottom:30px; padding:0 15px }
#body>section .pageTitle	{ position:relative; margin-bottom:30px; /*padding-bottom:17px;*/ color:#000; font-size:28px; font-family: 'Nanum Square'; text-align:center; line-height:100%}
#body>section .pageTitle:after	{ content:""; display:block; position:absolute; bottom:0; left:50%; width:30px; height:1px; margin-left:-15px; background:#000}
.techinfoview h2,
.techinfo_view h2	{ margin-bottom:15px; padding-left:30px; color:#333; font-size:20px; background:url('/assets/images/sub/bgH2.png') no-repeat 0 50%}
.sub_content:after	{ content:""; clear:both; display:block}

.tab_menu	{ margin-bottom:30px}
.tab_menu:after	{ content:""; clear:both; display:block; }
.tab_menu li	{ float:left; width:50%; margin:-1px 0 0 -1px; box-sizing:border-box; border:1px solid #ccc; }
.tab_menu li:nth-child(2n+1)	{ margin-left:0}
.tab_menu li a	{ display:block; color:#444; line-height:42px; text-align:center;}
.tab_menu li a.active	{ color:#fff; background:#4a75bb}

.pageInfo	{ margin-top:60px; padding:15px 30px 5px; border:1px solid #e1e1e1; background:#f8f8f8}
.pageInfo:after	{ content:""; clear:both; display:block;}
.pageInfo dl	{ display:block; margin-bottom:10px; }
.pageInfo dt	{ position:relative; display:inline-block; width:95px; padding-right:15px; color:#333; font-size:14px; line-height:22px; }
.pageInfo dt:after	{ content:""; clear:both; display:block; position:absolute; top:8px; right:0; width:1px; height:8px; background:#bfbfbf}
.pageInfo dd	{ display:inline-block; padding-left:10px; color:#666; font-size:14px; line-height:22px; }

.paging{padding:15px 0 0;text-align:center;line-height:normal;}
.paging a {
    display:inline-block;
    position:relative;
    margin:0 -2px;
    padding:2px 6px;
    font-weight:normal;
    font-size:12px;
    font-family:Tahoma, Sans-serif;
    color:#6a6a6a;
    line-height:normal;
    text-decoration:none;
    vertical-align:middle
    }

.paging strong{
    display:inline-block;
    position:relative;
    margin:0 -2px;
    padding:2px 6px;
    font-weight:bold;
    font-size:12px;
    font-family:Tahoma, Sans-serif;
    color:#6a6a6a;
    line-height:normal;
    text-decoration:none;
    vertical-align:middle
    }
.paging a:hover,
.paging a:active,
.paging a:focus{background-color:#f8f8f8}
.paging strong{color:#00569e}
.paging .direction{font-weight:normal;color:#767676;white-space:nowrap}
.paging .direction span{font-weight:bold;font-size:12px}
.paging .direction:hover,
.paging .direction:active,
.paging .direction:focus{background-color:#fff;color:#333}

.btnWrap	{ margin-top:20px; text-align:right}

.table	{ border-top:2px solid #34495e; text-align:center;}
.table th,
.table td	{ padding-top:5px; padding-bottom:5px; border-bottom:1px solid #e1e1e1; border-left:1px solid #e1e1e1}
.table th	{ color:#000; font-weight:normal; background:#f7f7f7}
.table .first	{ border-left:0}

.nohead th,
.nohead td	{ text-align:left; padding:15px 25px 14px 25px;}
.nohead th	{ border-left:0;}

/* 인사말 */
.greeting	{ position:relative;  }
.greeting .imgGreeting	{ display:none}
.greeting strong	{ color:#4a75bb; font-size:24px; font-family:"Nanum Square"; line-height:140% }
.greeting p	{ margin-bottom:30px; word-break:keep-all}
.greeting p.sign	{ color:#333}
.greeting p.sign span	{ margin-left:20px;}
.greeting p.sign img	{ vertical-align:text-bottom}

/* 연혁 및 일반현황 */
.introText	{ margin-bottom:45px; line-height:160%; word-break:keep-all }
.introText strong	{ display:block; margin-bottom:20px; font-size:20px; color:#2f60c2; line-height:30px;}
.introWrap	{ margin-bottom:60px;}
.introWrap .table	{ margin-bottom:20px;}
.introWrap .table span { display:block; font-size:12px}
.introWrap .fr	{ float:none}
.introWrap .fr img	{ width:100%}
.article_tab .tabBtn		{ margin-bottom:40px;}
.article_tab .tabBtn:after	{ content:""; clear:both; display:block;}
.article_tab .tabBtn li		{ float:left; width:14.2%; margin-left:-1px; text-align:center;}
.article_tab .tabBtn li.fir	{ width:15%; margin-left:0}
.article_tab .tabBtn li a.tabover,
.article_tab .tabBtn li a:hover{ position:relative; color:#333; border-left:1px solid #999; border-right:1px solid #999; border-bottom:1px solid #fff; border-top:2px solid #34495e; background:#fff; text-decoration:none; z-index:5}
.article_tab .tabBtn li a	{ display:block; padding:10px 0; border:1px solid #e1e1e1; line-height:24px; background:#f7f7f7}
.historyWrap .conHistory	{ position:relative; }
.historyWrap .conHistory span	{ display:none}
.historyWrap .conHistory dl	{ position:relative; padding-top:30px; line-height:140%}
.historyWrap .conHistory dl:before	{ content:""; clear:both; display:block; position:absolute; top:0; left:30%; width:1px; height:100%; margin-left:-7px; background:#d1d1d1}
.historyWrap .conHistory dl:after	{ content:""; clear:both; display:block;}
.historyWrap .conHistory dl:nth-of-type(1)	{ padding-top:0}
.historyWrap .conHistory dt	{ position:relative; float:left; width:30%; color:#333; font-size:17px; font-weight:600; }
.historyWrap .conHistory dt:after	{ content:""; clear:both; display:block; position:absolute; top:5px; right:0; width:14px; height:14px; border-radius:7px; background:#d1d1d1}
.historyWrap .conHistory dd	{ float:left; width:70%; padding-left:35px;}
.historyWrap .h1990 span	{ padding-top:52px}

/* 사업소개*/
.bizWrap *	{  word-break:keep-all}
.bizWrap h3	{ margin-bottom:10px; color:#4a75bb; font-size:20px; font-family:"Nanum Square"; text-align:center; }
.bizWrap .textTitle strong	{ display:block; margin-bottom:10px; font-size:18px;}
.bizWrap .textTitle.biz07 .bizImg	{ display:none}

.bizWrap .imgList:after	{ content:""; clear:both; display:block}
.bizWrap .imgList .bizImg img	{ max-width:100%}
.bizWrap .imgList .listStyle01	{ overflow:hidden; }
.bizWrap .imgList>p	{ margin-top:10px}

.bizWrap .listStyle01	{ margin-top:20px; margin-left:10px}
.bizWrap .listStyle01 .small { display:block; font-size:12px; }
.bizWrap .listStyle04	{ margin:10px 0 20px 0}
.bizWrap .bizBox		{ padding:10px; border:1px solid #ccc; border-radius:5px; text-align:center;  }
.bizWrap .bizBox	 img	{ max-width:100%}
.bizWrap .bizBox.mg60f { margin-bottom:0}

/* 사업개요 */
.overview	{ text-align:center}
.overview	a	{ display:inline-block; width:100%; margin:20px 0 0 0; padding:165px 0 50px 0; border:1px solid #e4e4e4; border-radius:15px; }
.overview	strong	{ display:block; margin-bottom:20px; color:#333; font-size:20px; }
.overview span	{ display:block;}
.overview .text	{ margin-bottom:25px; color:#333; font-size:14px; line-height:18px; }
.overview .more	{ width:82px; margin:0 auto; border:1px solid #4a75bb; color:#4a75bb; font-size:14px;  line-height:27px; }

/* 유우군검정은 어떻게 */
.biz052 li	{ margin:0 0 20px 0; padding:30px; border:1px solid #ccc; border-radius:5px;}
.biz052 li div	{ display:none}

/* 한국형 보증씨수소의 선발 */
.biz061Wrap li	{ position:relative; width:100%; margin:0 0 30px 0; padding:30px 20px 10px 20px; border:1px solid #ccc; text-align:center}
.biz061Wrap li:first-child:after	{ display:none}
.biz061Wrap li h2	{ margin:0 0 20px 0; padding:0; color:#4a75bb; font-size:20px; background:none}
.biz061Wrap li h2 span	{ display:inline-block; width:36px; height:36px; margin-right:10px; border-radius:50%; color:#fff; text-align:center; line-height:36px; background:#4a75bb}
.biz061Wrap li div	{ display:inline-block; margin-bottom:20px; text-align:center; vertical-align:top}
.biz061Wrap li div img	{ display:block; margin:0 auto 10px; }

/* 청정육종농가는 */
ol.biz062 li	{ position:relative; width:100%; margin-bottom:30px; padding:80px 0 20px 0; border:1px solid #ddd; font-weight:600; text-align:center }
ol.biz062 li span	{ font-size:14px; font-weight:400; letter-spacing:-1px}
ol.biz062 li.step1:after	{ display:none;}
.albumList	{ margin-bottom:60px; padding:40px 50px 10px 10px; border:1px solid #ccc; background:#f7f7f7}
.albumList	 ul	{ margin-bottom:20px; }

/* 후대검정농가는 */
ol.biz063	{ margin-bottom:30px}
ol.biz063 li	{ position:relative; width:100%; margin-bottom:30px; padding:80px 0 20px 0; border:1px solid #ddd; font-weight:600; text-align:center }
ol.biz063 li span	{ display:block; font-size:14px; font-weight:400; letter-spacing:-1px}
ol.biz063 li.step1:after	{ display:none;}



/* 직원현황 */
.dcic_org	{ text-align:center}
.dcic_org:after	{ content:""; clear:both; display:block}
.dcic_org .h1:after	{ content:""; display:block; position:absolute; bottom:-30px; left:50%; width:1px; height:30px; background:#b9b9b9}
.d1_list_wr	{ position:relative; margin:0 auto}
.d1_list_wr:before	{ content:""; display:block; position:absolute; top:0; left:24%; width:52%; height:1px; background:#b9b9b9}
.dcic_org .d1	{ position:relative; display:inline-block; width:48%; padding-top:26px;}
.dcic_org .d1:before	{ content:""; display:block; position:absolute; top:0; left:50%; width:1px; height:26px; background:#b9b9b9}
.dcic_org .d1:nth-of-type(1)	{ float:left}
.dcic_org .d1:nth-of-type(2)	{ float:right}
.dcic_org .d1_list	{ height:108px; padding:10px 20px; border:1px solid #ccc; border-top:0; text-align:left}
.org_table .table	{ margin-top:30px}
.org_table .table th,
.org_table .table td	{ word-break:break-all}
.org_listStyle01	{ margin-top:20px; margin-left:10px}


/* 찾아오시는 길 */
.root_daum_roughmap	{ width:100% !important}
.location	{ margin-top:30px; }
.location:after	{ content:""; clear:both; display:block}
.location p span	{ display:block; color:#666; font-size:14px; font-weight:400; line-height:100%}
.location dl	{ margin-top:15px; padding-top:15px; border-top:1px solid #ccc}
.location dt	{ float:left; width:15%; padding:5px 0; color:#4a75bb; font-weight:600}
.location dd	{ float:left; width:85%; padding:5px 0; }

/* 이달의 검정농가 */
.chkfarm_list:after	{ content:""; clear:both; display:block;}
.chkfarm_list>div	{ display:none; margin:0 0 4% 0; padding:30px 20px; border:1px solid #d4d4d4;}
.chkfarm_list>div:nth-of-type(1),
.chkfarm_list>div:nth-of-type(2),
.chkfarm_list>div:nth-of-type(3)	{ display:block;}
.chkfarm_list>div h2	{ position:relative; margin-bottom:15px; padding:0 0 10px 0; color:#4a75bb; font-size:16px; font-weight:600; line-height:22px; word-break:keep-all; background:none }
.chkfarm_list>div h2:after	{ content:""; clear:both; display:block; position:absolute; bottom:0; left:0; width:30px; height:2px; background:#666}
.chkfarm_list>div li a	{ position:relative; display:inline-block; width:100%; height:34px; margin-top:5px; padding:0 37px 0 10px; overflow:hidden; border:1px solid #d4d4d4; font-size:14px; font-weight:600; line-height:32px; letter-spacing:-0.5px; text-overflow:ellipsis; white-space:nowrap; }
.chkfarm_list>div li a:hover	{ color:#fff; text-decoration:none; background:#34495e}
.chkfarm_list>div li a:hover span	{ background-position:-233px 5px}
.listBtn	 .btn,
.beforeBtn .btn	{ width:100%}
.beforeBtn,
.before_data	{ display:none}

/* 행정구역별 검정사업현황 */
.state_list	{ border-top:1px solid #d4d4d4}
.state_list dl	{ display:none; position:relative; padding:20px 0 10px 0; border-bottom:1px solid #d4d4d4}
.state_list dl:after	{ content:""; clear:both; display:block;}
.state_list dl:nth-of-type(1),
.state_list dl:nth-of-type(2)	{ display:block}
.state_list dt	{ margin-bottom:10px; color:#4a75bb; font-size:20px; font-weight:600; line-height:34px; }
.state_list dd	{ position:relative; float:left; width:22%; height:34px; margin:0 0 10px 4%; border:1px solid #d4d4d4; line-height:32px;}
.state_list dd:nth-of-type(4n+1)	{ margin-left:0}
.state_list dd span	{ display:block; padding:0 10px; color:#d4d4d4; background:#f4f4f4}
.state_list dd a	{ display:block; padding:0 10px; color:#666; background:#fff}
.state_list dd a:hover	{ color:#fff; text-decoration:none; background:#34495e}
.state_list dd a:hover .ico	{ background-position:-233px 5px}

/* 검정성적조회 */
.oxshcWrap	{ position:relative; margin-bottom:57px; padding:20px 20px 33px 20px; border-top:1px solid #ccc; border-bottom:1px solid #ccc; background:#f5f5f5;}
.oxshcWrap:after	{ content:""; clear:both; display:block;}
.oxshcWrap span	{ float:left; width:100%; margin-bottom:10px;}
.oxshcWrap span label	{ float:left; width:30%; margin-top:7px; padding-left:25px; color:#333; font-size:14px; font-weight:600; word-break:break-all}
.oxshcWrap span .text,
.oxshcWrap span .select	{ width:70%}
.oxshcWrap span .text.date	{ width:33%}
.oxshcWrap span em	{ display:inline-block; width:4%; text-align:center; vertical-align:middle; line-height:36px; font-style:normal}

.oxshcWrap.span1	{ text-align:center}
.oxshcWrap.span1 span label	{ float:none; display:inline-block; width:auto; margin-top:0}
.oxshcWrap.span1 span .select	{ width:auto}
.oxshcWrap.span1 span .text	{ width:65%}

.oxshcWrap .btnSearch	{ position:absolute; bottom:-20px; left:50%; height:42px; margin-left:-69px; }
.oxshcWrap .btnSearch	a	{ display:block; padding:0 40px; color:#fff; font-weight:600; line-height:42px;  background:#34495e; }
.oxshcWrap .btnSearch	a:hover	{ text-decoration:none}
.oxshcWrap .btnSearch	a img	{ margin:12px 5px 0 0; }
.oxshcWrap .btnSearch a.print		{ display:none}


.oxshcWrap.span3 #performace .btnSearch a	{ display:inline-block;}

.oxshcWrap .btnSearch a.tooltip	{ position:relative}
.oxshcWrap .btnSearch a.tooltip .tooltiptext	{ position:absolute; bottom:110%; left:50%; width:150px; margin-left:-75px !important; padding:5px; visibility:hidden; border-radius:6px; color:#fff; font-size:12px; text-align:center; word-break:keep-all; line-height:130%; background:#000; z-index:1}
.oxshcWrap .btnSearch a.tooltip .tooltiptext:after	{ content:""; position:absolute; top:100%; left:50%; margin-left:-5px; border:5px solid; border-color:black transparent transparent transparent }
.oxshcWrap .btnSearch a.tooltip:hover .tooltiptext	{ visibility: visible; }

.perWrap.article_tab .tabBtn	{ margin-bottom:30px}
.perWrap.article_tab .tabBtn li	{ width:50%;}

.perWrap2.article_tab .tabBtn	{ margin-bottom:30px}
.perWrap2.article_tab .tabBtn li	{ width:25%;}

.oxInfoWrap h3	{ color:#4a75bb; font-size:18px; font-family: 'Nanum Square'; font-weight:400}
.oxInfoWrap li li	{ padding-left:0; color:#666; background:none;}

.confirmData dl	{ width:100%; margin-bottom:20px; padding:35px 40px 30px 40px; }
.confirmData dt	{ position:relative; margin-bottom:30px; padding:0 0 15px 0; border-bottom:1px solid rgba(255,255,255,.5); color:#fff; font-size:24px; font-family: 'Nanum Square'; font-weight:600}


/* 계획교배 */
.proBest	{ margin-bottom:20px}
.proBest span	{ display:block; border-radius:5px 5px 0 0; overflow:hidden; color:#fff; line-height:32px; text-align:center; background:#0fa075}
.proBest ul	{ padding:15px 0; border:1px solid #ddd; border-top:0; }
.proBest ul:after	{ content:""; clear:both; display:block;}
.proBest li	{ float:left; width:20%; line-height:28px; text-align:center; cursor:pointer}
.proBest li.seldata	{ color:#f00}


/* 구입 연락처 */
.contactMap	{ margin-bottom:3%; padding:9px 20px; border:1px solid #ccc; border-radius:5px; text-align:center}
.contactMap span	{ display:inline-block; margin-top:20px; color:#2f60c2; word-break:keep-all}
.contactus:after	{ content:""; clear:both; display:block;}
.contactus dl	{ float:left; width:48%; height:130px; margin:0 1% 2% 1%; border:1px solid #ccc; text-align:center}
.contactus dt	{ margin-bottom:18px; color:#333; font-weight:600; line-height:50px; background:#f4f4f4}


/* 씨수소자료해설 */
.breedingox ul	{ margin:0 0 60px 10px}
.breedingox dl	{ margin:0 0 30px 10px;}
.breedingox dd	{ word-break:keep-all}

.ox_guideSample	{ margin-bottom:40px; padding:20px; border:1px solid #ededed; border-radius:10px; text-align:center; background:#fcfcfc}
.ox_guideSample img	{ max-width:100%}
.ox_guide *	{ word-break:break-all}
.ox_guide dl	{ margin-bottom:30px}
.ox_guide dt	{ margin-bottom:5px; font-weight:600}
.ox_guide dd	{ margin-left:20px}
.ox_guide dd img	{ max-width:100%}
.ox_guide dd p	{ margin-bottom:10px }
.ox_guide dd .listStyle01 li span	{ display:block; margin-bottom:5px}
.ox_guide dd .listStyle01 li strong	{ color:#4a75bb;}

.ox_get .oxImg	{ margin-bottom:60px; padding:20px; border:1px solid #ccc}
.ox_get dl	{ margin:0 0 30px 10px; }
.ox_get dl dd .listStyle4	{ margin-left:15px}
.ox_get dl dd .table th	{ height:42px}
.ox_get dl dd .table td	{ padding-top:10px; padding-bottom:10px}
.ox_get dl dd .table .first	{ border-left:0}
.ox_get dl dd li span	{ color:#3aa3df}
.ox_get img	{ max-width:100%}
.ox_get .aftercr	{ margin-left:15px;}
.ox_get .aftercr li	{ float:none; width:100%; margin-bottom:10px;}

/* 젖소검정성적표보는법 */
.sub_content .data03_table h2	{ padding-left:0; background:none;}
.data03_table:after	{ content:""; clear:both; display:block;}
.data03_table .mainTable	{ margin-bottom:50px; padding:35px 20px; border:2px solid #34495e; text-align:center }
.data03_table .mainTable img	{ max-width:100%}

.tablewrap	{ margin-bottom:50px; }
.tablewrap:after	{ content:""; clear:both; display:block;}
.tablewrap img	{ max-width:100%}
.tablewrap .imgTable	{ margin-bottom:20px; padding:35px 20px; border:1px solid #ccc; text-align:center; }
.tablewrap .infoTable *	{ word-break:keep-all}
.tablewrap .infoTable .listStyle01 li:first-child	{ margin-top:0}
.tablewrap .infoTable .listStyle01.fl.w50p	{ float:none; width:100%}
.tablewrap .infoTable .listStyle04	{ margin-top:10px}
.tablewrap .infoTable ol>li	{ margin-bottom:10px}
.tablewrap .infoTable ol>li ul	{ margin-left:15px}
.tablewrap .infoTable .table th,
.tablewrap .infoTable .table td	{ padding:10px 0; text-align:center}
.tablewrap .infoTable div.tr	{ text-align:left}
.tablewrap .infoTable .spanList li	{ display:inline-block; width:30%}

.tablewrap .infoTable dl	{ padding:20px; border:1px solid #e1e1e1; background:#f8f8f8}
.tablewrap .infoTable dt	{ margin-bottom:5px; color:#4a75bb; font-weight:600; }

/* 관련사이트 */
.relSite:after	{ content:""; clear:both; display:block}
.relSite li	{ float:left; width:48%; margin:0 0 30px 4%; text-align:center }
.relSite li:nth-child(2n+1)	{ margin-left:0}
.relSite img	{ width:100%; border:1px solid #ccc; box-sizing:border-box;}
.relSite span	{ display:block; margin-top:10px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap}


/* 청정육종농가 */
.album_photo	{ width:100%; margin-top:30px; }
.album_photo div	{ margin-bottom:10px;}
.album_photo div img	{ width:100%}
.album_photo ul:after	{ content:""; clear:both; display:block;}
.album_photo li	{ float:left; margin-left:4px}
.album_photo li:first-child	{ margin-left:0}
.album_photo li img	{ width:107px; height:85px; }

.album_state	{ float:left; width:100%; margin-top:60px}
.album_state dl	{ text-align:center;}
.album_state dl:last-child	{ margin-top:60px; }
.album_state dd	{ display:inline-block; width:150px; height:150px; margin:0 5px; padding:30px 0 0 0; border-radius:50%; color:#fff; font-size:20px;  background:#4a75bb}
.album_state dd strong	{ display:block; margin-bottom:5px; font-size:60px; line-height:100%}
.album_state dl:last-child dd	{ background:#3598db}

/* 사이트맵 */
.sitemap	{ text-align:center}
.sitemap>li	{ display:inline-block; width:47%; margin:0 4% 40px 0; vertical-align:top; }
.sitemap>li:nth-child(2n)	{ margin-right:0}
.sitemap h2	{ margin-bottom:10px; padding-left:0; color:#fff; font-size:18px; font-family: 'Nanum Square'; text-align:center; line-height:54px; background:#4a75bb}
.sitemap .map2	{ border:1px solid #ccc; text-align:left}
.sitemap .map2>li	{ border-bottom:1px solid #ccc}
.sitemap .map3	{ padding:10px 0; border-top:1px solid #ccc; background:#f5f5f5}
.sitemap .map3>li	{ padding:0 20px; }
.sitemap .map3>li>a	{ padding-left:10px; color:#999; font-size:14px; line-height:24px; }
.sitemap a:hover	{ color:#4a75bb !important; text-decoration:none }
.sitemap li a img	{ vertical-align:text-bottom}

/* 개인정보처리방침 */
.person p	{ margin:0 0 20px 20px;}
.person .line1	{ margin:0 0 40px 20px }
.person .line2	{ margin-bottom:20px}
.person .line2 li	{ margin-bottom:10px}
.person .line3	{ margin:5px 0 10px 20px}

/* 낙농용어사전 */
.dicWrap	{ padding:20px; border-radius:10px; background:#f5f5f5}
.dicWrap:after	{ content:""; clear:both; display:block;}
.col-md-3	{ overflow:hidden; margin-bottom:20px; border-radius:10px; background:#0fa075 }
.text-success	{ padding:10px 0; border-bottom:1px dashed #fff; color:#fff; font-size:18px; text-align:center}
.easy-tree	{ max-height:300px; overflow-y:auto}
.easy-tree ul	{ padding-left:40px}
.easy-tree li > span	{ width:100%}
.glyphicon	{ position:relative; top:1px; display:inline-block; margin-right:5px }
.dic_con	{ padding:20px; border-radius:10px; background:#fff}
.dic_con .searchWrap	{ margin-bottom:30px}
.dic_con li	{ padding:15px 0; border-top:1px dashed #ddd}
.dic_con li:first-child	{ border-top:0}
.dic_con li div	{ display:none; margin-top:10px; word-break:keep-all}


@media all and (max-width:480px) {
	.tablewrap .infoTable .spanList li	{ display:inline-block; width:100%}
	.state_list dd	{ width:30%; margin:0 0 10px 5%; }
	.state_list dd:nth-of-type(4n+1)	{ margin-left:5%}
	.state_list dd:nth-of-type(3n+1)	{ margin-left:0}
	.confirmData dl	{ padding:35px 30px 30px 30px; }
	.confirmData dl.farm,
	.confirmData dl.expert,
	.sitemap>li	{ width:100%; margin:0 0 40px 0; }
	.oxshcWrap span label	{ padding-left:0}
	.oxshcWrap span label.ico1,
	.oxshcWrap span label.ico2,
	.oxshcWrap span label.ico3,
	.oxshcWrap span label.ico4,
	.oxshcWrap span label.ico5	{ background:none}
	.proBest li	{ width:25%; }
}

@media all and (max-width:640px) {
	.tableWrap	{ overflow-x:auto}
	.tableWrap .table,
	.tableWrap .tablestyle	{ min-width:600px}
	.span3.oxshcWrap #performace .btnSearch a#excelDownBtn{display:none;}
}

@media all and (min-width:769px) {
	#body	{ width:1400px; margin: 20px auto 0}
	#body:after	{ content:""; clear:both; display:block;}

	#m_lnb	{ display:none}

	#lnb	{ display:block; float:left; width:210px; margin:0 60px 40px 0}
	#lnb>ul	{ border:1px solid #d4d4d4; border-bottom:0;}
	#lnb>ul>li	{ border-bottom:1px solid #d4d4d4; }
	#lnb>ul>li>a	{ display:block; padding:14px 0 14px 18px; color:#444; }
	#lnb>ul>li>a:hover,
	#lnb>ul>li.active>a	{ color:#fff; text-decoration:none; background:#34495e}
	#lnb>ul>li ul	{ display:none;padding:17px 0; background:#f4f4f4}
	#lnb>ul>li ul li a:hover,
	#lnb>ul>li ul li a.active	{ color:#4a75bb; text-decoration:underline}
	#lnb>ul>li.active ul	{ display:block}
	#lnb>ul>li li img	{ margin-top:8px}
	

	#body>section	{ float:left; width:1130px; padding:0}
	#body>section .pageTitle	{ margin-bottom:60px; padding:20px 0; border-bottom:1px solid #cfcfcf; text-align:left;}
	#body>section .pageTitle:after	{ display:none}

	.tab_menu	{ margin-bottom:60px}
	.tab_menu.m4 li	{ width:25%}
	.tab_menu.m3 li	{ width:33.4%}
	.tab_menu li:nth-child(2n+1)	{ margin-left:-1px}
	.tab_menu.m4 li:nth-child(4n+1)	{ margin-left:0}

	.pageInfo dl	{ display:inline-block; margin-right:50px; }
	.pageInfo dt	{ width:auto; }

	#nav	{ display:block; position:absolute; top:30px; right:0}
	#nav li:first-child	{ margin-left:0; padding-left:0; background:none}

	/* 인사말 */
	/*.greeting .imgGreeting	{ position:absolute; top:65px; right:0; display:block;}*/
	.greeting p span	{ display:block;}
	.greeting p.sign span	{ display:inline;}

	/* 연혁 및 일반현황 */
	.article_tab .tabBtn		{ margin-bottom:60px;}
	.introWrap:after	{ content:""; clear:both; display:block;}
	.introWrap .table	{ float:left; width:600px; margin-bottom:0}
	.introWrap .fr	{ float:right}
	.introWrap .fr img	{ width:auto}
	.historyWrap .conHistory	{ min-height:150px; padding-left:255px; }
	.historyWrap .conHistory dl:before	{ left:132px; margin-left:0; }
	.historyWrap .conHistory dt	{ width:140px; }
	.historyWrap .conHistory dd	{ width:auto; padding-left:45px;}

	/* 사업안내 */
	.bizWrap .textTitle	{ margin-bottom:45px; padding:30px; line-height:160%; }
	.bizWrap .textTitle strong	{ font-size:22px; line-height:30px}
	.bizWrap .textTitle.biz07	{ height:181px; text-align:left}
	.bizWrap .textTitle.biz07 .bizImg	{ display:block; float:left; margin-right:30px; overflow:hidden; border-radius:10px}
	.bizWrap .imgList .bizImg	{ float:left; width:300px; margin-right:40px; }
	.bizWrap .imgList>p	{ margin-top:0}
	.bizWrap .listStyle01	{ margin-top:0; margin-left:20px;}
	.bizWrap .listStyle01 .small { padding-left:104px;}
	.bizWrap .bizBox.mg60f { margin-bottom:60px}
	.bizWrap .m { display:none}

	/* 유우군검정은 어떻게 */
	.biz052:after	{ content:""; clear:both; display:block}
	.biz052 li	{ float:left; width:48%; min-height:230px; margin:0 0 3% 4%; padding:30px 40px; }
	.biz052 li:nth-child(2n+1)	{ margin-left:0}
	.biz052 li div	{ display:block; float:left; margin-right:40px; }
	.biz052 li div img	{ width:136px;}
	.biz052 li dl	{ float:left; width:284px; }

	/* 한국형 보증씨수소의 선발 */
	.biz061Wrap:after	{ content:""; clear:both; display:block }
	.biz061Wrap li	{ float:left; width:540px; margin:0 50px 40px 0; padding:40px 0; }
	.biz061Wrap li:nth-child(2n)	{ margin-right:0; }
	.biz061Wrap li:nth-child(2n+1):after	{ display:none}
	.biz061Wrap li h2	{ margin:0 0 20px 50px; text-align:left; }
	.biz061Wrap li div	{ margin-bottom:0; padding:0 14px; }

	/* 청정육종농가는 */
	ol.biz062:after	{ content:""; clear:both; display:block }
	ol.biz062 li	{ float:left; width:186px; margin-left:50px; }

	/* 후대검정농가는 */
	ol.biz063:after	{ content:""; clear:both; display:block }
	ol.biz063 li	{  float:left; width:30%; height:148px; margin-left:5%; margin-bottom:0;  }


	/* 사업개요 */
	.overview	{ padding:60px 0; border:1px solid #ccc; }
	.overview	a	{ width:300px; margin:0 50px; }

	/* 직원현황 */
	.dcic_org .h1	{ margin-bottom:30px; }
	.dcic_org .h1:after	{bottom:-30px; height:30px;}
	.d1_list_wr	{ width:62%; }
	.d1_list_wr:before	{ left:13.5%; width:73.3%; }
	.dcic_org .d1	{ width:187px; }
	.dcic_org .d1_list	{ padding:10px 30px;}
	.org_table:after	{ content:""; clear:both; display:block }
	.org_table .table	{ float:left; width:45%; margin:30px 2.5% 0}
	.org_listStyle01 { position:absolute; bottom:75px; left:20px}

	/* 찾아오시는길 */
	.location p	{ float:left; width:30%; }
	.location dl	{ float:left; width:70%; margin-top:0; padding-top:0; padding-left:10%; border-top:0; border-left:1px solid #ccc}
	.location dt	{ float:left; width:8%; }
	.location dd	{ float:left; width:92%; }

	/* 이달의 검정농가 */
	.chkfarm_list>div h2	{ position:relative; margin-bottom:20px; padding:0 0 10px 0; color:#4a75bb; font-size:16px; font-weight:600; line-height:22px; word-break:keep-all; background:none }
	.chkfarm_list>div	{ display:block; float:left; width:360px; margin:0 0 25px 25px; padding:30px 20px; border:1px solid #d4d4d4; }
	.chkfarm_list>div:nth-child(3n+1)	{ margin-left:0}
	.listBtn	,
	.beforeBtn	{ display:none}

	/* 행정구역별 검정사업현황 */
	.state_list dl	{ display:block; padding:30px 50px 20px 168px }
	.state_list dt	{ position:absolute; top:30px; left:50px; margin-bottom:0; line-height:34px; }
	.state_list dd	{ width:132px; margin:0 0 10px 20px; }
	.state_list dd:nth-of-type(4n+1)	{ margin-left:20px}
	.state_list.quarter dd	{ width:208px}

	/* 검정성적조회 */
	.oxshcWrap	{ margin-bottom:60px; padding:30px 50px; }

	.oxshcWrap.span4 span	{ width:47%; margin-left:6% }
	.oxshcWrap.span4 span:nth-of-type(2n+1)	{ margin-left:0}
	.oxshcWrap.span4 span label	{ width:28%}
	.oxshcWrap.span4 span .text,
	.oxshcWrap.span4 span .select	{ width:72%}
	.oxshcWrap.span4 span .date	{ width:34%}

	.oxshcWrap.span3 span	{ width:auto; margin-left:50px; margin-bottom:0}
	.oxshcWrap.span3 span:nth-of-type(3n+1)	{ margin-left:0}
	.oxshcWrap.span3 span label	{ width:auto; margin-right:20px;}
	.oxshcWrap.span3 span .text,
	.oxshcWrap.span3 span .select	{ width:150px;}
	.oxshcWrap.span3 span .text.date	{ width:80px; }
	.oxshcWrap.span3 span em	{ width:20px;}
	.oxshcWrap.span3 span #smon4	{ width:180px}
	.oxshcWrap.span3 .btnSearch	{ position:relative; bottom:auto; left:auto; float:right; height:36px; margin-left:0; }
	.oxshcWrap.span3 .btnSearch a	{ font-size:14px; line-height:36px; padding:0 20px}
	.oxshcWrap.span3 .btnSearch a img	{ width:15px; margin-top:10px}

	.oxshcWrap.span1	{ margin-bottom:30px; padding:30px 50px 20px 50px;}
	.oxshcWrap.span1 span	{ float:none; display:inline-block ;width:auto; }
	.oxshcWrap.span1 span .text	{ width:250px; }
	.oxshcWrap.span1 .btnSearch	{ position:relative; bottom:auto; left:auto; display:inline-block; height:36px; margin-left:0; vertical-align:middle}
	.oxshcWrap.span1 .btnSearch a	{ display:inline-block; font-size:14px; line-height:36px; padding:0 20px; }
	.oxshcWrap.span1 .btnSearch a img	{ width:15px; margin-top:10px}

	.oxshcWrap.span3 #performace span	{ margin-left:30px}
	.oxshcWrap.span3 #performace span:nth-of-type(3n+1)	{ margin-left:0}

	.oxshcWrap .btnSearch a.tooltip .tooltiptext	{ bottom:130%; width:120px; margin-left:-60px !important; }

	.oxInfoWrap h3	{ margin-bottom:15px; font-size:20px; }
	.oxInfoWrap li	{ font-size:17px; }
	.oxInfoWrap li ul	{ padding:5px 0 15px 20px;}

	.confirmData:after	{ content:""; clear:both; display:block;}
	.confirmData dl	{ float:left; width:363px; height:360px; margin-right:21px; margin-bottom:0; padding:35px 40px; }
	.confirmData dl.farm	{ width:362px;}
	.confirmData dl.data dt	{ letter-spacing:-2px}

	/* 계획교배 */
	.proBest 	{ display:inline-block; width:32%; margin-bottom:0; margin-left:1.5%; vertical-align:top }
	.proBest:first-child	{ margin-left:0}
	.proBest ul	{ min-height:200px; box-sizing:border-box; }
	.proBest li	{ width:25%}

	/* 구입 연락처 */
	.contactus dl	{ width:23% }


	/* 씨수소자료해설 */
	.breedingox ul	{ margin:0 0 60px 20px}
	.breedingox dl	{ margin:0 0 30px 20px;}

	.ox_get dl	{ margin:0 0 30px 20px; }
	.ox_get dl dd	{ padding-left:15px}
	.ox_get .aftercr	{ margin-left:0}
	.ox_get .aftercr li	{ float:left; margin-bottom:0;}
	.ox_get .aftercr li.w20p	{ width:20%}
	.ox_get .aftercr li.w40p	{ width:40%}
	.ox_get .aftercr li.w80p	{ width:80%}
	.ox_get dl dd .listStyle4	{ margin-left:0}

	/* 젖소검정성적표보는법 */
	.tablewrap	{ display:flex; }
	.tablewrap .imgTable	{ float:left; margin-right:50px; margin-bottom:0; padding:40px; }
	.tablewrap .infoTable	{ float:left}
	.tablewrap .infoTable .listStyle01.fl.w50p	{ float:left; width:50%}
	.tablewrap .infoTable div.tr	{ text-align:right}
	.tablewrap img	{ max-width:none}

	/* 관련사이트 */
	.relSite li	{ width:270px; margin:0 0 30px 17px; }
	.relSite li:nth-child(2n+1)	{ margin-left:16px}
	.relSite li:nth-child(4n+1)	{ margin-left:0}

	/* 청정육종농가 */
	.albumWrap .btn_minihome { right:0;}
	.albumWrap .table	{ float:left; width:600px;}
	.album_photo	{ float:right; width:440px; margin-top:0}
	.album_state dl	{ float:left; width:50%; box-sizing:border-box; }
	.album_state dl:last-child 	{ margin-top:0; padding-left:80px; border-left:1px solid #e1e1e1}
	.album_state dd	{ width:170px; height:170px; margin:0 25px; padding:40px 0 0 0; }

	/* 사이트맵 */
	.sitemap>li	{ width:21.7%; margin:0 4% 50px 0; }
	.sitemap>li:nth-child(2n)	{ margin-right:4%}
	.sitemap>li:nth-child(4n)	{ margin-right:0}
	.sitemap h2	{ margin-bottom:10px; padding-left:0; color:#fff; font-size:18px; background:#4a75bb}

	/* 낙농용어사전 */
	.col-md-3	{ float:left; width:20%; margin-bottom:0}
	.easy-tree	{ height:700px; max-height:700px; }
	.easy-tree li > span	{ width:auto}
	.dic_con	{ float:right; width:78%; min-height:764px; }


}

/*****************************************************************************************************************************************************************************/
/* 전문가 */
/* .expert .expTb	{ margin-bottom:20px; padding:20px; border:1px solid #ccc; border-radius:10px; background:#f8f8f8}
.expert .expTb:after	{ content:""; clear:both; display:block;}
.expert input{width: 140px; height:auto; margin-right: 20px;}
.expert .thumb	{ float:left; width:25%; margin-right:5%}
.expert .thumb img	{ width:100%;}
.expert .thumb .btn	{ width:100%; margin-top:8px; padding:2px 0; border-radius:5px; font-size:14px; text-align:center; background:#4a75bb}
.expert .expInfo	{ float:left; width:70%}
.expert .expInfo dl	{ margin-bottom:3px}
.expert .expInfo dl:after	{ content:""; clear:both; display:block;}
.expert .expInfo dt:after	{ content:":"; clear:both; display:block; position:absolute; top:0; right:8px}
.expert .expInfo dd	{ float:left; width:75%} */

.board_info	{ margin-bottom:30px; padding:30px 0; border:1px solid #d4d4d4; border-bottom-color:#949494; background:#f4f4f4}
.board_info .ico_info	{ display:none}
.board_info .text_info	{ padding:0 30px; }
.board_info .text_info strong	{ color:#34495e}
.board_info .text_info a	{ display:inline-block; margin-top:20px; padding:4px 10px; border:1px solid #d4d4d4; border-radius:3px; font-size:12px; font-weight:600; line-height:24px; background:#fff}

.searchWrap	{ margin-bottom:20px; padding:16px 20px; border:1px solid #d4d4d4; text-align:center}
.searchWrap:after	{ content:""; clear:both; display:block}
.searchWrap .fl,
.searchWrap .fr	{ float:none}
.searchWrap .total	{ font-weight:600; font-size:14px; line-height:36px; }
.searchWrap .total span	{ color:#4a75bb}
.searchWrap .select	{ width:20%; color:#666}
.searchWrap .text	{ width:50%;}

/* 페이징 */
.pagination	{ margin-top:20px; text-align:center}
.pagination a, .pagination strong	{ display:inline-block; min-width:30px; height:30px; padding:0 5px; border:1px solid #d2d2d2; box-sizing:border-box; color:#666; line-height:28px;  vertical-align:top; text-decoration: none;  }
.pagination a:hover,
.pagination a:focus,
.pagination strong{ color:#fff; border:1px solid #34495e; background:#34495e;}

.post_list table	{ display:block; border-top:1px solid #ccc}
.post_list thead	{ display:none}
.post_list tbody,
.post_list tr,
.post_list td	{ display:block; }
.post_list tr	{ padding:10px 0; border-bottom:1px solid #ddd}
.post_list td.num,
.post_list td.hit,
.post_list td.file	{ display:none}
.post_list td.name,
.post_list td.date	{ display:inline-block; position:relative; color:#888; font-size:14px; }
.post_list td.date	{ position:relative; margin-left:6px; padding-left:10px;}
.post_list td.date:after	{ content:""; display:block; position:absolute; top:50%; left:0; width:1px; height:12px; margin-top:-6px; background:#aaa}
.post_list td.title a	{ display:block; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.post_list td.title .link	{ margin:4px 0 0 5px}

.post_list2 table	{ display:table; border-top:1px solid #ccc}
.post_list2 thead	{ display:table-header-group}
.post_list2 tbody	{ display:table-row-group}
.post_list2 tr	{ display:table-row; padding:0; border-bottom:0}
.post_list2 th,
.post_list2 td	{ display:table-cell}

.post_list2 thead th{height: 50px; background-color: #d2d7dc;}
.post_list2 td{height:50px; border-bottom: 1px dashed;}
.post_list2 .num,
.post_list2 .hit,
.post_list2 .file	{ width:8%}
.post_list2 .name,
.post_list2 .date	{ width:50%}
.post_list2 td .num,
.post_list2 td .hit,
.post_list2 td .file,
.post_list2 td .name,
.post_list2 td .date	{ display:inline-block; text-align: left;}
.post_list2 td .title	{ padding-left:0px !important; text-align: left; border-bottom:0px; font-size:19px; font-weight:bold; display:block; margin-bottom:10px;}
.post_list2 td .title .new	{ margin:2px 5px 0 0}
.post_list2 td .name,
.post_list2 td .date	{ color:#666;}
.post_list2 td .date	{ margin-left:0; padding-left:0}
.post_list2 td .date:after	{ display:none}
.post_list2 td.file .ppt	{  background-position: 0 0}
.post_list2 td.file .doc	{  background-position: 0 -39px}
.post_list2 td.file .hwp	{  background-position: 0 -79px}
.post_list2 td.file .pdf	{  background-position: 0 -120px}
.post_list2 td.file .img	{  background-position: 0 -159px}
.post_list2 td.file .xlsx	{  background-position: 0 -200px}
.post_list2 td.file .down	{  background-position: 0 -240px}

.tablestyle23 {
	width: 100%;
}
.tablestyle23,
.tablestyle23 tbody,
.tablestyle23 tr	{ display:block;}
.tablestyle23 th{ display:inline-block; padding:0 10px; text-align:left;}
.tablestyle23 th.title	{ display:none}

.tablestyle23 td	{ display:inline-block; }
.tablestyle23 td.title	{ width:100%; margin-bottom:5px; padding:14px 10px; border-top:1px solid #9EA1A5; border-bottom:1px solid #9EA1A5; font-size:16px; font-weight:600; background:#d2d7dc}
.tablestyle23 td.date	{ margin-right:20px}
.tablestyle23 td#bdcbody,
.tablestyle23 td.bdcbody	{ width:100%; margin-top:5px; border-top:1px solid #9EA1A5; border-bottom:1px solid #9EA1A5; padding:20px 10px; }
.tablestyle23 td img	{ width:100%; max-width:600px}
.tablestyle23 td.file	{ padding:0 0 0 10px}
.tablestyle23 td.file .file_down	{ padding:2px 20px 2px 0; line-height:25px; word-break:break-all}
.tablestyle23 td.file .ppt	{  background-position: 0 -1px}
.tablestyle23 td.file .doc	{  background-position: 0 -40px}
.tablestyle23 td.file .hwp	{  background-position: 0 -80px}
.tablestyle23 td.file .pdf	{  background-position: 0 -120px}
.tablestyle23 td.file .img	{  background-position: 0 -160px}
.tablestyle23 td.file .xlsx	{  background-position: 0 -201px}
.tablestyle23 td.file .down	{  background-position: 0 -241px}
.tablestyle23back{text-align:center; width:100%; margin:10px 0 20px 0; padding:10px; border:1px solid #9EA1A5; transition:all 0.3s; -webkit-transition:all 0.3s; -moz-transition:all 0.3s}
.tablestyle23back:hover{background:#273143; color:#fff; cursor:pointer}
.tablestyle23 .prev,
.tablestyle23 .next	{ border:1px solid #9EA1A5; padding:8px 10px; }
.tablestyle23 .next	{ margin-top:-1px}


/* 간행물 */
.report_list:after	{ content:""; clear:both; display:block;}
.report_list>div	{ float:left; width:48%; margin:0 0 4% 4%; padding:20px; border:1px solid #d4d4d4; }
.report_list>div:nth-child(2n+1)	{ margin-left:0}
.report_list>div h2	{ position:relative; margin-bottom:20px; padding:0 0 10px 0; color:#4a75bb; font-size:16px; font-weight:600; line-height:22px; word-break:keep-all; background:none }
.report_list>div h2:after	{ content:""; clear:both; display:block; position:absolute; bottom:0; left:0; width:30px; height:2px; background:#666}
.report_list>div li a	{ display:inline-block; width:100%; height:34px; margin-top:10px; padding:0 10px; border:1px solid #d4d4d4; font-size:14px; font-weight:600; line-height:32px; }
.report_list>div li span	{ text-align:right; width:17px; height:32px; display:inline-block;}
.report_list>div li a:hover	{ color:#fff; text-decoration:none; background:#34495e}
.report_list>div li.btnPdf a:hover span	{ background-position:-233px 5px}
.report_list>div li.btnEbook a:hover span	{ background-position:-275px 5px}
.report_list>div .thumb	{ float:right; width:100%; border:1px solid #d8d8d8; text-align:center;}
.report_list>div .thumb img	{ max-width:100%}

.cowTestList{width:90%; text-align:center; padding-left:10%;}
.cowTestList td{padding-top: 10px;padding-bottom: 10px; color:#4A75BB;}
.cowTestList th{padding-right:20px; border-right:1px solid #949494; font-size: 30pt; font-family: 'Nanum Square'; color: #273143; width:222px; text-align:center;}
.viewPdf a{display:block; padding:5px;}
.viewPdf a:hover{ text-decoration:none;}
.viewPdf{border:1px solid #D4D4D4; width:150px;}
.viewPdf:hover{background:#273143; color:#fff;}

#addbtn{padding-left:20px; padding-right:20px; background:#fff;}
#addbtn:hover{background: #273143; color:#fff;}

.postview th{border: 1px solid; height: 50px; background: #d2d7dc;}
.postview td{border:1px solid; padding-left: 20px;}
.postview td:nth-child(1), .postview td:nth-child(3){width:15%;}
.postview td:nth-child(2){width:40%;}
.postview td:nth-child(3){width:30%;}

#post_contents{padding: 30px; border-bottom: 3px solid;}
#post_btn{padding-top: 30px; padding-bottom: 100px;}
#post_btn input{margin-right:10px;padding-left:20px; padding-right: 20px; background: white;}
#post_btn input:hover{background:#273143; color:#fff;}
#post_btn input:nth-child(2){margin-right: 0px;}

#expert_write tr{border-bottom : 1px solid; height:50px;}
#expert_write tr:nth-last-child(1){border-bottom: 0px;}
#expert_write th:nth-child(1){width:200px; color:white; background: #273143;}
#expert_write input[type="text"], #expert_write input[type="button"]{margin-left:20px; height:30px;}
#expert_write input[type="textarea"]{margin-left: 20px; width:90%;height:400px; margin-top: 10px; margin-bottom: 10px;}
#expert_write input[type="text"]{width:95%;}
#expert_write input[type="password"]{margin-left: 15px; height:20px;}

/* .expert_btn{float:right; margin:10px;padding-left:20px;padding-right: 20px; background: #fff;height:40px;}
.expert_btn:hover{background: #273143; color: #fff;} */

#qna_write tr{border-bottom : 1px solid; height:50px;}
#qna_write tr:nth-last-child(1){border-bottom: 0px;}
#qna_write th:nth-child(1){width:200px; color:white; background: #273143;}
#qna_write input[type="text"], #qna_write input[type="button"]{margin-left:20px; height:30px;}
#qna_write input[type="textarea"]{margin-left: 20px; width:90%;height:400px; margin-top: 10px; margin-bottom: 10px;}
#qna_write input[type="text"]{width:95%;}
#qna_write input[type="password"]{margin-left: 15px; height:20px;}

@media (max-width:480px){
	/* .expert .thumb	{ width:100%; margin-right:0;}
	.expert .thumb img	{ display:none}
	.expert .thumb .btn	{ margin-top:0; margin-bottom:10px}
	.expert .expInfo	{ width:100%} */
	.board_info .text_info	{ padding:0 20px; }
	.searchWrap .btnSearch	{ margin-left:0}

}

@media (max-width:590px){
	/* .expert .expInfo dt	{ width:30%; }
	.expert .expInfo dd	{ width:70%} */
}

@media (min-width:769px){
	/* .expert .expTb	{ float:left; width:48.5%; margin-bottom:30px; }
	.expert .expTb:nth-of-type(2n+1)	{ margin-right:3%}
	.expert input{width: 140px; height:auto; margin-right: 20px;}
	.expert .thumb	{ width:140px; margin-right:20px}
	.expert .expInfo	{ width:68%;}
	.expert .expInfo dt	{ width:25%} */

	.searchWrap	{ padding:16px 30px; text-align:left}
	.searchWrap .fl	{ float:left}
	.searchWrap .fr	{ float:right}
	.searchWrap .select	{ width:100px}
	.searchWrap .text	{ width:235px}
	.searchWrap .btnSearch	{ width:47px;}

	.board_info:after	{ content:""; clear:both; display:block}
	.board_info .ico_info	{ float:left; display:block; padding:0 50px; border-right:1px solid #dbdbdb}
	.board_info .text_info	{ float:left; width:927px; padding:0 50px; word-break:keep-all}

	.post_list table	{ display:table; border-top:0}
	.post_list thead	{ display:table-header-group}
	.post_list tbody	{ display:table-row-group}
	.post_list tr	{ display:table-row; padding:0; border-bottom:0}
	.post_list th,
	.post_list td	{ display:table-cell}

	.post_list thead th{height: 50px; background-color: #4e995a; color: #fff;}
	.post_list td{text-align: center; height:50px; border-bottom: 1px dashed;}
	.post_list .num,
	.post_list .hit,
	.post_list .file	{ width:8%}
	.post_list .name,
	.post_list .date	{ width:12%}
	.post_list .title	{ width:52%}
	.post_list td.num,
	.post_list td.hit,
	.post_list td.file,
	.post_list td.name,
	.post_list td.date	{ display:table-cell; }
	.post_list td.title	{ padding-left:20px; text-align: left; }
	.post_list td.title .new	{ margin:2px 5px 0 0}
	.post_list td.name,
	.post_list td.date	{ color:#666; font-size:16px}
	.post_list td.date	{ margin-left:0; padding-left:0}
	.post_list td.date:after	{ display:none}

	.post_list td.file .ppt	{  background-position: 0 0}
	.post_list td.file .doc	{  background-position: 0 -39px}
	.post_list td.file .hwp	{  background-position: 0 -79px}
	.post_list td.file .pdf	{  background-position: 0 -120px}
	.post_list td.file .img	{  background-position: 0 -159px}
	.post_list td.file .xlsx	{  background-position: 0 -200px}
	.post_list td.file .down	{  background-position: 0 -240px}

	.post_list2 .date	{ width:35%}

	.report_list>div h2	{ position:relative; float:left; width:122px; margin-bottom:30px; padding:25px 0 10px 0; color:#4a75bb; font-size:16px; font-weight:600; line-height:22px; word-break:keep-all; background:none }
	.report_list>div .thumb		{ float:right; width:176px; height:247px; border:1px solid #d8d8d8}
	.report_list>div	{ float:left; width:360px; height:289px; margin:0 0 25px 25px; padding:20px; border:1px solid #d4d4d4; }
	.report_list>div:nth-child(2n+1)	{ margin-left:25px}
	.report_list>div:nth-child(3n+1)	{ margin-left:0}
	.report_list>div li a	{ width:122px; }

	#expert_write input[type="text"]{width:95%;}
	#expert_write input[type="textarea"]{margin-left: 20px; width:95%;height:400px; margin-top: 10px; margin-bottom: 10px;}
	#qna_write input[type="text"]{width:95%;}
	#qna_write input[type="textarea"]{margin-left: 20px; width:95%;height:400px; margin-top: 10px; margin-bottom: 10px;}

	.tablestyle23	{ display:table;}
	.tablestyle23 thead	{ display:table-header-group}
	.tablestyle23 tr	{ display:table-row; }
	.tablestyle23 th,
	.tablestyle23 td	{ display:table-cell}
	.tablestyle23 th.title	{ display:table-cell}
	.tablestyle23 th{width:10%; padding:14px 0; color: #fff; background-color: #4e995a; border:1px solid #9EA1A5; text-align:center}
	.tablestyle23 td.title,
	.tablestyle23 td.date {width:70%; margin-bottom:0; padding:14px 0 14px 20px; border:1px solid #9EA1A5; font-weight:400; background:none}
	.tablestyle23 td.writer,
	.tablestyle23 td.hit	{text-align:center; border:1px solid #9EA1A5;}
	.tablestyle23 td#bdcbody,
	.tablestyle23 td.bdcbody	{ margin-top:0; border:1px solid #9EA1A5; padding:30px;}
	.tablestyle23 td img	{ width:100%; max-width:600px}
	.tablestyle23 td.file	{ padding:5px 20px; border:1px solid #9EA1A5;}
	.tablestyle23 td.file .file_down	{ padding:2px 20px 2px 0; line-height:25px;}
	.tablestyle23 td.file .ppt	{  background-position: 0 -1px}
	.tablestyle23 td.file .doc	{  background-position: 0 -40px}
	.tablestyle23 td.file .hwp	{  background-position: 0 -80px}
	.tablestyle23 td.file .pdf	{  background-position: 0 -120px}
	.tablestyle23 td.file .img	{  background-position: 0 -160px}
	.tablestyle23 td.file .xlsx	{  background-position: 0 -201px}
	.tablestyle23 td.file .down	{  background-position: 0 -241px}
	.tablestyle23back{ margin:0; text-align:center; height:50px; padding:0px !important; transition:all 0.3s; -webkit-transition:all 0.3s; -moz-transition:all 0.3s}
	.tablestyle23back:hover{background:#273143; color:#fff; cursor:pointer}
	.tablestyle23 .prev td,
	.tablestyle23 .next td	{ padding-left:20px;}

}

input[type="button"], input[type="submit"]{cursor: pointer; height:40px; appearance:0;}
input[value="등록"]{height:40px;padding-left: 20px;padding-right: 20px;}


/***** 정보창고 *****/
#relsite, #relsite tr, #relsite td{border: 1px solid #BABABA; text-align: center; height:50px;}
#relsite td{width:10%;}
#relsite td:hover, #relsite td:focus{background: #273143; color:#fff;}

#viewcowexam	{ border:1px solid #ccc}
#viewcowexam th,
#viewcowexam td{border: 1px solid #ccc; padding:10px; text-align: center;}
#viewcowexam thead th{background: #273143; color:#fff; height:50px;}
#viewcowexam tbody th{background: #DEE6FC}

#view_how{padding-left:20px;padding-right: 20px;}
#view_how ul{background: #DEE6FC; padding: 10px; margin: 10px; margin-top: 20px;margin-right: 15px;}

/* 정보창고 - 기술정보 */

.techinfo_view	{ text-align:center;  overflow: hidden;}
.techinfo_view .viewTitle	{ margin-bottom:20px; color:#4a75bb; font-size:26px; font-weight:600; text-align:center; }
.techinfo_view h2	{ text-align:left}
.techinfo_view *	{ word-break:keep-all}
.techinfo_view li	{ text-align:left}
.techinfo_view .listStyle01>li	{ margin-top:5px; padding-left:17px; background:url('/assets/images/sub/imgDot01.png') no-repeat 0 7px }
.techinfo_view .listStyle03>li	{ margin-top:5px; padding-left:12px; background:url('/assets/images/sub/imgDot03.gif') no-repeat 0 8px }
.techinfo_view .listStyle04	{ margin-top:10px; margin-left:10px; }
.techinfo_view .imgBox	{ display:block; border:1px solid #ccc; padding:20px; border-radius:10px; text-align:center; }
.techinfo_view .imgBox img	{ max-width:100%; }
.techinfo_view .imgBox span	{ display:inline-block; vertical-align:top}
.techinfo_view .bgBox	{ padding:30px 50px; background:#f8f8f8}
.techinfo_view .bgBox h3	{ margin-bottom:10px; color:#3aa3df; font-size:18px; }
.techinfo_view p	{ margin-bottom:15px; text-align:left}
.techinfo_view div	{ margin-bottom:30px; text-align:left}
.techinfo_view ol li	{ margin-top:5px}
.techinfo_view dl	{ margin-bottom:30px; text-align:left}
.techinfo_view dt	{ margin-bottom:5px; padding-left:17px; color:#4a75bb; font-weight:600; background:url('/assets/images/sub/imgDot01.png') no-repeat 0 7px}
.techinfo_view dd:after	{ content:""; clear:both; display:block}
.techinfo_view dd .imgBox	{ margin-bottom:20px; }
.techinfo_view .refer li	{ color:#999; font-size:14px;}
.techinfo_view .mg30f	{ margin-bottom:30px !important}
.techinfo_view .mg60f	{ margin-bottom:60px !important}
.techinfo_view .aftercr .w70p	{ width:100%;}
.techinfo_view .imgBox .mg20l	{ margin-left:0; margin-top:20px}


#tectinfoview1 tr:nth-child(1), #tectinfoview1 tr:nth-child(2){background: #273143; color:white;}
.techinfoview { overflow: hidden;}
.techinfoview h1{font-size: 25px; font-family: 'Nanum Square'; text-align: center; margin-top: 30px;height: 50px;}
.techinfoview h2{margin-top: 40px;}
.techinfoview>ul>li>ul>li>ul>li>ul>li>ul>li{padding-left: 10px;}
.techinfoview>ul>li>ul>li>ul>li>ul>li{padding-left: 20px; background-image: url('/assets/images/sub/hyphen.jpg'); background-repeat: no-repeat; background-position: 0 .4em;}
.techinfoview>ul>li>ul>li>ul>li{padding-left: 20px; background-image: url('/assets/images/sub/imgDot04.gif'); background-repeat: no-repeat; background-position: .6em 1em;}
.techinfoview>ul>li>ul>li{padding-left: 20px; background-image: url('/assets/images/sub/imgDot03.gif'); background-repeat: no-repeat; background-position: .6em 1.0em; line-height: 230%;}
.techinfoview>ul>li{padding-left: 40px; line-height: 30px; background-image: url('/assets/images/sub/imgDot01.png'); background-repeat: no-repeat; background-position: 1.5em .7em;}
.techinfoview>div>ul>li{padding-left: 40px; line-height: 30px; background-image: url('/assets/images/sub/imgDot01.png'); background-repeat: no-repeat; background-position: 1.5em .7em;}
.techinfoview>div>ul>li>ul>li{padding-left: 20px; background-image: url('/assets/images/sub/imgDot03.gif'); background-repeat: no-repeat; background-position: .6em 1.0em; line-height: 230%;}
.techinfoview>div>ul>li>ul>li>ul>li{padding-left: 20px; background-image: url('/assets/images/sub/imgDot04.gif'); background-repeat: no-repeat; background-position: .6em 1em;}
.techinfoview img{border: 1px solid #273143; padding :20px 20px 10px 20px; margin: 10px 0px 10px 10px; border-radius: 5%;}

.tablestyle	{ border:1px solid #34495e}
.tablestyle caption	{ margin-bottom:5px; color:#34495e; font-weight:600; text-align:left;}
.tablestyle th,
.tablestyle td	{ padding:5px 0; text-align: center; }
.tablestyle thead th	{ color:#fff; border-left:1px solid #ccc; border-bottom:1px solid #ccc; background:#4e995a; }
.tablestyle td	{ border-left: 1px solid #34495e; border-bottom: 1px solid #34495e;}
.tablestyle tbody th{ border-right: 1px solid #34495e; border-bottom: 1px solid #34495e; background:#ccddef}
.tablestyle .first	{ border-left:0}
.tablestyle .left	{ padding-left:10px; text-align:left;}

@media (min-width:768px){
	.techinfo_list {margin-left:30px;}
	.techinfo_view dd .imgBox	{ float:left; margin-right:40px; margin-bottom:0}
	.techinfo_view .aftercr .w70p	{ width:70%;}
	.techinfo_view .imgBox .mg20l	{ margin-top:0; margin-left:20px}
}

#tectinfoview1, #tectinfoview1 td, #tectinfoview1 tr{border: 1px solid; text-align: center; margin:20px; margin-right: 40px;}
#tablestyle1, #tablestyle1 tr, #tablestyle1 td{text-align: center; border: 1px solid;}
#tablestyle1 tr:nth-child(1), #tablestyle1 tr:nth-child(2){background:#273143; color:white;}
#tablestyle2, #tablestyle2 tr, #tablestyle2 td{text-align: center; border: 1px solid;}
#tablestyle2 tr:nth-child(1), #tablestyle2 tr:nth-child(2){background:#273143; color:white;}
#tablestyle3, #tablestyle3 tr, #tablestyle3 td{border:1px solid; text-align: center;}

@media (max-width:768px){
	.techinfo_ko {margin-left:0}
}

.tablestyle2{margin: 20px;}
.tablestyle2 th{border: 1px solid; text-align: left; padding-left: 20px; padding: 10px; background: #4e995a; color: #fff;}
.tablestyle2 td:nth-child(1){padding-left: 30px; padding: 20px; background: #FEEEE3; line-height: 200%;}
.tablestyle2 td:nth-child(2){padding-left: 30px; padding: 20px; background: #E7FEE0; width:30%;}
.tablestyle3 th{border: 1px solid; text-align: left; padding-left: 20px; padding: 10px; background: #273143; color: #fff;}
.tablestyle3 img{border: 0px solid;padding: 0px;margin: 0px;border-radius: 0%; width: 80%;}
.tablestyle3 tr:nth-child(2){margin: 0px; padding: 0px;}
.tablestyle3{margin:20px; margin-top: 50px;}
.tablestyle3 td:nth-child(4){padding-left: 30px; padding: 20px; background: #E7FEE0; width:30%;}
.tablestyle3 tr:nth-child(2) td:nth-child(1),.tablestyle3 tr:nth-child(2) td:nth-child(2),.tablestyle3 tr:nth-child(2) td:nth-child(3),.tablestyle3 tr:nth-child(3) td:nth-child(1), .tablestyle3 tr:nth-child(3) td:nth-child(2), .tablestyle3 tr:nth-child(3) td:nth-child(3), .tablestyle3 tr:nth-child(4) td:nth-child(1), .tablestyle3 tr:nth-child(4) td:nth-child(2), .tablestyle3 tr:nth-child(4) td:nth-child(3){text-align: center;}
.tablestyle3 td:nth-child(1), .tablestyle3 td:nth-child(2), .tablestyle3 td:nth-child(3){background: #EFECFF; }
.tablestyle3 tr:nth-child(5) td{padding-left: 30px; padding: 20px; background: #FEEEE3; line-height: 200%;}
#tablestyle4 td:nth-child(1){width:10%}
#tablestyle4 tr:nth-child(1) td, #tablestyle4 tr:nth-child(2) td{text-align: center;}
#tablestyle4 td:nth-child(2){text-align: left; padding: 10px; width: 70%;}
#tablestyle4 td:nth-child(3), #tablestyle4 td:nth-child(4){width:10%;}
.tablestyle5 td{height: 30px;}
.tablestyle6 tr:nth-child(1){text-align: center; background: #273143; color: #fff; height: 50px;}
.tablestyle6 tr:nth-child(2) td, .tablestyle6 tr:nth-child(4) td{text-align: center; background: #EDEDFE; border: 1px solid; width: 20%; height: 30px;}
.tablestyle6 img{border: none; padding: 0px; margin:0px;width: 100%; border-radius: 0%;}
.tablestyle7 th{background: #273143; color: white;}
.tablestyle7 th, .tablestyle7 td{border: 1px solid; padding-left: 20px; padding-right: 20px;}
.tablestyle8{width: 52%;}
.tablestyle8 td{padding-left: 20px;}
.tablestyle8 tr:nth-child(1){text-align:center;}
.tablestyle8 td:nth-child(1){background: #FFCEFF;}
.tablestyle8 td:nth-child(2){background: #CACAFF;}
.tablestyle9 tr:nth-child(1){text-align: center; background: #EFEFEF; color: black;}
.tablestyle9 td{padding-left: 15px !important; height: 30px; padding: 5px; line-height: 200%; border: 1px solid #fff;}
.tablestyle9 tr:nth-child(2), .tablestyle9 tr:nth-child(3), .tablestyle9 tr:nth-child(4){background: #C8C8C8; color: black;}
.tablestyle9 tr:nth-child(5){background: #9E9E9E; color: black;}
.tablestyle9 tr:nth-child(6), .tablestyle9 tr:nth-child(7), .tablestyle9 tr:nth-child(8){background: #4F4F4F; color: #fff;}
.tablestyle10{text-align:center; width: 80%;}
.tablestyle10 td{height: 30px;}
.tablestyle10 tr:nth-child(odd) td:nth-child(1){background: #FFCEFF;}
.tablestyle10 tr:nth-child(7) td:nth-child(2), .tablestyle10 tr:nth-child(9) td:nth-child(2), .tablestyle10 tr:nth-child(11) td:nth-child(2), .tablestyle10 tr:nth-child(13) td:nth-child(2){background: #FFCEFF; border-left: 50px solid #fff;}
.tablestyle10 tr:nth-child(odd) td:nth-last-child(1){background: #CACAFF;}
.tablestyle10 tr:nth-child(7) td:nth-last-child(2){background: #CACAFF;border-right: 30px solid #fff;}
.tablestyle10 tr:nth-child(1) td:nth-last-child(1){background: none;}
.tablestyle11{text-align: center; width: 50%;}
.tablestyle11 td:nth-child(2){width:20%;}
.tablestyle11 tr:nth-child(1) td:nth-child(1){border-bottom: 1px solid;}
.tablestyle12{text-align: center; width: 80%; padding: 20px; margin: 20px;}
.tablestyle12 tr:nth-child(1) td:nth-child(3), .tablestyle12 tr:nth-child(1) td:nth-child(5), .tablestyle12 tr:nth-child(1) td:nth-child(7){background:#E1FFFF; border-left: 10px solid #fff;border-right: 10px solid #fff; border-bottom: 5px solid #fff;}
.tablestyle12 tr:nth-child(2) td:nth-child(2){background: #9AEDDE; border-left: 10px solid #fff;border-right: 10px solid #fff; border-bottom: 5px solid #fff;}
.tablestyle12 tr:nth-child(3) td:nth-child(odd){background:#CACAFF; border-left: 10px solid #fff;border-right: 10px solid #fff; border-bottom: 5px solid #fff; width: 80px;}
.tablestyle12 tr:nth-child(4) td:nth-child(2),.tablestyle12 tr:nth-child(5) td:nth-child(2), .tablestyle12 tr:nth-child(6) td:nth-child(2){background:#FFF440; border-left: 10px solid #fff;border-right: 10px solid #fff; border-bottom: 5px solid #fff;}
.tablestyle12 tr:nth-child(3) td:nth-child(even){width: 30px;}
.tablestyle13 td{border: 1px solid; padding-left: 10px; padding-right: 10px;}
.tablestyle13 tr:nth-child(2) td{background: #273143; color: #fff; text-align: center;}
.tablestyle13 td:nth-child(3){text-align: right;}
.tablestyle14{text-align: center; width: 50%}
.tablestyle14 td:nth-child(2){border-bottom: 1px solid;}
.tablestyle15{text-align: center; width: 60%}
.tablestyle15 td:nth-child(3){border-bottom: 1px solid;}
.tablestyle15 tr:nth-child(3) td:nth-child(4){text-align: left;}
.tablestyle16 td{border: 1px solid; text-align: center;}
.tablestyle17 td{border: 1px solid; width: 10%}
.tablestyle17 td:nth-child(1), .tablestyle17 td:nth-child(2){text-align:center}
.tablestyle17 tr:nth-child(1), .tablestyle17 tr:nth-child(2){background: #273143; color: #fff; text-align: center;}
.tablestyle18{text-align: center; width: 30%}
.tablestyle18 td:nth-child(2){border-bottom: 1px solid;}

.tablestyle19	{ text-align:center;}
.tablestyle19 th,
.tablestyle19 td	{ padding:14px 0; border:1px solid #273143; font-size:11px; word-break:break-all; letter-spacing:-1px}
.tablestyle19 td a	{ margin-bottom:5px; color:#4a75bb; display:inline-block;}
.tablestyle19 td img	{ vertical-align:text-bottom; margin-left:5px}
.tablestyle19 thead th	{ background:#4e995a; color:#fff;}
.tablestyle19 thead tr:nth-child(2)	th,
.tablestyle19 tfoot td	{ background:#CCDDEF; color:#273143;}
.tablestyle19 tbody tr:hover	{ background:#FFFFC0;}

.tablestyle30	{ text-align:center;}
.tablestyle30 th,
.tablestyle30 td	{ padding:14px 0; border:1px solid #273143; font-size:11px; word-break:break-all; letter-spacing:-1px}
.tablestyle30 td a	{ margin-bottom:5px; color:#4a75bb; display:inline-block;}
.tablestyle30 td img	{ vertical-align:text-bottom; margin-left:5px}
.tablestyle30 thead th	{ background:#4e995a; color:#fff;}
.tablestyle30 tbody tr:hover	{ background:#FFFFC0;}

.oxsch_table	{ overflow-x:auto}
.oxsch_table .tablestyle19	{ min-width:400px}

.tablestyle20{text-align:center; width:30%}
.tablestyle20 tr:nth-child(1) td:nth-child(2), .tablestyle20 tr:nth-child(1) td:nth-child(4){border-bottom:1px solid;}
.tablestyle21{text-align:center; width:80%}
.tablestyle21 tr:nth-child(1) td:nth-child(2), .tablestyle21 tr:nth-child(1) td:nth-child(4), .tablestyle21 tr:nth-child(1) td:nth-child(6), .tablestyle21 tr:nth-child(3) td:nth-child(2), .tablestyle21 tr:nth-child(3) td:nth-child(4){border-bottom:1px solid;}
.tablestyle22{text-align:center; width:50%}
.tablestyle22 tr:nth-child(1) td:nth-child(2), .tablestyle22 tr:nth-child(1) td:nth-child(4), .tablestyle22 tr:nth-child(3) td:nth-child(2), .tablestyle22 tr:nth-child(3) td:nth-child(4), .tablestyle22 tr:nth-child(5) td:nth-child(2), .tablestyle22 tr:nth-child(5) td:nth-child(4){border-bottom:1px solid;}

/*정액주문*/
.tablestyle24 th{color: #e1dede; background-color: #4e995a; border:1px solid #9EA1A5; height:50px; font-size:12px; letter-spacing:-1px}
.tablestyle24 td{height:50px; text-align:center; border:1px solid #9EA1A5; word-break:break-all; letter-spacing:-1px; font-size:12px; }
.tablestyle24 tr:hover{background:#FFFFC0;}
.tablestyle24 td.orderName,
.tablestyle24 a{color:#4a75bb }

.tablestyle24 tbody .orderPrice{text-align:right; padding-right:5px;}
.orderBtn{float:right; margin-top:20px; padding: 10px 20px}
.orderBtn:hover{background:#273143; color:#fff}

.tablestyle25 th{color: #e1dede; background-color: #4e995a; border:1px solid #9EA1A5; height:40px; width:20%}
.tablestyle25 td{height:40px; padding-left:20px; border: 1px solid #9EA1A5;}
.listback{text-align:center; height:50px; margin-top:20px;}
.listback:hover{background:#273143; color:#fff; cursor:pointer;}

.tablestyle26	{ min-width:100px}
.tablestyle26 th{color: #e1dede; background-color: #4e995a; border:1px solid #9EA1A5; height:40px;}
.tablestyle26 td{height:40px; border:1px solid #9EA1A5; text-align:right; padding-right:10px;}
.tablestyle26 .code, .tablestyle26 .name, .tablestyle26 .fname, .tablestyle26 .str, .tablestyle26 .price, .tablestyle26 .all, .tablestyle26 .strsum{text-align:center; padding:0;}
.tablestyle26 .str2{background:#FFFFE0; text-align:center; padding:0px;}
.tablestyle26 .price2{background:#FFFFE0;}
.tablestyle26 tr:hover{background:#FFFFC0;}
.tablestyle26 tr:last-child{background:#fff;}
.tablestyle26 a{color:#4a75bb }
.tablestyle26 input[type=text]{height:25px;text-align:right;}
.tablestyle27	{ min-width:600px}
.tablestyle27 th{color: #e1dede; background-color: #4e995a; border:1px solid #9EA1A5; height:40px; }
.tablestyle27 td{height:40px; padding:0 15px; border:1px solid #9EA1A5;}
.tablestyle27 .jmemo{height:100px !important; }
.tablestyle27 input {height:25px; box-sizing:border-box }
.tablestyle27 textarea	{ box-sizing:border-box}
.tablestyle27 .orderBtn2, .tablestyle27 .backBtn{text-align:center; padding:0px; height:60px;}
.tablestyle27 .orderBtn2:hover, .tablestyle27 .backBtn:hover{background:#273143; color:#fff; cursor:pointer}


.tablestyle28 th{color: #e1dede; background-color: #4e995a; }
.tablestyle28	{ border-top:2px solid #34495e; text-align:center;}
.tablestyle28 th, .tablestyle28 td	{ padding:5px; border-bottom:1px solid #e1e1e1; border-left:1px solid #e1e1e1; word-break:break-all; font-size:11px; letter-spacing:-1px}
.tablestyle28 th	{ color:#000; font-weight:normal; background:#f7f7f7}
.tablestyle28 .first	{ border-left:0; }

.tablestyle29 .tableth,
.tablestyle29 .tabletd	{ display:block;}
.tablestyle29	{ border-bottom:1px solid #ccc}
.tablestyle29 .tableth{color: #e1dede; background-color: #4e995a; width:100%; padding:5px 10px; text-align:left}
.tablestyle29 .tabletd{ width:100%; padding:3px 0 10px 0;}
.tablestyle29 input[type="text"], .tablestyle29 input[type="password"]{height:40px;}
.tablestyle29 .wname, .tablestyle29 .wmail, .tablestyle29 .title, .tablestyle29 .pwd, .tablestyle29 .pwd2{width:100%}
.tablestyle29 .prevent{width:56%}
.tablestyle29 .file	{ width:100%}
.qnainsertBtn, .qnalistBack{float:right; margin-top:20px; padding: 10px 20px; margin-left:20px;}
.qnainsertBtn:hover, .qnalistBack:hover{background:#273143; color:#fff}

.tablestyle30	{ text-align:center;}
.tablestyle30 th,
.tablestyle30 td	{ padding:14px 0; border:1px solid #273143; font-size:11px; word-break:break-all; letter-spacing:-1px}
.tablestyle30 td a	{ margin-bottom:5px; color:#4a75bb; display:inline-block;}
.tablestyle30 td img	{ vertical-align:text-bottom; margin-left:5px}
.tablestyle30 thead th	{color: #e1dede; background-color: #4e995a; }
.tablestyle30 tbody tr:hover	{ background:#FFFFC0;}

.tablestyle270	{ min-width:300px}
.tablestyle270 th{color: #e1dede; background-color: #4e995a; border:1px solid #9EA1A5; height:40px; }
.tablestyle270 td{height:40px; padding:0 15px; border:1px solid #9EA1A5;}
.tablestyle270 .jmemo{height:40px !important; }
.tablestyle270 input {height:25px; box-sizing:border-box }
.tablestyle270 select {height:25px; box-sizing:border-box }
.tablestyle270 textarea	{ box-sizing:border-box}
.tablestyle270 .orderBtn2, .tablestyle270 .backBtn{text-align:center; padding:0px; height:60px;}
.tablestyle270 .orderBtn2:hover, .tablestyle270 .backBtn:hover{background:#273143; color:#fff; cursor:pointer}

.edBtn, .deBtn, .reBtn{float:right; margin-left:20px; padding: 10px 20px;}
.edBtn:hover, .deBtn:hover, .reBtn:hover{background:#273143; color:#fff}

.mkaddBtn{float:right;}
.mkaddBtn:hover{background:#273143; color:#fff;}

.pwdinput{height:30px; width:300px; float:left;}
.pwdBtn{padding:7px 20px; margin-left: 10px;}

span.sort_title:hover{
	text-decoration:underline; cursor: pointer;
}

.pstyle{line-height: 200%;}

.nonborder{border: 0px !important; padding: 0px!important; border-radius: 0% !important; margin: 5px !important;}
.nonborder2{border: 0px !important; padding: 0px!important; border-radius: 0% !important; margin: 5px !important; width: 70%; margin-left: 15% !important; margin-right: 15% !important;}

.leftpart{width: 65%; float: left;}
.rightpart{width: 35%; float: left;}
.rightpart p{text-align: center;}
.rightpart img{width: 100%;}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content { position:absolute; top:50%; left:50%; background-color: #fefefe; margin: -95px 0 0 -45%; padding: 50px 30px; border: 1px solid #888; width:90%; box-sizing:border-box; text-align:center; }
.modal-content p	{ margin-bottom:10px; word-break:keep-all}
.close { position:absolute; top:20px; right:20px; color: #aaaaaa; font-size: 28px;font-weight: bold;}
.close:hover,
.close:focus {color: #000;text-decoration: none;cursor: pointer; }
.conpw{height:38px; width:50%; margin-right:5px}
.confirmBtn{ border:0; background:#273143; color:#fff; padding : 0 10px; appearance:0;}
.confirmBtn:hover{cursor:pointer}


.refreshBtn{width:20%}
.conBtn{padding : 0 10px; margin : 0 20px;}
.captchaimg	{ display:inline-block; height:40px; width:21%; border: 1px solid black; padding:8px 0; vertical-align:top; text-align:center}


@media (min-width:768px){
	.tablestyle19 th,
	.tablestyle19 td	{ font-size:16px; letter-spacing:0}

	.tablestyle30 th,
	.tablestyle30 td	{ font-size:16px; letter-spacing:0}

	.tablestyle24 th,
	.tablestyle24 td	{ font-size:16px; letter-spacing:0}

	.tablestyle28 th, .tablestyle28 td	{ font-size:16px; letter-spacing:0}

	/* 게시판 글쓰기 */
	.tablestyle29	{ display:table; width:100%; border-bottom:0; border-collapse:collapse}
	.tablestyle29>div	{ display:table-row; padding:0; border-bottom:0}
	.tablestyle29 .tableth,
	.tablestyle29 .tabletd	{ display:table-cell}
	.tablestyle29 .tableth{background:#4e995a; color:#fff; width:15%; border:1px solid #9EA1A5; text-align:center; vertical-align:middle}
	.tablestyle29 .tabletd{  border:1px solid #9EA1A5; padding:5px 20px; vertical-align:middle}
	.tablestyle29 .tabletd.contents	{ padding:0}
	.tablestyle29 input[type="text"], .tablestyle29 input[type="password"]{height:40px;}
	.tablestyle29 .title	{ width:100%}
	.tablestyle29 .wname, .tablestyle29 .wmail	{width:50%}
	.tablestyle29 .pwd, .tablestyle29 .pwd2{width:25%}
	.tablestyle29 .prevent{width:32%}
	.tablestyle29 .file	{ width:100%}
	.qnainsertBtn, .qnalistBack{float:right; margin-top:20px; padding: 10px 20px; margin-left:20px;}
	.qnainsertBtn:hover, .qnalistBack:hover{background:#273143; color:#fff}

	.modal-content { margin-left:-228px; width:456px;  }
	.conpw{ width:200px; }
	.captchaimg	{ width:84px}
	.refreshBtn	{ width:auto; padding:0 10px;}


}


/*2021년6월팝업*/
.poptit {width:100%;background:#3aa3df;border:1px solid #3298d3;margin-bottom:30px;height:60px;position:relative;padding:20px 20px;}
.poptit span {font-family:"Nanum Square";font-weight:600;font-size:24px;color:#fff;}
.popsubtit {width:100%;background:#ededed;border:1px solid #f7f7f7;margin-bottom:15px;height:40px;position:relative;padding:15px 15px;text-align:left;}
.popsubtit span {font-family:"Nanum Square";font-weight:600;font-size:18px;color:#666666;}



div.chartbox1 {float:left;border:1px solid #ddd;margin-bottom:20px!important;}
div.chartbox2 {float:left;border:1px solid #ddd;margin-bottom:20px!important;}
div.chartbot2:after {content:""; clear:both; display:block;}

table.pop {border-top:2px solid #34495e;border-right:1px solid #e1e1e1;}
table.pop th {background:#f7f7f7; color:black;text-align:center;padding:9px 5px 8px;border-bottom:1px solid #e1e1e1;
	border-left:1px solid #e1e1e1; word-break:break-all;
	font-size:13px; letter-spacing:1px;
	}
table.pop td {
	padding:8px 5px 8px;text-align:center;border-bottom:1px solid #e1e1e1; border-left:1px solid #e1e1e1; word-break:break-all;
	font-size:13px; letter-spacing:1px;
	}

	/* 패딩 5,10단위만 지정함*/
.pad0l	{ padding-left:0 !important}
.pad5a {padding:5px}
.pad5tf {padding-top:5px;padding-bottom:5px}
.pad5lr {padding-left:5px;padding-right:5px;}
.pad5r {padding-right:5px}
.pad5l {padding-left:5px}
.pad5t {padding-top:5px}
.pad5f {padding-bottom:5px}
.pad10a {padding:10px}
.pad10tf {padding-top:10px;padding-bottom:10px}
.pad10lr {padding-left:10px;padding-right:10px;}
.pad10r {padding-right:10px}
.pad10l {padding-left:10px}
.pad10t {padding-top:10px}
.pad10f {padding-bottom:10px}
.pad15a {padding:15px}
.pad15tf {padding-top:15px;padding-bottom:15px}
.pad15lr {padding-left:15px;padding-right:15px;}
.pad15r {padding-right:15px}
.pad15l {padding-left:15px}
.pad15t {padding-top:15px}
.pad15f {padding-bottom:15px}
.pad20a {padding:20px}
.pad20tf {padding-top:20px;padding-bottom:20px}
.pad20lr {padding-left:20px;padding-right:20px;}
.pad20r {padding-right:20px}
.pad20l {padding-left:20px}
.pad20t {padding-top:20px}
.pad20f {padding-bottom:20px}
.pad25a {padding:25px}
.pad25tf {padding-top:25px;padding-bottom:25px}
.pad25lr {padding-left:25px;padding-right:25px;}
.pad25r {padding-right:25px}
.pad25l {padding-left:25px}
.pad25t {padding-top:25px}
.pad25f {padding-bottom:25px}
.pad30a {padding:30px}
.pad30tf {padding-top:30px;padding-bottom:30px}
.pad30lr {padding-left:30px;padding-right:30px;}
.pad30r {padding-right:30px}
.pad30l {padding-left:30px}
.pad30t {padding-top:30px}
.pad30f {padding-bottom:30px}
.pad40a {padding:40px}
.pad40tf {padding-top:40px;padding-bottom:40px}
.pad40lr {padding-left:40px;padding-right:40px;}
.pad40r {padding-right:40px}
.pad40l {padding-left:40px}
.pad40t {padding-top:40px}
.pad40f {padding-bottom:40px}
.pad50a {padding:50px}
.pad50tf {padding-top:50px;padding-bottom:50px}
.pad50lr {padding-left:50px;padding-right:50px;}
.pad50r {padding-right:50px}
.pad50l {padding-left:50px}
.pad50t {padding-top:50px}
.pad50f {padding-bottom:50px}

/*마진*/
.mg0f {margin-bottom:0 !important}
.mg5f {margin-bottom:5px;}
.mg10f {margin-bottom:10px;}
.mg15f {margin-bottom:15px;}
.mg20f {margin-bottom:20px;}
.mg25f {margin-bottom:25px;}
.mg30f {margin-bottom:30px;}
.mg40f {margin-bottom:40px;}
.mg50f {margin-bottom:50px;}
.mg60f {margin-bottom:60px;}
.mg0t {margin-top:0 !important}
.mg5t {margin-top:5px;}
.mg10t {margin-top:10px;}
.mg15t {margin-top:15px;}
.mg20t {margin-top:20px;}
.mg25t {margin-top:25px;}
.mg30t {margin-top:30px;}
.mg40t {margin-top:40px;}
.mg50t {margin-top:50px;}
.mg60t {margin-top:60px;}
.mg0l {margin-left:0 !important}
.mg5l {margin-left:5px;}
.mg10l {margin-left:10px;}
.mg15l {margin-left:15px;}
.mg20l {margin-left:20px;}
.mg25l {margin-left:25px;}
.mg30l {margin-left:30px;}
.mg40l {margin-left:40px;}
.mg50l {margin-left:50px;}
.mg60l {margin-left:60px;}
.mg5r {margin-right:5px;}
.mg10r {margin-right:10px;}
.mg15r {margin-right:15px;}
.mg20r {margin-right:20px;}
.mg25r {margin-right:25px;}
.mg30r {margin-right:30px;}
.mg40r {margin-right:40px;}
.mg50r {margin-right:50px;}
.mg60r {margin-right:60px;}
.mg5a {margin:5px;}
.mg10a {margin:10px;}
.mg15a {margin:15px;}
.mg20a {margin:20px;}
.mg25a {margin:25px;}
.mg30a {margin:30px;}
.mg20tf {margin-top:20px;margin-bottom:20px}
.mg50tf {margin-top:50px;margin-bottom:50px}
.mg50lr {margin-left:50px;margin-right:50px;}
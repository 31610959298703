.newAnal01Pdf {
    position: relative;
    max-width: 1440px;
    min-width: 793px;
    min-height: 1122px;
    margin: 0 auto;
    padding: 40px 30px 80px;
    background-color: var(--white_ff);
  
    h1 {
      text-align: center;
      font-size: var(--fs_28);
      font-weight: var(--fw_normal);
      margin-bottom: 20px;
    }
    .newAnal01Info {
      display: flex;
      gap: 12px;
      border-top: solid 1px var(--main_blue);
      border-bottom: solid 1px var(--main_blue);
      padding: 10px 20px;
      background-color: rgba(52, 73, 94, 0.1);
    }

    .exp {
        margin-top: 20px;
        font-size : var(--fs_14);
    }
    
    .pdfTable {
      margin-top: 20px;

      thead {
        th {
            border:solid 1px var(--main_black);
          }
      }    
      tbody {
        tr {
          td {
            &:nth-child(1) {
              width: 4%;
            }
            &:nth-child(2) {
              width: 2%;
            }
            &:nth-child(3) {
              width: 4%;
            }
            &:nth-child(4) {
              width: 5%;
            }
            &:nth-child(5) {
              width: 5%;
            }
            &:nth-child(6) {
              width: 5%;
            }
            &:nth-child(7) {
              width: 5%;
            }
            &:nth-child(8) {
              width: 5%;
            }
            &:nth-child(9) {
              width: 5%;
            }
            &:nth-child(10) {
              width: 5%;
            }
            &:nth-child(11) {
              width: 5%;
            }
            &:nth-child(12) {
              width: 5%;
            }
            &:nth-child(13) {
              width: 5%;
            }
            &:nth-child(14) {
              width: 5%;
            }
            &:nth-child(15) {
              width: 5%;
            }
            &:nth-child(16) {
              width: 5%;
            }
            &:nth-child(17) {
              width: 5%;
            }
            &:nth-child(18) {
              width: 5%;
            }
            &:nth-child(19) {
              width: 5%;
            }
          }
        }
      }
    }
  }
@import '/src/styles/media.scss';

.generalHistory {

  .subTitle {
    @include desktop {
      margin-top: 100px;
      transform: translateY(150%);
    }
  }
  .generalTitle {
    @include desktop {
      width: 1080px;
      margin-left: auto;
    }

    dt {
      font-weight: var(--fw_bold);
      margin-bottom: 24px;
      @include tablet {
        font-size: var(--fs_24);
        margin-bottom: 30px;
      }
      @include desktop {
        font-size: var(--fs_40);
      }

      span {
        display: block;
        white-space: nowrap;
        @include tablet {
          display: inline;
        }
      }
    }
    dd {
      font-size: var(--fs_14);
      line-height: var(--lh_170);
      @include tablet {
        font-size: var(--fs_18);
      }
      @include desktop {
        width: 820px;
      }
    }
  }
  .generalStatus {
    display: grid;
    gap: 36px;
    text-align: center;
    margin: 32px 4px 64px 4px;
    @include tablet {
      margin: 80px 12px 120px 12px;
      grid-template-columns: repeat(2,1fr);
      row-gap: 40px;
      column-gap: 20px;
    }
    @include desktop {
      margin: 120px 12px;
      grid-template-columns: repeat(4,1fr);
      column-gap: 30px;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;
      padding: 60px 0 24px 0;
      border: solid 1px var(--sub_gray);
      border-radius: 5px;

      img {
        width: 64px;
        @include tablet {
          width: 80px;
        }
      }
      dt {
        font-size: var(--fs_16);     
        font-weight: var(--fw_medium);
        margin-bottom: 24px;
        @include tablet {
          font-size: var(--fs_20);
        }
      }
      dd {
        font-size: var(--fs_14);
        color: var(--main_gray);
        @include tablet {
          font-size: var(--fs_16);
        }

        span {
          display: block;
          font-size: var(--fs_12);
          @include tablet {
            font-size: var(--fs_16);
          }
        }
      }
    }
  }
  .history {
    position: relative;
    margin-bottom: 80px;
    .historyTitle {
      font-weight: var(--fw_bold);
      margin-bottom: 24px;
      @include tablet {
        font-size: var(--fs_24);
      }
    }
    .historyTab {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      column-gap: 8px;
      row-gap: 15px;
      @include tablet {
        column-gap: 50px;
        row-gap: 24px;
      }
      @include desktop {
        column-gap: 0;
        row-gap: 0;
        grid-template-columns: repeat(5,1fr);
      }

      button {
        width: 100%;
        font-weight: var(--fw_bold);
        font-size: var(--fs_12);
        border: solid 1px var(--main_right_gray);
        color: var(--main_right_gray);
        padding: 5px 0;
        @include tablet {
          font-size: var(--fs_18);
          padding: 15px 0;
        }

        &:hover,
        &.current {
          color: var(--main_green);
          border: solid 1px var(--main_green);
        }
      }
    }
    .historyCon {
      position: relative;
      margin-top: 40px;
      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: -10px;
        width: 1px;
        height: calc(100% - 16px); 
        background-color: var(--sub_gray);
      }
      @include tablet {
        padding-left: 35px;
        &::before {
          top: 20px;
          left: 10px;
          height: calc(100% - 30px);
        }
      }
      @include desktop {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        &::before {
          left: 50%;
        }
      }
      
      .titleYear {
        position: relative;
        font-size: var(--fs_12);
        color: var(--main_green);
        font-weight: var(--fw_bold);
        margin-bottom: 15px;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-40%);
          left: -14px;
          width: 9px;
          height: 9px;
          background: url('/assets/svgs/containers/business/history/dot.svg') no-repeat center/cover;
        }
        @include tablet {
          font-size: var(--fs_24);
          &::before {
            left: -33px;
            width: 17px;
            height: 17px;
          }
        }  
        @include desktop {
          display: none;
        }
      }
      dl {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        @include desktop {
          width: 42%;
          margin-bottom: 80px;
        }
        
        dt {
          position: relative;
          font-size: var(--fs_12);
          color: var(--main_gray);
          font-weight: var(--fw_bold);
          margin-bottom: 15px;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-40%);
            left: -14px;
            width: 9px;
            height: 9px;
            background: url('/assets/svgs/containers/business/history/dot.svg') no-repeat center/cover;
          }
          @include tablet {
            margin-bottom: 30px;
            font-size: var(--fs_24);
            &::before {
              left: -33px;
              width: 17px;
              height: 17px;
            }
          }
          @include desktop {
            margin-bottom: 40px;
            font-size: var(--fs_32);
            color: var(--main_green);
            &::before {
              left: auto;
              right: -125px;
            }
          }
        }
        dd {
          position: relative;
          font-size: var(--fs_14);
          color: var(--main_gray);
          display: flex;
          justify-content: flex-start;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            content: '';
            position: absolute;
            top: 9px;
            left: -12px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: var(--sub_gray);
          }
          @include tablet {
            margin-bottom: 15px;
            font-size: var(--fs_18);
            &::before {
              width: 9px;
              height: 9px;
              top: 10px;
              left: -29px;
            }
          }
          @include desktop {
            margin-bottom: 20px;
            padding-left: 50px;
            &::before {
              left: auto;
              right: -121px;
            }
          }
  
          span{
            position: relative;
            display: inline-block;
            width: 30px;
            font-weight: var(--fw_bold);
            @include tablet {
              width: 70px;
            }
          }
          p{
            flex: 1;
          }
        } 
      }

      @include desktop {
        &._현재2015 {
          dl {
            &:nth-of-type(2),
            &:nth-of-type(3) {
              align-self: flex-end;
            
              dt {
                &::before {
                  right: auto;
                  left: -123px;
                }      
              }
              dd {
                &::before {
                  right: auto;
                  left: -119px;
                }
              }
            }
          }
        }
        &._20142010 {
          dl {
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5) {
              align-self: flex-end;
            
              dt {
                &::before {
                  right: auto;
                  left: -123px;
                }      
              }
              dd {
                &::before {
                  right: auto;
                  left: -119px;
                }
              }
            }
          }
        }
        &._20142010 {
          dl {
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5) {
              align-self: flex-end;
            
              dt {
                &::before {
                  right: auto;
                  left: -123px;
                }      
              }
              dd {
                &::before {
                  right: auto;
                  left: -119px;
                }
              }
            }
          }
        }
        &._20092000 {
          dl {
            &:nth-of-type(6),
            &:nth-of-type(7) {
              align-self: flex-end;
            
              dt {
                &::before {
                  right: auto;
                  left: -123px;
                }      
              }
              dd {
                &::before {
                  right: auto;
                  left: -119px;
                }
              }
            }
          }
        }
        &._19991980 {
          dl {
            &:nth-of-type(2),
            &:nth-of-type(4),
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8) {
              align-self: flex-end;
            
              dt {
                &::before {
                  right: auto;
                  left: -123px;
                }      
              }
              dd {
                &::before {
                  right: auto;
                  left: -119px;
                }
              }
            }
          }
        }
        &._19701960 {
          dl {
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(9) {
              align-self: flex-end;
            
              dt {
                &::before {
                  right: auto;
                  left: -123px;
                }      
              }
              dd {
                &::before {
                  right: auto;
                  left: -119px;
                }
              }
            }
          }
        }
      }
    }
    .imgBox {
      display: none;
      @include desktop {
        display: flex;
        flex-direction: column;
        gap: 30px;
        position: absolute;
        top: 180px;
        right: 0;
      }
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        &:last-child {
          margin-top: 120px;
        }

        span {
          font-size: var(--fs_14);
          color: var(--main_gray);     
        }
      }
    }
  }
}
@import '/src/styles/media.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: background-color 0.5s;
  z-index: var(--999);
  &:hover {
    background-color: rgba(255, 255, 255, 1);
    a {
      color: #000;
    }
  }
  @include desktop {
    padding: 0 20px;
  }

  .headerInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;

    h1 {
      a {
        width: 128px;
        height: 30px;
        background: url('/assets/images/common/header_logo_mo.png') no-repeat
          center / cover;
        @include desktop {
          width: 165px;
          height: 39px;
          background: url('/assets/images/common/header_logo_pc.png') no-repeat
            center / cover;
        }
      }
    }
    .en_ko {
      display: flex;
      gap: 10px;
      
      @include desktop {
        position: absolute;
        right: 60px;
        top: 8px;
      }

      li {
        a {
          font-size: var(--fs_12);
          color: var(--admin_black);
          text-decoration: underline;
          
          @include desktop {
            font-size: var(--fs_14);
          }
        }
      }
    }
  }
  .navBtn {
    width: 30px;
    height: 22px;
    background: url('/assets/svgs/common/header_menu.svg') no-repeat center /
      cover;
  }
}

@import '/src/styles/media.scss';

.NewSemenSelection {
    
    width:100%;
    height:auto;
    background-color:var(--white_ff);

    .semenSelection {

        width:100%;
        height:auto;

        .nation {

            display:flex;
            justify-content:space-between;
            flex-direction:column;        
            width:100%;
            height:auto;   
            overflow:hidden; 

            
            @include desktop {

            
            flex-direction:row;            

            }
            
            
                    
            > li {
                
                position:relative;
                display:flex;
                align-items:center;
                justify-content:space-between;
                float:left;  
                width:100%;
                height:600px;                       
                padding:10px 10px;               
                text-align:center;     
                

                @include tablet {
                    height:400px;
                }

                @include desktop {
                    width:30%;
                }
            
                

                .nationName {
                    position:absolute;
                    display:block;
                    top:0;
                    left:0;                    
                    width:100%;
                    height:50px;
                    line-height:50px;
                    background-color:var(--main_blue);
                    color:var(--white_ff);

                }


                .semenList {
                    
                    position:absolute;
                    display:flex; 
                    flex-direction:row;
                    flex-wrap:wrap;
                    align-content:flex-start;
                    justify-content: center;
                    top:50px;
                    left:0px;               
                    width:100%;            
                    height:auto;
                    padding: 10px 10px;
                    
                    border: solid 1px var(--main_right_gray);
                    
                    li {
                                        
                        display:block; 
                        padding:10px 10px; 
                        font-size: var(--fs_14);
                        color: var(--main_black);
                        background-color:(--ff_white);
                        cursor:pointer;
                    }

                    

                }
                                        
                
            }
        }
    }


    .buttons {

        display:flex;
        justify-content:center;

        button {
                    
            width:150px;
            height:40px;            
            margin-bottom:120px;
            background-color:var(-white_ff);
            border:solid 1px var(--main_right_gray);
            font-weight:var(--fw_bold);

        }

        button:first-child {
            margin-right:20px;
        }

    }

}
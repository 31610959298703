@import '/src/styles/media.scss';

.oxNow {
  margin-bottom: 60px;
  @include tablet {
    margin-bottom: 80px;
  }
  @include desktop {
    margin-bottom: 100px;
  }
  .oxNowTab {
    display: flex;
    justify-content: center;
    gap: 16px;
    @include tablet {
      justify-content: flex-start;
    }

    button {
      padding: 10px;
      font-size: var(--fs_16);
      font-weight: var(--fw_medium);
      color: var(--main_gray);
      border-bottom: solid 1px var(--gray_dd);
      @include tablet {
        font-size: var(--fs_20);
        padding: 15px;
      }

      &.current,
      &:hover {
        color: var(--main_green);
        border-bottom: solid 1px var(--main_green);
      }
    }
  }
  .tableName {
    font-size: var(--fs_18);
    margin: 40px 0 24px 0;
    @include tablet {
      font-size: var(--fs_24);
      text-align: center;
    }
  }
  .tablebox {
    table {
      thead {
        tr {
          th {
            font-size: var(--fs_14);
            @include desktop {
              font-size: var(--fs_18);
            }
          }
          &:nth-child(2) {
            th {
              color: var(--main_black);
              background-color: rgba(252, 200, 0, 0.25);
            }
          }
          th {
            &:nth-child(1) {
              width: 6%;
            }
            &:nth-child(2) {
              width: 10%;
            }
            &:nth-child(3) {
              width: 10%;
            }
            &:nth-child(4) {
              width: 9%;
            }
            &:nth-child(5) {
              width: 6%;
            }
            &:nth-child(6) {
              width: 6%;
            }
            &:nth-child(7) {
              width: 6%;
            }
            &:nth-child(8) {
              width: 6%;
            }
            &:nth-child(9) {
              width: 6%;
            }
            &:nth-child(10) {
              width: 6%;
            }
            &:nth-child(11) {
              width: 6%;
            }
            &:nth-child(12) {
              width: 25%;
            }
          }
          button {
            position: relative;
            color: var(--gray_f8);
            font-size: 14px;
            font-weight: var(--fw_bold);
            text-decoration: underline;
            padding-right: 20px;
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-40%);
              right: 0;
              width: 8px;
              height: 12px;
              background: url('/assets/svgs/containers/ox/oxSearch/sort_icon.svg')
                no-repeat center/cover;
              @include tablet {
                transform: translateY(-35%);
              }
            }
            &.current {
              color: var(--main_blue);
            }
            @include desktop {
              font-size: 18px;
            }
          }
        }
      }
      tbody {
        td {
          button {
            font-size: 12px;
            color: var(--main_green);
            text-decoration: underline;
            @include tablet {
              font-size: 14px;
            }
            @include desktop {
              font-size: 16px;
            }
          }
          span {
            display: block;
            color: var(--main_red);
          }
        }
      }
    }
  }
}

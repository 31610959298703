@import '/src/styles/media.scss';

.findCow {
  .findCowForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    @include tablet {
      flex-direction: row
    };
    @include desktop {
      gap: 20px;
    }
    input {
      flex: 1;
      width: 100%;
      font-size: var(--fs_14);
      font-weight: var(--fw_medium);
      color: var(--main_gray);
      border: solid 1px var(--admin_gray);
      padding: 4px;
      &:focus {
        border: solid 1px var(--main_black);
      }
      &::placeholder {
        font-size: var(--fs_14);
      }
      @include tablet {
        font-size: var(--fs_18);
      }
    }
  }
  .cowInfoTable {
    margin: 40px 20px 0 20px;
    border-top: solid 2px var(--main_green);
    border-left: solid 1px var(--admin_gray);
    border-right: solid 1px var(--admin_gray);
    

    @include desktop {
      padding: 0;
      max-width: 1440px;
      margin: 100px auto 0;
    }

    table {

      tr {
        th {
          width: 10%;
          background-color: var(--gray_f8);
          color: var(--main_gray);
          font-weight: var(--fw_normal);
          border: solid 1px var(--admin_gray);
        } 
        td {
          width: 40%;
          border: solid 1px var(--admin_gray);
          background-color: var(--white_ff);
        }
      }
    }
  }
  .findCowTab {
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 0 20px;
    margin: 80px 0 40px 0;
    @include tablet {
      gap: 20px;
      justify-content: flex-start;
    }
    @include desktop {
      padding: 0;
      max-width: 1440px;
      margin: 120px auto 60px; 
    }

    li {
      button {
        padding: 16px 12px;
        border: solid 1px var(--gray_dd);
        border-radius: 15px;
        background-color: var(--white_ff);
        font-size: var(--fs_12);
        font-weight: var(--fw_medium);
        color: var(--main_gray);
        @include tablet {
          font-size: var(--fs_20);
          padding: 30px;
        }

        &.current,
        &:hover {
          color: var(--gray_f8);
          border: solid 1px var(--main_green);
          background-color: var(--main_green);
        }
      }
    }
  }
  .milkPerformanceTable {
    margin: 40px 20px 0 20px;
    @include desktop {
      padding: 0;
      max-width: 1440px;
      margin: 0 auto;
    }
  }
  .reproductivePerformanceTable {
    margin: 40px 20px 0 20px;
    white-space: nowrap;
    overflow-x: auto;
    @include desktop {
      padding: 0;
      max-width: 1440px;
      margin: 0 auto;
    }
  }
}
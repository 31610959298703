.newCowAnal101Pdf {
    position: relative;
    max-width: 1440px;
    min-width: 902px;
    min-height: 1000px;
    margin: 0 auto;
    padding: 40px 30px 80px;
    background-color: var(--white_ff);
    
    .standardMonth {
        text-align:right;
        font-size:var(--fs_16);
    }
  
    h1 {
      text-align: center;
      font-size: var(--fs_28);
      font-weight: var(--fw_normal);     
      margin-top:20px;   
    }

    .newCowAnal101Info {
      display: flex;
      gap: 12px;
      border-top: solid 1px var(--main_blue);
      border-bottom: solid 1px var(--main_blue);
      padding: 10px 20px;
      background-color: rgba(52, 73, 94, 0.1);
    }
    
    .titleTable {
      tbody {
        tr {
          td {
            width: 9%;
          }
        }
      }
    }
    .pdfTable {
      margin-top: 20px;

      tr,th {
        border:solid 1px var(--main_black);
      }
      tbody {
        tr {
          td {
            padding: 2px;
            white-space: nowrap;
          
            &:nth-child(1) {
              width: 4%;
            }
            &:nth-child(2) {
              width: 3%;
            }
            &:nth-child(3) {
              width: 3%;
            }
            &:nth-child(4) {
              width: 2%;
            }
            &:nth-child(5) {
              width: 2%;
            }
            &:nth-child(6) {
              width: 2%;
            }
            &:nth-child(7) {
              width: 2%;
            }
            &:nth-child(8) {
              width: 2%;
            }
            &:nth-child(9) {
              width: 2%;
            }
            &:nth-child(10) {
              width: 2%;
            }
            &:nth-child(11) {
              width: 2%;
            }
            &:nth-child(12) {
              width: 2%;
            }
            &:nth-child(13) {
              width: 2%;
            }
            &:nth-child(14) {
              width: 2%;
            }
            &:nth-child(15) {
              width: 2%;
            }
            &:nth-child(16) {
              width: 2%;
            }
            &:nth-child(17) {
              width: 2%;
            }
            &:nth-child(18) {
              width: 2%;
            }
            &:nth-child(19) {
              width: 2%;
            }
            &:nth-child(20) {
              width: 8%;
            }
            &:nth-child(21) {
              width: 8%;
            }
            &:nth-child(22) {
              width: 3%;
            }
            &:nth-child(23) {
              width: 8%;
            }
            &:nth-child(24) {
              width: 4%;
            }
            &:nth-child(25) {
              width: 4%;
            }
          }
        }
      }
    }
    .summary {
      margin-top:20px;
      font-size:var(--fs_14);
    }
  }

.dciHeader {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 180px;
  min-height: 100vh;
  background-color: var(--admin_black);
  z-index: var(--1000);
  transform: translateX(-180px);
  transition: transform .5s;
  &.open {
    transform: translateX(0);
  }

  h1 {
    padding: 12px 0;
    border-bottom: solid 1px var(--gray_de);
    margin-bottom: 24px;

    a {
      display: flex;
      gap: 24px;
      align-items: center;
      justify-content: center;

      color: var(--white_ff);
      font-size: 16px;
    }
  }
  nav {
    .twoDepth {
      > li {

        > button,
        > a {
          position: relative;
          width: 100%;
          padding: 8px 4px;
          font-size: var(--fs_16);
          text-align: start;
          color: var(--admin_gray);
        
          &:hover,
          &.current{
            background-color: rgba(255,255,255, .1);
            color: var(--gray_de);

            &::before {
              background: url('/assets/svgs/common/v_icon_white.svg') no-repeat center / cover;
            }
          }
        }
        > button {
          &::before {
            content: '';
            position: absolute;
            right: 4px;
            top: 50%;
            width: 15px;
            height: 7px;
            background: url('/assets/svgs/common/v_icon_gray.svg') no-repeat center / cover;
            transform: translateY(-50%) rotateZ(90deg);
          }
          &.current {
            &::before {
              transform: translateY(-50%);  
            }
          }
        }

        .threeDepth {
          background-color: rgba(255,255,255, .1);
          li {
            a {
              padding: 4px;
              font-size: var(--fs_14);
              color: var(--admin_gray);

              &:hover {
                text-decoration: underline;
              }
            }
          } 
        }
      }
    }
  }
  .logout {
    width: 100%;
    padding: 4px 0;
    border-radius: 4px;
    background-color: var(--main_green);
    color: var(--white_ff);
  }
  .totalView {
    margin-top: 20px;
    padding: 0 4px;

    ul {
      display: flex;
      justify-content: center;
      gap: 12px;
      background-color: rgba(255,255,255,.1);
      border-radius: 4px;
      font-size: var(--fs_12);
      color: var(--admin_gray);
      padding: 8px 0;

      li {
        p {
          span {
            color: var(--white_ff);
          }
        }
      }
    }
  }
  .navBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 160px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: var(--main_green);
    
    .arrow {
      width: 15px;
      height: 9px;
      background: url('/assets/svgs/common/v_icon_white.svg') no-repeat center;
      transform: rotateZ(-90deg);
      &.open {
        transform: rotateZ(90deg);
      }
    }
  }
}
.newGraphMun01 {
  position: relative;
  max-width: 1440px;
  min-width: 1122px;
  min-height: 793px;
  margin: 0 auto;
  padding: 40px 30px 80px;
  background-color: var(--white_ff);

  h1 {
    text-align: center;
    font-size: var(--fs_28);
    font-weight: var(--fw_normal);
    padding: 10px 0;
    margin-top: 10px;
    border: solid 1px var(--gray_de);
    background-color: antiquewhite;
  }
  .dotChartArea {
    padding: 20px 0;
    margin-bottom: 20px;
    background-color: var(--gray_de);
  }
  .Mun01Label {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 4px;
    margin: 20px auto 0;
    font-size: var(--fs_14);

    li {
      position: relative;
      padding-left: 20px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      &:nth-child(1) {
        &::before {
          background-color: black;
        }
      }
      &:nth-child(2) {
        &::before {
          background-color: red;
        }
      }
      &:nth-child(3) {
        &::before {
          background-color: green;
        }
      }
      &:nth-child(4) {
        &::before {
          background-color: yellow;
        }
      }
      &:nth-child(5) {
        &::before {
          background-color: blue;
        }
      }
      &:nth-child(6) {
        &::before {
          background-color: gray;
        }
      }
      &:nth-child(7) {
        &::before {
          background-color: purple;
        }
      }
      &:nth-child(8) {
        &::before {
          background-color: orange;
        }
      }
      &:nth-child(9) {
        &::before {
          background-color: brown;
        }
      }
    }
  }
  .pdfTable {
    tbody {
      tr {
        td {
          font-size: var(--fs_10);
        }
      }
    }
  }
}
@import '/src/styles/media.scss';

.mobileNav {
  min-width: 320px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  background-color: var(--white_ff);
  z-index: var(--1000);
  transition: right .5s;
  @include desktop {
    display: none;
  }
  @include tablet {
    width: 420px;
  }

  &.open {
    right: 0;
  }

  .head {
    height: 60px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: solid 1px var(--main_black);
    
    button {
      width: 20px;
      height: 20px;
      background: url('/assets/svgs/common/X_button.svg') no-repeat center/cover;
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    padding: 20px;
    @include tablet {
      gap: 70px;
      padding: 30px;
    }

    .twoDepth {
      position: relative;
      display: flex;
      flex-direction: column;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -25px;
        width: 1px;
        height: calc(100vh - 80px);
        background-color: var(--main_gray);
        @include tablet {
          right: -35px;
        }
      }

      button {
        position: relative;
        font-size: var(--fs_14);
        padding: 10px 0;
        @include tablet { 
          font-size: var(--fs_18);
        }

        &.current,
        &:hover {
          color: var(--main_green);

          &::before {
            transition: all .3s;
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -15px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--main_green);
          }
        }
      }
    }

    .threeDepth {
      flex: 1;
      display: flex;
      flex-direction: column;

      a {
        padding: 5px 0;
        @include tablet {
          padding: 10px 0;
        }
        span {
          font-size: var(--fs_14);
          font-weight: var(--fw_bold);
          color: var(--main_gray);
          @include tablet {
            font-size: var(--fs_18);
          }
        }
      }
    }
  }

  .mobileNavBack { 
    position: fixed;
    left: -100vw;
    top: 0;
    width: calc(100% - 320px);
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    transition: left .5s;
    @include tablet {
      width: calc(100% - 420px);
    }
    
    &.open {
      left: 0;
    }
  }
}
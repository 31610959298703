@import '/src/styles/media.scss';

.promotionalVideoDetail {
  margin-bottom: 40px;
  @include tablet {
    margin-bottom: 60px;
  }
  @include desktop {
    margin-bottom: 80px;
  }

  .promotionalVideoDetailInner {
    border-top: solid 2px var(--main_gray);
    border-bottom: solid 1px var(--gray_dd);

    .promotionalVideoDetailHead {
      padding: 6px;
      border-bottom: solid 1px var(--gray_dd);
      
      h5 {
        font-size: var(--fs_14);
        @include tablet {
          font-size: var(--fs_18);
        }
        @include tablet {
          font-size: var(--fs_24);
        }
      }
      .promotionalVideoDetailHeadInfo {
        display: flex;
        justify-content: space-between;
        font-size: var(--fs_12);
        color: var(--main_gray);
        margin-top: 8px;
        @include tablet {
          font-size: var(--fs_14);
        }

        ul {
          display: flex;
          gap: 12px;
          @include tablet {
            gap: 24px;
          }

          li {
            &:first-child {
              position: relative;
              &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-40%);
                right: -6px;
                width: 1px;
                height: 8px;
                background-color: var(--main_gray);
                @include tablet {
                  right: -12px;
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }
    .promotionalVideoDetailBody {
      padding: 10px;
      @include tablet {
        padding: 20px;
      }

      div {
        text-align: center;
        @include desktop {
          width: 80%;
          margin: 0 auto;
        }

        height: 300px;
        @include tablet {
          height: 450px;
        }
        @include desktop {
          height: 550px;
        }
      }
    }
    .promotionalVideoDetailFoot {
      border-top: solid 1px var(--gray_dd);
      li {
        display: flex;

        span {
          font-size: var(--fs_14);
          font-weight: var(--fw_bold);
          border-bottom: solid 1px var(--main_black);
          padding: 4px 8px; 
          @include tablet {
            font-size: var(--fs_18);
            padding: 6px 12px; 
          }
        }
        button {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 4px 8px;
          font-size: var(--fs_12);
          @include tablet {
            font-size: var(--fs_14);
            padding: 6px 12px; 
          }
        }
      }
    }
  }
  .viewBtn {
    margin-top: 18px;
    text-align: end;
    button {
      display: inline-block;
      font-size: var(--fs_14);
      font-weight: var(--fw_bold);
      color: var(--main_gray);
      border:solid 1px var(--main_gray);
      padding: 4px 8px;
      @include tablet {
        font-size: var(--fs_18);
        padding: 4px 12px;
      }
    }
  }
}
.bo-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;

  .bo-header {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    position: relative;
    width: 100%;
    z-index: 1000;

    .header-content {
      max-width: 1600px;
      margin: 0 auto;
      padding: 0.5rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 1.25rem;
        color: #1a73e8;
        font-weight: 600;
        margin: 0;
      }

      .top-nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a,
        .logout-btn {
          color: #666;
          text-decoration: none;
          font-size: 0.75rem;
          font-weight: 500;
          transition: color 0.2s ease;

          &:hover {
            color: #1a73e8;
            text-decoration: none;
          }
        }

        .separator {
          margin: 0 0.5rem;
          color: #ddd;
        }

        .logout-btn {
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
        }
      }
    }
  }

  .content-wrapper {
    display: flex;
    flex: 1;
    max-width: 1600px;
    margin: 0 auto;
    width: 100%;

    .side-nav {
      width: 240px;
      background: #fff;
      box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);
      padding: 1.5rem 0;
      flex-shrink: 0;

      h2 {
        padding: 0 1.5rem 1.5rem;
        margin: 0;
        font-size: 1.25rem;
        color: #1a73e8;
        font-weight: 600;
        border-bottom: 1px solid #eee;
      }

      ul {
        list-style: none;
        padding: 0.5rem 0;
        margin: 0;

        li {
          a,
          button {
            display: block;
            width: 100%;
            text-align: left;
            padding: 0.875rem 1.5rem;
            color: #5f6368;
            text-decoration: none;
            font-size: 0.875rem;
            font-weight: 500;
            border: none;
            background: none;
            cursor: pointer;
            transition: all 0.2s ease;
            position: relative;

            &:hover {
              color: #1a73e8;
              background: #f1f3f4;
            }
          }

          button {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:after {
              content: '';
              width: 6px;
              height: 6px;
              border-right: 2px solid #5f6368;
              border-bottom: 2px solid #5f6368;
              transform: rotate(45deg);
              transition: transform 0.2s ease;
            }

            &:hover:after {
              border-color: #1a73e8;
            }
          }

          ul {
            display: none;
            background: #f8f9fa;
            padding: 0.5rem 0;

            &.open {
              display: block;
            }

            li {
              a {
                padding-left: 3rem;
                font-size: 0.813rem;
                color: #666;

                &:hover {
                  color: #1a73e8;
                }
              }

              ul {
                li a {
                  padding-left: 4rem;
                  font-size: 0.75rem;
                  color: #777;

                  &:before {
                    content: '';
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ccc;
                    margin-right: 8px;
                    vertical-align: middle;
                  }

                  &:hover {
                    color: #1a73e8;

                    &:before {
                      background: #1a73e8;
                    }
                  }
                }
              }
            }
          }

          &.active {
            > a,
            > button {
              color: #1a73e8;
              background: #e8f0fe;
              font-weight: 600;

              &:after {
                border-color: #1a73e8;
              }
            }

            > ul > li.active {
              > a {
                color: #1a73e8;
                background: rgba(232, 240, 254, 0.7);
                font-weight: 600;
              }

              > ul > li.active > a {
                color: #1a73e8;
                background: rgba(232, 240, 254, 0.4);
                font-weight: 600;

                &:before {
                  background: #1a73e8;
                }
              }
            }
          }
        }
      }
    }

    .main-content {
      flex: 1;
      padding: 2rem;
      margin-left: 2rem;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
  }
}

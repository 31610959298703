@import '/src/styles/media.scss';

.provinceStatus {
  .subTitle {
    @include desktop {
      margin: 100px 0;
    }
  }

  .provinceStatusPdf {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 60px;
    @include tablet {
      gap: 40px;
      margin-bottom: 80px;
    }
    @include desktop {
      margin-bottom: 100px;
    }
  
    > li {
      display: flex;
      gap: 15px;
      padding-bottom: 30px;
      border-bottom: solid 1px var(--main_blue);
      &:last-child {
        border-bottom: none;
      }
      @include tablet {
        gap: 100px;
        padding-bottom: 40px;
      }
      @include desktop {
        gap: 290px;
      }
  
      span {
        font-size: var(--fs_14);
        font-weight: var(--fw_bold);
        color: var(--main_blue);
        @include tablet {
          font-size: var(--fs_20);
        }
      }
  
      .monthPdf {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 15px;
        @include tablet {
          column-gap: 24px;
          row-gap: 40px;
        }
        @include desktop {
          column-gap: 75px;
          row-gap: 20px;
        }
  
        li {
          position: relative;
          font-size: var(--fs_12);
          color: var(--main_gray);
          border: solid 1px var(--gray_dd);
          padding: 2px 4px;
          width: 60px;
          cursor: pointer;
          @include tablet {
            font-size: var(--fs_16);
            width: 115px;
            padding: 6px 4px;
          }
        
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
            width: 12px;
            height: 12px;
            background: url('/assets/svgs/containers/performance/provinceStatus/download_btn_blue.svg') no-repeat center/cover;
            @include tablet {
              width: 22px;
              height: 22px;
            }
          }
          &:hover {
            color: var(--gray_f8);
            border: solid 1px var(--main_blue);
            background-color: var(--main_blue);

            &::before {
              background: url('/assets/svgs/containers/performance/provinceStatus/download_btn_white.svg') no-repeat center/cover;
            }
          }
        }
      }
    }
  }
}
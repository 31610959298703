@import '/src/styles/media.scss';
.monthFarmhouse {
  .subTitle {
    @include desktop {
      padding: 100px 0 50px 0;
    }
  }
  .monthFarmHouseTab {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 20px;
    @include tablet {
      grid-template-columns: repeat(6, 1fr);
      margin-bottom: 30px;
    }
    @include desktop {
      grid-template-columns: repeat(7, 1fr);
      margin-bottom: 40px;
    }

    li {
      button {
        width: 100%;
        font-size: 14px;
        padding: 4px 0;
        color: var(--dark_44);
        border: solid 1px var(--gray_dd);
        @include tablet {
          font-size: 16px;
          padding: 6px 0;
        }

        &.current {
          color: var(--white_ff);
          background-color: var(--main_green);
          border: solid 1px var(--main_green);
        }
      }
    }
  }
  .monthlyData {
    display: none;
    @include tablet {
      display: none;
      flex-wrap: wrap;
      column-gap: 10px;
      &.current {
        display: flex;
      }
    }
    &.current {
      display: flex;
      flex-wrap: wrap;
    }

    .monthFarmhouse_con {
      border: 1px solid #ddd;
      margin-bottom: 20px;
      width: 100%;
      @include tablet {
        width: calc(50% - 10px);
        margin-bottom: 50px;
      }
      @include desktop {
        width: calc(25% - 10px);
      }
    }
    margin-bottom: 20px;

    .monthFarmhouse_title {
      padding: 24px 16px;
      font-size: 14px;
      @include tablet {
        font-size: 16px;
      }
      color: #34495e;
    }
    .monthFarmhouse_info {
      list-style: none;
      padding: 0 16px 24px 16px;
      .entryItem {
        position: relative;
        font-size: 11px;
        color: #555555;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #dddddd;
        @include tablet {
          font-size: 14px;
        }

        &::before {
          position: absolute;
          content: '';
          top: 20%;
          right: 5px;
          width: 18px;
          height: 18px;
          background: url('/assets/svgs/containers/performance/monthFarmhouse/download_btn_blue.svg')
            no-repeat center/cover;
          @include tablet {
            width: 22px;
            height: 22px;
          }
        }

        &:hover {
          background-color: var(--main_blue);
          color: #fff;
          transition: ease-in-out 300ms;

          &::before {
            background: url('/assets/svgs/containers/performance/monthFarmhouse/download_btn_white.svg')
              no-repeat center/cover;
          }
        }
        span.icon {
          display: inline-block;
        }
      }
    }
  }
}

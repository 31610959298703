@import '/src/styles/media.scss';

.girlcowSelect {
  padding: 10px 15px;
  @include laptop {
    padding: 25px 30px;
  }

  h2 {
    font-weight: var(--fw_medium);
    margin-bottom: 15px;
    font-size: var(--fs_18);
    @include laptop {
      font-size: var(--fs_24);
    }
  }
  span {
    font-size: var(--fs_14);
  }
  .girlcowSelectHead {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
    @include tablet {
      flex-direction: row;
      gap: 0;
    }

    select {
      padding: 4px 8px;
    }
    .exp {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .girlcowSearch {
      display: flex;
      gap: 15px;
      align-items: center;
      flex-direction: column;
      @include tablet {
        flex-direction: row;
      }

      .girlcowSearchArea {
        display: flex;
        gap: 15px;
        align-items: center;
        width: 100%;
        @include tablet {
          width: auto;
        }

        input {
          border: solid 1px var(--gray_de);
          height: 100%;
          padding: 4px 8px;
          flex: 1;
        }
      }
      .girlcowSearchBtn {
        display: flex;
        gap: 15px;
        align-self: flex-end;
      
        button {
          padding: 4px 8px;
          border-radius: 5px;
          background-color: var(--sub_blue2);
          color: var(--white_ff);
        }
      }
    }
  }
  .cowSelectTable {
    overflow-y: auto;
    table {
      min-width: 500px;
      margin-top: 25px;
  
      button {
        text-decoration: underline;
        &.current {
          color: var(--sub_blue2);
        }
      }
      thead {
        tr {
          &:nth-child(1) {
            th {
              &:nth-child(1) {
                width: 4.1%;
              }
              &:nth-child(5) {
                width: 10.1%;
              }
            }
          }
          &:nth-child(2) {
            th {
              &:nth-child(1) {
                width: 6%;
              }
              &:nth-child(2) {
                width: 10%;
              }
              &:nth-child(3) {
                width: 10%;
              }
              &:nth-child(4) {
                width: 3.8%;
              }
              &:nth-child(5) {
                width: 8%;
              }
              &:nth-child(6) {
                width: 8%;
              }
              &:nth-child(7) {
                width: 8%;
              }
              &:nth-child(8) {
                width: 8%;
              }
              &:nth-child(8) {
                width: 8%;
              }
              &:nth-child(10) {
                width: 8%;
              }
              &:nth-child(11) {
                width: 8%;
              }
            }
          }
        }
        th {
          border: solid 1px var(--gray_df);
        }
      }
    }
    .girlcowSelectList {
      min-width: 600px;
      max-height: 400px;
      text-align: center;
      border-left: solid 1px var(--gray_df);
      border-bottom: solid 1px var(--gray_df);
      border-right: solid 1px var(--gray_df);
      overflow-y: scroll;
      &::-webkit-scrollbar{
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        width: 2px;
        background-color: var(--gray_df);
      }

      > li {
        &:nth-child(even) {
          background-color: var(--gray_e8);
        }
        ul {
          display: flex;
          border-bottom: solid 1px var(--gray_df);
          li {
            font-size: var(--fs_12);
            padding: 0 2px;
            @include laptop {
              font-size: var(--fs_14);
              padding: 6px 2px;
            }
            &.numberCon {
              text-align: end;
            }
            &:nth-child(1) {
              width: 4%;
            }
            &:nth-child(2) {
              width: 6%;
            }
            &:nth-child(3) {
              width: 10%;
            }
            &:nth-child(4) {
              width: 10%;
            }
            &:nth-child(5) {
              width: 4%;
            }
            &:nth-child(6) {
              width: 8%;
            }
            &:nth-child(7) {
              width: 8%;
            }
            &:nth-child(8) {
              width: 8%;
            }
            &:nth-child(9) {
              width: 8%;
            }
            &:nth-child(10) {
              width: 8%;
            }
            &:nth-child(11) {
              width: 8%;
            }
            &:nth-child(12) {
              width: 8%;
            }
            &:nth-child(13) {
              width: 10%;
            }
          }
        }
      }
    }
  }
}
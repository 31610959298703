@import '/src/styles/media.scss';

.gene {
  background: var(--admin_bg) url('/assets/images/common/milk_gene_bg.png') no-repeat center bottom 100px;
  background-size: 100% auto;
  position: relative;
  
  @include desktop {
    display: flex;
    justify-content: center;
    max-height: 100vh;
    overflow-y: scroll;
  }
  .geneInner {
    @include desktop {
      min-height: 100vh;
      max-width: 1000px;
      flex: 1;
      padding-top: 20px;
    }
    > div {
      background-color: var(--white_ff);
      border-radius: 16px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
      min-height: calc(100vh - 40px);
      padding: 20px;

      h2 {
        font-size: var(--fs_24);
        margin-bottom: 20px;
      }
      .geneTable {
        overflow-x: auto;
        table {
          border-top: solid 1px var(--admin_black);
          text-align: center;
        
          thead {
            th {
              background-color: var(--gray_f2);
              font-size: var(--fs_14);
              font-weight: var(--fw_medium);
              padding: 4px 0;
  
              @include desktop{
                padding: 10px 0;
              }
            }
          }
          tbody {
            tr {
              &:nth-child(even) {
                background-color: var(--gray_e8);
              }
              td {
                border: solid 1px var(--gray_df);
                font-size: var(--fs_14);
                padding: 6px 2px;

                &.numberCon {
                  text-align: end;
                }
              }
            }
          }
        }
      }
    }
  }
}
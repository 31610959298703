.bo-content {
  h2 {
    margin-bottom: 0.75rem;
  }
}

// 검색 영역
.searchbox {
  margin-bottom: 0.75rem;
  display: flex;
  gap: 0.375rem;
  align-items: center;

  select {
    padding: 0.25rem 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 0.813rem;
    color: #4a5568;
    background: #f8fafc;

    &:focus {
      outline: none;
      border-color: #cbd5e0;
    }
  }

  input {
    padding: 0.25rem 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    flex: 1;
    max-width: 180px;
    font-size: 0.813rem;
    color: #4a5568;
    background: #f8fafc;

    &:focus {
      outline: none;
      border-color: #cbd5e0;
    }
  }

  .btn-search {
    padding: 0.25rem 0.5rem;
    background: #2d3748;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.813rem;
    transition: background 0.2s;

    &:hover {
      background: #1a202c;
    }
  }

  label {
    display: inline-flex;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;
    font-size: 0.813rem;
    color: #4a5568;

    input[type='radio'] {
      margin-right: 0.25rem;
      cursor: pointer;
    }
  }
}

// 상단 정보
.board-top {
  margin-bottom: 0.75rem;

  p {
    color: #718096;
    font-size: 0.813rem;

    strong {
      color: #2d3748;
      font-weight: 600;
    }
  }
}

// 테이블 컨테이너
.table-container {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  overflow-x: auto;

  &.bold-border {
    border: 1px solid #ccc;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      white-space: nowrap;
      padding: 0.5rem;
      border-bottom: 1px solid #edf2f7;
      font-size: 0.813rem;
      color: #4a5568;

      &.wrap {
        white-space: normal;
      }
      label {
        span {
          white-space: nowrap;
        }
      }
    }

    th {
      background: #f7fafc;
      font-weight: 600;
      color: #2d3748;
      font-size: 0.75rem;
      text-transform: uppercase;

      span {
        &.required {
          color: #e53e3e;
        }
      }
    }

    td {
      &.loading-data,
      &.error-data,
      &.no-data {
        padding: 1rem 0;
        text-align: center;
      }

      &.center {
        text-align: center;
      }

      &.loading-data {
        color: #4a5568;
        font-style: italic;
      }

      &.error-data {
        color: #e53e3e;
      }

      > a,
      > button {
        color: #1a73e8;
        text-decoration: none;
        transition: all 0.2s;
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        display: inline-block;
        border: 1px solid #e8eef7;
        background-color: #f8fafc;

        &:hover {
          color: #1a73e8;
          background-color: #f0f4f8;
          text-decoration: underline;
          border-color: #1a73e8;
        }
      }

      > input {
        width: 100%;
        max-width: 400px;
        padding: 0.375rem 0.75rem;
        border: 1px solid #666;
        border-radius: 4px;
        font-size: 0.875rem;
        color: #4a5568;
        background: #fff;
        transition: all 0.2s;

        &:focus {
          outline: none;
          border-color: #4299e1;
          box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
        }

        &[type='radio'] {
          width: auto;

          &:focus {
            box-shadow: none;
          }
        }
        &[type='checkbox'] {
          width: auto;
        }

        // 중복확인 버튼이 있는 input의 경우 너비 조정
        &[name='grp_id'] {
          width: calc(100% - 90px);
          margin-right: 8px;
        }
      }

      // 라디오 버튼을 포함한 라벨 스타일
      > label {
        margin-right: 1.5rem;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
      }

      // Add this new style for CKEditor
      .ckeditor-container {
        .ck-editor__main {
          overflow: auto;
          max-height: 600px;
        }

        .ck-editor__editable_inline {
          min-height: 500px !important;
          max-height: none !important;
          padding: 1rem;
        }

        .ck-toolbar {
          border-radius: 4px 4px 0 0 !important;
        }

        .ck-content {
          border-radius: 0 0 4px 4px !important;
        }

        .ck-file-dialog-button {
          display: none;
        }
      }
    }

    tr:hover {
      background: #f8fafc;
    }

    tbody {
      tr {
        &[style*='cursor: pointer'] {
          &:hover {
            background-color: #edf2f7;
          }
        }
      }
    }
  }
}

// 버튼 그룹
.button-group {
  margin-top: 0.75rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.375rem;

  .btn-l {
    padding: 0.25rem 0.75rem;
    background: #2d3748;
    color: white;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.813rem;
    transition: background 0.2s;
    cursor: pointer;

    &:hover {
      background: #1a202c;
    }
    &:disabled {
      background: #e2e8f0;
      cursor: not-allowed;
    }
  }
}

.bo-container {
  width: 100%;
  height: 100%;
}

.bo-content {
  padding: 1rem;
}

.bo-page {
  width: 100%;
}

.btn-2 {
  padding: 0.25rem 0.5rem;
  background: #2d3748;
  color: white;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.813rem;
  transition: background 0.2s;
  cursor: pointer;

  &:hover {
    background: #1a202c;
  }
}

.farmcongCode {
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: var(--fs_16);
  }
  input {
    height: 100%;
    width: 40px;
    border: solid 1px var(--gray_df);
    border-radius: 4px;
  }
  button {
    background-color: var(--main_green);
    color: var(--white_ff);
    padding: 2px 4px;
    border-radius: 4px;
  }
  ul {
    display: flex;
    gap: 4px;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }
}
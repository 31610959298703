@import '/src/styles/media.scss';

.freezeSemen {

  .subTitle {
    margin-top: 100px;
  }
  .semenTitle {
    margin-bottom: 80px;
    @include desktop {
      margin: 30px 0 80px 0;
    }
    
    dt {
      margin-bottom: 24px;
      font-weight: var(--fw_bold);
      @include tablet {
        font-size: var(--fs_40);
      }

      span {
        display: block;
        @include desktop {
          display: inline;
        }
      }
    }
    dd {
      font-size: var(--fs_14);
      line-height: 170%;
      @include tablet {
        font-size: var(--fs_18);
      }
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 80px;
    @include tablet {
      gap: 120px;
      margin-bottom: 120px;
    }

    li {
      @include desktop {
        display: flex;
        justify-content: space-between;
        &:nth-child(2) {
          flex-flow: row-reverse;
        }
      }

      img {
        width: 100%;
        @include desktop {
          width: 48%;
        }
      }
      dl {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 24px;
        @include tablet {
          width: 80%;
          margin: 50px auto 0;
          gap: 20px;
        }
        @include desktop {
          width: 580px;
          margin: 0;
          justify-content: center;
        }
        dt {
          margin-bottom: -6px;
          font-size: var(--fs_16);
          font-weight: var(--fw_bold);
          @include tablet {
            font-size: var(--fs_24);
          }
        }
  
        dd {
          font-size: var(--fs_14);
          line-height: 170%;
          @include tablet {
            font-size: var(--fs_18);
          }
        }
      }
    }
  }
}
.newFCMFPCMECMPdf {
  position: relative;
  max-width: 1440px;
  min-width: 793px;
  min-height: 1122px;
  margin: 0 auto;
  padding: 40px 30px 80px;
  background-color: var(--white_ff);

  h1 {
    text-align: center;
    font-size: var(--fs_28);
    font-weight: var(--fw_normal);
    margin-bottom: 20px;
  }
  
  .pdfTable {
    margin-top: 20px;

    tbody {
      tr {
        td {
          &:nth-child(1) {
            width: 10%;
          }
          &:nth-child(2) {
            width: 5%;
          }
          &:nth-child(3) {
            width: 6%;
          }
          &:nth-child(4) {
            width: 6%;
          }
          &:nth-child(5) {
            width: 6%;
          }
          &:nth-child(6) {
            width: 6%;
          }
          &:nth-child(7) {
            width: 6%;
          }
          &:nth-child(8) {
            width: 6%;
          }
          &:nth-child(9) {
            width: 6%;
          }
          &:nth-child(10) {
            width: 6%;
          }
          &:nth-child(11) {
            width: 6%;
          }
          &:nth-child(12) {
            width: 6%;
          }
          &:nth-child(13) {
            width: 6%;
          }
        }
      }
    }
  }
  .barChartArea {
    background-color: var(--gray_df);

    .fatLabel {
      display: flex;
      gap: 20px;
      margin-top: 20px;
      justify-content: center;
      font-size: var(--fs_14);
  
      li {
        position: relative;
        padding-left: 20px;
  
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        &:nth-child(1) {
          &::before {
            background-color: violet;
          }
        }
        &:nth-child(2) {
          &::before {
            background-color: red;
          }
        }
        &:nth-child(3) {
          &::before {
            background-color: green;
          }
        }
        &:nth-child(4) {
          &::before {
            background-color: yellow;
          }
        }
      }
    }
  }
}
.newCowBunPdf {
  position: relative;
  max-width: 1440px;
  min-width: 793px;
  min-height: 1122px;
  margin: 0 auto;
  padding: 40px 30px 80px;
  background-color: var(--white_ff);

  h1 {
    text-align: center;
    font-size: var(--fs_28);
    font-weight: var(--fw_normal);
    margin-bottom: 20px;
  }
  .newCowBunInfo {
    display: flex;
    gap: 12px;
    border-top: solid 1px var(--main_blue);
    border-bottom: solid 1px var(--main_blue);
    padding: 10px 20px;
    background-color: rgba(52, 73, 94, 0.1);
  }
  .pdfTable {
    margin-top: 20px;
  }
}
@import '/src/styles/media.scss';

.orderSemen {
  @include desktop {
    max-width: 1440px;
    margin: 0 auto;
  }

  > p {
    font-size: var(--fs_14);
    color: var(--main_gray);
    font-weight: var(--fw_medium);
    line-height: var(--lh_170);
    margin-bottom: 24px;

    span {
      display: block;
      color: var(--main_red);
    }
  }
  .tablebox {
    table {
      tbody {
        td {
          background-color: var(--white_ff);
          &:last-child {
            background-color: rgba(252, 200, 0, 0.25);
            max-width: 60px;
            input {
              width: 100%;
              text-align: center;
            }
          }
          input {
            &.has-order {
              background-color: #fff9c4;
            }
          }
        }
      }
    }
  }
  .orderInfo {
    margin: 40px auto 0;
    border: solid 1px var(--gray_dd);

    li {
      display: flex;
      width: 100%;

      label {
        position: relative;
        width: 25%;
        display: block;
        font-size: var(--fs_14);
        background-color: var(--gray_f8);
        text-align: center;
        padding: 16px 0;
        @include tablet {
          width: 20%;
        }
        @include desktop {
          width: 15%;
        }

        span {
          color: var(--main_red);
        }
      }
      input,
      textarea {
        flex: 1;
        border: solid 1px var(--gray_de);
        padding: 4px;

        &:focus {
          border: solid 1px var(--main_black);
        }
      }
      &.orderTwo {
        ul {
          width: 100%;

          @include desktop {
            display: flex;

            li {
              label {
                width: 30%;
              }
            }
          }
        }
      }
    }
  }
  > strong {
    font-size: var(--fs_14);
    color: var(--sub_blue);
    font-weight: var(--fw_normal);
    @include tablet {
      font-size: var(--fs_16);
    }
  }
  .orderSemenBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 40px;
    @include tablet {
      flex-direction: row;
      justify-content: center;
      gap: 24px;
    }

    li {
      button {
        font-weight: var(--fw_bold);
        color: var(--white_ff);
        background-color: var(--main_blue);
        padding: 12px 20px;
        border: solid 1px var(--sub_gray);
      }

      &:last-child {
        button {
          color: var(--main_gray);
          background-color: var(--white_ff);
        }
      }
    }
  }

  // 라이브러리
  .react-datepicker-wrapper {
    flex: 1;

    .react-datepicker__input-container {
      height: 100%;

      input {
        width: 100%;
        height: 100%;
      }
    }
  }
}

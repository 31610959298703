@import '/src/styles/media.scss';

.boardHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  @include tablet {
    flex-direction: row;
    gap: 0;
  }

  .boardTotal {
    display: flex;
    gap: 8px;
    font-size: var(--fs_14);
    font-weight: var(--fw_bold);
    color: var(--main_right_gray);
    @include tablet {
      font-size: var(--fs_20);
    }

    li {
      span {
        color: var(--main_green);
      }
    }
  }
  .boardSearch {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 80%;
    margin: 0 auto;
    @include tablet {
      flex-direction: row;
      width: 50%;
      margin: 0;
    }

    .searchInput {
      display: flex;
      justify-content: space-between;
      gap: 6px;
      @include tablet {
        flex: 1;
      }

      select,
      input {
        border: solid 1px var(--gray_de);
      }
      select {
        padding: 4px 6px;
        @include tablet {
          font-size: var(--fs_18);
        }
      }
      input {
        width: 100%;
        padding-left: 4px;
        @include tablet {
          font-size: var(--fs_18);
        }
      }
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 6px 0;

      background-color: var(--main_blue);
      color: var(--white_ff);
      font-size: var(--fs_14);
      @include tablet {
        font-size: var(--fs_18);
        padding: 4px 16px;
      }
    }
  }
}
.boardTable {
  margin-top: 30px;
  .boardTableHead {
    @include tablet {
      display: flex;
      border-bottom: solid 2px var(--main_black);

      li {
        text-align: center;
        font-weight: var(--fw_bold);
        padding: 12px 0;
        &.number {
          @include desktop {
            width: 10%;
          }
        }
        &.title {
          width: 100%;
          @include desktop {
            width: 45%;
          }
        }
        &.write {
          width: 20%;
          @include desktop {
            width: 15%;
          }
        }
        &.date {
          width: 20%;
          @include desktop {
            width: 16%;
          }
        }
        &.view {
          @include desktop {
            width: 8%;
          }
        }
        &.attach {
          @include desktop {
            width: 6%;
          }
        }
      }
    }
  }
  .boardTableBody {
    .noSearch {
      text-align: center;
      padding: 6px 0;
    }
    > li {
      border-bottom: solid 1px var(--sub_gray);

      .boardTableItem {
        padding: 4px;
        @include tablet {
          display: flex;
          padding: 20px 0;
        }
        &.mainNotice {
          font-weight: var(--fw_bold);
        }

        li {
          font-size: var(--fs_12);
          color: var(--main_gray);
          text-align: center;
          @include tablet {
            font-size: var(--fs_18);
          }
          &.number {
            @include desktop {
              width: 10%;
            }
          }
          &.title {
            width: 100%;
            @include desktop {
              width: 45%;
            }
            a {
              position: relative;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: start;
              text-decoration: underline;
              &:hover {
                text-decoration: underline;
              }
              @include tablet {
                text-decoration: none;
              }
              &.download {
                span {
                  &::before {
                    width: 14px;
                    height: 14px;
                    background: url('/assets/svgs/common/download.svg')
                      no-repeat center/cover;
                  }
                }
              }
              &.answer {
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 12px;
                  height: 12px;
                  background: url('/assets/svgs/containers/data/BuyAndSell/reply.svg')
                    no-repeat center/cover;
                  @include tablet {
                    width: 16px;
                    height: 16px;
                  }
                }
              }

              span {
                position: relative;
                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  transform: translateY(-40%);
                  right: -24px;
                  width: 12px;
                  height: 12px;
                  background: url('/assets/svgs/containers/info/link.svg')
                    no-repeat center/cover;
                }
              }
            }
          }
          &.write {
            position: relative;
            display: inline-block;
            margin-right: 24px;
            @include tablet {
              display: block;
              width: 20%;
              margin-right: 0;
            }
            @include desktop {
              width: 15%;
            }
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-45%);
              right: -12px;
              width: 1px;
              height: 12px;
              background-color: var(--main_gray);
              @include tablet {
                display: none;
              }
            }
          }
          &.date {
            display: inline-block;
            @include tablet {
              display: block;
              width: 20%;
            }
            @include desktop {
              width: 16%;
            }
          }
          &.view {
            @include desktop {
              width: 8%;
            }
          }
          &.attach {
            @include desktop {
              width: 6%;
            }
            img {
              width: 15px;
              height: 17px;
              vertical-align: baseline;
            }
          }
        }
      }
    }
  }
}
.boardBtn {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 18px;

  li {
    a,
    button {
      display: inline-block;
      font-size: var(--fs_14);
      font-weight: var(--fw_bold);
      color: var(--main_gray);
      border: solid 1px var(--main_gray);
      padding: 4px 8px;
      @include tablet {
        font-size: var(--fs_18);
        padding: 4px 12px;
      }
    }
  }
}

.newTotalprint {
  background-color: var(--gray_f8);
  height: calc(100vh - 22px);

  h1 {
    padding-top: 20px;
    font-size: var(--fs_24);
    color: var(--main_green);
    text-align: center;
  }
  .pdfTable {
    max-width: 800px;
    margin: 20px auto;

    td {
      &:nth-child(1) {
        input {
          display: none;
        }
        label {
          display: block;
          margin: 0 auto;
          width: 18px;
          height: 18px;;
          background: url('/assets/svgs/containers/performance/plannedMatingSemen/chooseCheck.svg') no-repeat center/cover;
        }
        input:checked + label {
          background: url('/assets/svgs/containers/performance/plannedMatingSemen/chooseChecked.svg') no-repeat center/cover; 
        }
      }
      &:nth-child(2) {
        padding-left: 20px;
        text-align: start;
      }
    }
  }
  .totalprintBtn {
    display: flex;
    justify-content: center;
    gap: 8px;

    li {
      button {
        width: 100%;
        padding: 8px 16px;
        border: solid 1px var(--main_blue);
        border-radius: 4px;
      }
    }
  }
}
@import '/src/styles/media.scss';

.buyContact {
  padding: 0 20px;
  @include desktop {
    padding: 0;
    max-width: 1440px;
    margin: 0 auto;
  }

  h5 {
    font-size: var(--fs_14);
    @include tablet {
      font-size: var(--fs_20);
    }
  }

  .mapArea {
    border-top: solid 1px var(--gray_dd);
    border-bottom: solid 1px var(--gray_dd);
    margin-top: 24px;
    padding-bottom: 24px;
    @include desktop {
      border: solid 1px var(--gray_dd);
    }

    svg {
      margin: 24px 0;

      path {
        &.junnam,
        &.junbok,
        &.gyeongnam,
        &.gyeongbok,
        &.chungnam,
        &.chungbok,
        &.gyeonggi,
        &.gangwon {
          cursor: pointer;
        }
      }
    }
    .allBtn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      @include tablet {
        flex-direction: row;
      }

      p {
        font-size: var(--fs_14);
        color: var(--sub_blue);
        @include tablet {
          font-size: var(--fs_16);
        }
      }
      button {
        width: 110px;
        padding: 10px 0;;
        margin: 0 auto;
        font-size: var(--fs_18);
        font-weight: var(--fw_bold);
        color: var(--white_ff);
        background-color: var(--main_blue);
        @include tablet {
          margin: 0;
        }
      }
    }
  }
  .contactUs {
    display: grid;
    row-gap: 24px;
    text-align: center;
    margin-top: 40px;
    @include tablet {
      align-items: start;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 24px;
    }
    @include desktop {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      width: 80%;
      margin: 0 auto;
      padding: 20px 0;
      border: solid 1px var(--gray_dd);
      @include tablet {
        width: 220px;
        margin: 0;
      }

      strong {
        display: inline-block;
        margin-bottom: 32px;
      }
      p,
      span {
        color: var(--main_gray);
      }
    }
  }
}
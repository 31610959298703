@import '/src/styles/media.scss';

.notFound {
  height: calc(100vh - 301px);
  margin-top: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @include tablet {
    height: calc(100vh - 307px);
    gap: 20px;
  }

  h2 {
    font-size: var(--fs_18);
    @include tablet {
      font-size: var(--fs_24);
    }
  }

  p {
    font-size: var(--fs_14);
    @include tablet {
      font-size: var(--fs_18);
    }
  }

  a {
    padding: 4px 8px;
    background-color: var(--main_green);
    color: var(--gray_f8);
    border-radius: 5px;
    font-size: var(--fs_14);
    @include tablet {
      font-size: var(--fs_18);
    }
  }
}
@import '/src/styles/media.scss';

.superiorCow {
  &.subCon {
    &.pullContent {
      @include desktop {
        max-width: 1440px;
        margin: 0 auto;
      }
    }
  }
  .subTitle {
    padding-left: 20px;
    @include desktop {
      padding-left: 0;
      margin: 120px 0 30px 0;
    }
  }
  > dl {
    padding: 0 20px;
    @include desktop {
      padding: 0;
    }
    dt {
      font-weight: var(--fw_bold);
      margin-bottom: 16px;
      @include tablet {
        font-size: var(--fs_40);
        margin-bottom: 32px;
      }

      span {
        display: block;
        @include desktop {
          display: inline;
        }
      }
    }
    dd {
      font-size: var(--fs_14);
      line-height: var(--lh_170);
      @include tablet {
        font-size: var(--fs_18);
      }
    }
  }
  .infoImage {
    text-align: center;
    img {
      width: 100%;
      max-width: 354px;
      padding: 0 20px;
      margin: 60px 0;
      @include tablet {
        margin: 70px 0 120px 0;
      }
      @include desktop {
        margin: 54px 0 36px 0;
      }
    }
  }
  .superiorInfo {
    
    > li {
      margin-bottom: 32px;
      @include tablet {
        margin-bottom: 48px;
      }
      @include desktop {
        margin-bottom: 30px;
      }
      dl {
        dt {
          font-size: var(--fs_16);
          font-weight: var(--fw_bold);
          padding-left: 20px;
          margin-bottom: 16px;
          @include tablet {
            font-size: var(--fs_20);
            margin-bottom: 24px;
          }
          @include desktop {
            margin-bottom: 30px;
          }

          span {
            color: var(--main_green);
          }
        }
        dd {
          padding: 20px;
          background-color: var(--gray_fb);
          font-size: var(--fs_14);
          @include tablet {
            font-size: var(--fs_18);
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 8px;
            @include tablet {
              gap: 20px;
            }
            
            li {
              position: relative;
              padding-left: 14px;
              &::before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0; 
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: var(--main_green);
                @include tablet {
                  top: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  .backImage {
    width: 100%;
    margin-bottom: 80px;
    @include desktop {
      margin: 80px 0 120px 0;
    }
  }
}
@import '/src/styles/media.scss';

.semenSelect {
  padding: 10px 15px;
  @include laptop {
    padding: 25px 30px;
  }

  h2 {
    font-weight: var(--fw_medium);
    margin-bottom: 15px;
    font-size: var(--fs_18);
    @include laptop {
      font-size: var(--fs_24);
    }
  }
  > ul {
    display: flex;
    flex-direction: column;
    gap: 50px;

    li {
      .semenSelectBtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        @include laptop {
          margin-bottom: 25px;
        }

        button {
          margin-left: auto;
          font-size: var(--fs_12);
          padding: 4px 8px;
          border-radius: 5px;
          background-color: var(--sub_blue2);
          color: var(--white_ff);
          @include laptop {
            font-size: var(--fs_16);
          }
        }
      }
      > p {
        margin-bottom: 10px;
        @include laptop {
          margin-bottom: 25px;
        }
      }
      p {
        position: relative;
        padding-left: 17px;
        font-weight: var(--fw_medium);
        color: var(--sub_blue2);

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          width: 7px;
          height: 7px;
          background-color: var(--sub_blue2);
          border-radius: 1px;
        }
      }
      span {
        display: block;
        margin-top: 10px;
        font-size: var(--fs_12);
        color: var(--main_black);
        @include laptop {
          font-size: var(--fs_14);
        }
      }
      .semenSelectTable {
        table {
          min-width: 600px;
          th {
            border: solid 1px var(--gray_df);
          }
          tbody {
            tr {
              border: solid 1px var(--gray_df);
            }
          }
        }
        .selectTableList {
          min-width: 600px;
          max-height: 500px;
          text-align: center;
          border-left: solid 1px var(--gray_df);
          border-bottom: solid 1px var(--gray_df);
          border-right: solid 1px var(--gray_df);
          overflow-y: scroll;
          &::-webkit-scrollbar{
            width: 2px;
          }
          &::-webkit-scrollbar-thumb {
            width: 2px;
            background-color: var(--gray_df);
          }

          > li {
              &:nth-child(even) {
                background-color: var(--gray_e8);
              }
              ul {
                display: flex;
                border-bottom: solid 1px var(--gray_df);
                li {
                  font-size: var(--fs_12);
                  padding: 0 2px;
                  @include laptop {
                    font-size: var(--fs_14);
                    padding: 6px 2px;
                  }
                  &.numberCon {
                    text-align: end;
                  }
                  &:nth-child(1) {
                    width: 4.2%;
                  }
                  &:nth-child(2) {
                    width: 8.2%;
                  }
                  &:nth-child(3) {
                    width: 8.2%;
                  }
                  &:nth-child(4) {
                    width: 9.8%;
                  }
                  &:nth-child(5) {
                    width: 10.2%;
                  }
                  &:nth-child(6) {
                    width: 14.5%;
                  }
                  &:nth-child(7) {
                    width: 8.2%;
                  }
                  &:nth-child(8) {
                    width: 4%;
                  }
                  &:nth-child(9) {
                    width: 4%;
                  }
                  &:nth-child(10) {
                    width: 10.5%;
                  }
                  &:nth-child(11) {
                    width: 18.2%;
                  }
                }
              }
          }
        }
      }
    }
  }
}
@import '/src/styles/media.scss';

.howtoReport {
  .subTitle {
    @include desktop {
      padding-top: 100px;
      padding-bottom: 15px;
    }
  }
  // font-size: 14px;
  .noticeCon {
    font-size: 14px;
    @include tablet {
      font-size: 18px;
    }
    padding: 20px 10px;
    @include tablet {
      padding: 30px 20px;
    }
    border: 1px solid #ddd;
    border-top: 4px solid var(--main_green);
    margin-bottom: 50px;
    @include tablet {
      margin-bottom: 60px;
    }
  }
  .noticeSecondCon {
    @include tablet {
      margin-bottom: 60px;
    }
    .noticeSecondCon_Title {
      font-size: 14px;
      @include tablet {
        font-size: 20px;
      }
      color: #222222;
      font-weight: 600;
    }
    .noticeSecondCon_Desc {
      font-size: 14px;
      @include tablet {
        font-size: 18px;
      }
      padding: 20px;
      margin: 20px 0;
      background-color: #edffde;
      li {
        position: relative; // Set the position context for the pseudo-element
        margin-left: 20px; // Add margin to make space for the circle
        margin-bottom: 20px;

        &::before {
          content: '';
          position: absolute;
          top: 7px;
          @include tablet {
            top: 10px;
          }
          left: -16px; // Position the circle to the left of the list item
          width: 6px;
          height: 6px;
          background-color: var(--main_green);
          border-radius: 50%;
        }
      }
    }
  }
}

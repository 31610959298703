@font-face{
  font-family: globalFont;
  src: url('/assets/fonts/NotoSansKR-Bold.woff');
  font-weight: 700;
}
@font-face {
  font-family: globalFont;
  src: url('/assets/fonts/NotoSansKR-Medium.woff');
  font-weight: 500;
}
@font-face {
  font-family: globalFont;
  src: url('/assets/fonts/NotoSansKR-Regular.woff');
  font-weight: 400;
}
@font-face{
  font-family: globalFont;
  src: url('/assets/fonts/Roboto-Bold.woff');
  font-weight: 700;
  unicode-range:  U+0041-005A, U+0061-007A;
}
@font-face {
  font-family: globalFont;
  src: url('/assets/fonts/Roboto-Medium.woff');
  font-weight: 500;
  unicode-range:  U+0041-005A, U+0061-007A;
}
@font-face {
  font-family: globalFont;
  src: url('/assets/fonts/Roboto-Regular.woff');
  font-weight: 400;
  unicode-range:  U+0041-005A, U+0061-007A;
}
@font-face {
  font-family: NanumSquare;
  src: url('/assets/fonts/NanumSquareOTF_acB.woff');
  font-weight: 700;
}
@font-face {
  font-family: NanumSquare;
  src: url('/assets/fonts/NanumSquareOTF_acR.woff');
  font-weight: 400;
}

:root{
  // 폰트 굵기
  --fw_normal: 400;
  --fw_medium: 500;
  --fw_bold: 700;

  // 폰트 사이즈
  --fs_48: 2.66em;
  --fs_40: 2.22em;
  --fs_36: 2em;
  --fs_32: 1.77em;
  --fs_28: 1.55em;
  --fs_24: 1.33em;
  --fs_20: 1.11em;
  --fs_18: 1em;
  --fs_16: 0.88em;
  --fs_14: 0.77em;
  --fs_12: 0.66em;
  --fs_10: 0.55em;

  // line height
  --lh_170: 170%;
}

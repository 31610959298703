.oxListPdf {
    position: relative;
    max-width: 1440px;
    min-width: 793px;
    min-height: 1122px;
    margin: 0 auto;
    padding: 40px 30px 80px;
    background-color: var(--white_ff);
  
    h1 {
      text-align: center;
      font-size: var(--fs_28);
      font-weight: var(--fw_normal);
      margin-bottom: 20px;
    }
    
    .pdfTable {
      margin-top: 20px;

      
    tbody {
      tr {
        td {
          &:nth-child(1) {
            width: 5%;
          }
          &:nth-child(2) {
            width: 15%;
          }
          &:nth-child(3) {
            width: 8%;
          }
          &:nth-child(4) {
            width: 8%;
          }
          &:nth-child(5) {
            width: 6%;
          }
          &:nth-child(6) {
            width: 6%;
          }
          &:nth-child(7) {
            width: 6%;
          }
          &:nth-child(8) {
            width: 6%;
          }
          &:nth-child(9) {
            width: 6%;
          }
          &:nth-child(10) {
            width: 6%;
          }
          &:nth-child(11) {
            width: 6%;
          }
        }
      }
    }
    }
  }
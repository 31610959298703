.newGentypePdf {
    position: relative;
    max-width: 1123px;
    min-width: 1123px;
    min-height: 1122px;
    margin: 0 auto;
    padding: 40px 30px 80px;
    background-color: var(--white_ff);
  
    h1 {
      text-align: center;
      font-size: var(--fs_28);
      font-weight: var(--fw_normal);
      margin-bottom: 20px;
    }
   
    .exp {
        text-align:right;
        font-size:var(--fs_14);
    }
        
    .pdfTable {
      margin-top: 20px;
    
      thead {

        .tablestart {

          width:90px;        
          background-image: linear-gradient(to left bottom, transparent calc(50% - 0.8px), #000, transparent calc(50% + 0.8px));
        }

        tr,th {

        border : solid 1px var(--main_black)

        }
      }
    }

    
  }
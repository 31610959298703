@import '/src/styles/media.scss';

.oxSearch {

  .subTitle {
    display: none;
  }
  .oxSearchTab {
    display: flex;
    justify-content: center;
    gap: 14px;
    margin-top: 24px;
    @include tablet {
      justify-content: flex-start;
      margin-top: 40px;
    }
    @include desktop {
      padding-bottom: 30px;
      border-bottom: solid 1px var(--sub_gray);
      max-width: 1440px;
      margin: 40px auto 0;
      padding-left: 0;
    }

    li {
      button,
      a {
        display: inline-block;
        font-weight: var(--fw_bold);
        font-size: var(--fs_12);
        border-radius: 4px;
        padding: 4px 8px;
        color: var(--main_green);
        background-color: var(--white_ff);
        border: solid 1px var(--main_green);
        &:hover,
        &.current {
          color: var(--white_ff);
          background-color: var(--main_green);
        }
        @include tablet {
          font-size: var(--fs_20);
          border: solid 2px var(--main_green);
        }
      }
    }
  }
  .thirdTitle {
    font-size: var(--fs_14);
    font-weight: var(--fw_bold);
    color: var(--main_right_gray);
    margin: 24px 0 16px 0;
    @include tablet {
      font-size: var(--fs_20);
      margin: 40px 0 24px 0;
    }
    @include desktop {
      margin: 80px 0 30px 0;
    }
  }
}
@import '/src/styles/media.scss';

.bestCowTest {
  // 공통
  h5 {
    font-size: var(--fs_14);
    @include tablet {
      font-size: var(--fs_20);
    }
  }

  // 자식
  .bestCowTab {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 24px;
    @include tablet {
      justify-content: flex-start;
      margin-top: 40px;
      padding-left: 20px;
    }
    @include desktop {
      padding-bottom: 30px;
      border-bottom: solid 1px var(--sub_gray);
      max-width: 1440px;
      margin: 40px auto 0;
      padding-left: 0;
    }

    li {
      button {
        padding: 4px 8px;
        font-weight: var(--fw_bold);
        font-size: var(--fs_12);
        color: var(--white_ff);
        background-color: var(--main_green);
        border: solid 1px var(--main_green);
        border-radius: 4px;

        &:hover {
          color: var(--main_green);
          background-color: var(--white_ff);
        }
        @include tablet {
          font-size: var(--fs_20);
          border: solid 2px var(--main_green);
        }
      }
    }
  }
  .bestCowTitle {
    padding: 0 20px;
    margin-bottom: 50px;
    @include tablet {
      margin-bottom: 70px;
    }
    @include desktop {
      margin-top: 100px;
      display: flex;
      gap: 200px;
      max-width: 1440px;
      margin: 100px auto 70px;
    }
    .subTitle {
      @include desktop {
        transform: translateY(10px);
      }
    }
    dl {
      @include desktop {
        width: 820px;
      }
      dt {
        font-weight: var(--fw_bold);
        margin-bottom: 24px;
        @include tablet {
          font-size: var(--fs_40);
          margin-bottom: 36px;
        }
        @include desktop {
          margin-bottom: 30px;
        }
        
        span {
          display: block;
          @include desktop {
            display: inline;
          }
        }
      }
      dd {
        font-size: var(--fs_14);
        line-height: var(--lh_170);
        @include tablet {
          font-size: var(--fs_18);
        }
      }
    }
  }
  .milkCowTest {
    padding: 30px 20px 40px 20px;
    margin-bottom: 80px;
    background-color: var(--gray_fb);
    @include tablet {
      padding: 60px 20px 80px 20px;
      margin-bottom: 120px;
    }
    @include desktop {
      padding: 0;
    }
    .milkCowTestInner {
      @include desktop {
        display: flex; 
        justify-content: space-between;
        max-width: 1440px;
        margin: 0 auto;
      }

      h5 {
        font-size: var(--fs_14);
        @include tablet {
          font-size: var(--fs_20);
        }
        @include desktop {
          padding-top: 50px;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 60px;
        margin-top: 40px;
        @include tablet {
          gap: 120px;
          margin-top: 80px;
        }
        @include desktop {
          width: 1080px;
          margin-top: 0;
          flex-direction: row;
          gap: 0;
        }
  
        li {
          @include tablet {
            display: flex;
            justify-content: space-around;
            align-items: center;
          }
          @include desktop {
            width: 33.33%;
            flex-direction: column;
            justify-content: flex-start;
            gap: 10px;
            padding: 80px 10px 45px 10px;
            border-left: solid 1px var(--sub_gray);
            &:last-child {
              border-right: solid 1px var(--sub_gray);
            }
            &:nth-child(2) {
              .imgArea {
                img {
                  margin-bottom: 22px;
                }
              }
            }
          }
          .imgArea {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            @include tablet {
              gap: 50px;
            }
  
            span {
              font-size: var(--fs_20);
              font-weight: var(--fw_medium);
            }
          }
          p {
            margin-top: 16px;
            font-size: var(--fs_14);
            color: var(--main_gray);
            text-align: center;
            @include tablet {
              font-size: var(--fs_16);
              width: 50%;
            }
            @include desktop {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .businessOverview {
    padding: 0 20px;
    margin-bottom: 80px;
    @include tablet {
      margin-bottom: 120px;
    }
    @include desktop {
      max-width: 1440px;
      margin: 0 auto 120px;
    }

    .businessOverviewTitle {
      margin-bottom: 30px;
      @include tablet {
        margin-bottom: 64px;
      }

      dt {
        font-weight: var(--fw_bold);
        margin: 24px 0;
        @include tablet {
          margin: 36px 0;
          font-size: var(--fs_40)
        }
        span {
          display: block;
          @include tablet {
            display: inline;
          }
        }
      }
      dd {
        font-size: var(--fs_14);
        line-height: var(--lh_170);
        @include tablet {
          font-size: var(--fs_18);
        }
        @include desktop {
          width: 50%;
        }
      }
    }
    .pageGuide {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @include tablet {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
      }
      @include desktop {
        justify-content: flex-start;
      }

      li {
        padding: 25px 30px;
        margin: 0 10px;
        text-align: center;
        border: solid 1px var(--gray_de);
        border-radius: 5px;
        @include tablet {
          width: 35%;
          margin: 0;
        }
        @include desktop {
          width: 250px;
        }

        dt {
          margin: 20px 0;
          font-size: var(--fs_20);
          font-weight: var(--fw_medium);
        }
        dd {
          font-size: var(--fs_14);
          color: var(--main_gray);
        }
        a {
          display: inline-block;
          margin-top: 20px;
          font-size: var(--fs_14);
          color: var(--white_ff);
          padding: 5px 10px;
          background-color: var(--main_green);
          border: solid 1px transparent;
          border-radius: 5px;
          &:hover {
            color: var(--main_gray);
            background-color: var(--white_ff);
            border: solid 1px var(--gray_de);
          }
        }
      }
    }
  }
  .howtoTest {
    padding: 0 20px;
    margin-bottom: 80px;
    @include desktop {
      max-width: 1440px;
      margin: 0 auto 80px;
      padding: 0;
    }

    .howtoTestTitle {
      margin: 24px 0 40px 0;
      @include tablet {
        margin: 36px 0 80px 0;
      }

      dt {
        font-weight: var(--fw_bold);
        margin-bottom: 24px;
        @include tablet {
          font-size: var(--fs_40);
          margin-bottom: 36px;
        }
        span{
          display: block;
          @include tablet {
            display: inline;
          }
        }
      }
      dd {
        font-size: var(--fs_14);
        line-height: var(--lh_170);
        @include tablet {
          font-size: var(--fs_18);
        }
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      @include desktop {
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0;
        row-gap: 60px;
      }

      li {
        @include tablet {
          width: 80%;
          display: flex;
          justify-content: center;
          gap: 40px;
        }
        @include desktop {
          width: 48%;
          margin: 0;
        }
        img {
          width: 100%;
          @include tablet {
            width: 55%;
          }
        }
        dl {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 16px;
          text-align: center;
          @include tablet {
            width: 300px;
            text-align: start;
            margin-top: 0;
            gap: 0;
            justify-content: center;
          }

          dt {
            font-size: var(--fs_16);
            font-weight: var(--fw_medium);
            margin-bottom: 8px;
            @include tablet {
              font-size: var(--fs_20);
              margin-bottom: 20px;
            }
          }
          dd {
            font-size: var(--fs_14);
            line-height: var(--lh_170);
            color: var(--main_gray);
            @include tablet {
              font-size: var(--fs_18);
            }
          }
        }
      }
    }
  }
  .cowTestJoin {
    padding: 0 20px;
    margin-bottom: 80px;
    @include desktop {
      padding: 0;
      max-width: 1440px;
      margin: 0 auto 80px;
    }
    > p {
      font-weight: var(--fw_bold);
      margin: 32px 0;
      @include tablet {
        font-size: var(--fs_40);
      }
      span {
        display: block;
      }
    }
    .benefit {
      @include desktop {
        display: flex;
        justify-content: space-between;
      }
      strong {
        font-weight: var(--fw_bold);
        color: var(--main_green);
        @include tablet {
          display: block;
          font-size: var(--fs_32);
          margin-bottom: 10px;
        }
      }
      ul{
        @include tablet {
          width: 60%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 30px;
        }
        
        li{
          margin-top: 16px;
          font-size: var(--fs_14);
          @include tablet {
            font-size: var(--fs_18);
            margin-top: 0;
          }
  
          p {
            color: var(--main_green);
            font-weight: var(--fw_bold);
          }
          span {
            display: block;
            margin-top: 4px;
          }
        }
      } 
    }
  }
  .howtoCowTestJoin {
    padding: 0 20px;
    @include desktop {
      max-width: 1440px;
      padding: 0;
      margin: 0 auto 120px;
      display: flex;
      justify-content: space-between;
    }
    h5 {
      font-size: var(--fs_18);
      color: var(--main_green);
      @include tablet {
        font-size: var(--fs_32);
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 64px;
      margin: 24px 0 120px 0;
      @include desktop {
        width: 60%;
        margin: 0;
      }
    
      li {
        @include tablet {
          width: 100%;
          display: flex;
          gap: 24px;
        }
        @include desktop {
          justify-content: space-between;
        }
        .imgArea {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          padding: 24px 0;
          margin-bottom: 16px;
          background-color: var(--gray_fb);
          border-radius: 16px;
          @include tablet {
            flex: 1;
            margin-bottom: 0;
          }
          @include desktop {
            max-width: 220px;
          }

          span {
            font-weight: var(--fw_bold);
            color: var(--main_gray);
          }
        }
        .textArea {
          @include tablet {
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          @include tablet {
            width: 60%;
          }

          p {
            font-size: var(--fs_14);
            line-height: var(--lh_170);
            margin-top: 4px;
            @include tablet {
              margin-top: 0;
            }
            @include desktop {
              font-size: var(--fs_18);
            }
          }
        }
      }
    }
  }
  .cooperPoint {
    @include desktop {
      max-width: 1440px;
      margin: 0 auto;
    }
  }
}
@import '/src/styles/media.scss';

.cow-info {
  margin-bottom: 30px;
  @include tablet {
    margin-bottom: 30px;
  }
  @include desktop {
    padding: 0 40px;
    border: 1px solid #ddd;
    border-radius: 16px;
  }
  p {
    display: block; // 특정 너비 내에 텍스트를 포함하도록 블록으로 설정
    max-width: 100%; // 필요에 따라 이 너비를 조정
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cow-header {
    display: flex;
    align-items: center;
    @include desktop {
      padding-top: 30px;
      margin-bottom: 10px;
    }

    .cow-name {
      @include tablet {
        font-size: 28px;
      }
      @include desktop {
        font-size: 32px;
      }
      margin-right: 10px; // 이름과 이미지 사이 간격 조절
      overflow: hidden; // 내용이 넘칠 경우 숨김 처리
      white-space: nowrap; // 텍스트를 한 줄로 처리
      text-overflow: ellipsis; // 넘치는 텍스트에 말줄임표 적용
    }

    .country-flag {
      width: 30px; // 이미지 크기 조절
      height: auto;
    }
  }

  .info {
    border-bottom: 1px solid #dddddd;

    p {
      margin-bottom: 10px;
      @include desktop {
        margin-bottom: 15px;
      }
      color: #555555;
      font-size: 14px;
      @include tablet {
        font-size: 18px;
      }

      strong {
        font-weight: bold;
        @include tablet {
          font-size: 18px;
        }
      }
    }
  }
  .cowImgBox {
    max-height: 100%;
    overflow: hidden; // 이 설정은 최대 높이를 넘는 내용을 숨깁니다.
    .cow-images {
      max-height: 60px;
      @include tablet {
        max-height: 190px;
      }
      @include desktop {
        max-height: 147px;
      }
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      @include tablet {
        padding: 0 60px;
        margin-bottom: 30px;
      }
      justify-content: space-between;
      margin-top: 20px;
      .image-container {
        flex-basis: calc(50% - 10px); // 두 이미지 사이의 간격을 고려하여 계산
        @include tablet {
          border: 1px solid #dddddd;
          border-radius: 16px;
        }

        //   height: 200px; // 또는 적절한 높이로 설정
        position: relative;
        overflow: hidden; // 이미지가 컨테이너를 벗어나면 숨김
        .defaultCow {
          @include tablet {
            padding: 30px;
          }
          @include desktop {
            padding: 20px;
          }
        }

        img {
          width: 100%;
          object-fit: cover; // 이미지가 컨테이너를 꽉 채우도록 조정
        }

        .image-text {
          position: absolute;
          bottom: 5px; // 이미지 하단에서 5px
          left: 0;
          width: 100%;
          text-align: center;
          color: white;
          background-color: rgba(0, 0, 0, 0.5); // 배경 반투명 검정색
          padding: 5px 0;
          font-size: 14px; // 텍스트 크기 조절 가능
        }
      }
    }
  }
}

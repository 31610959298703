@import '/src/styles/media.scss';

.alertCon {
  z-index: var(--10);
  padding: 0 20px;
  margin-bottom: 40px;
  @include tablet {
    margin-bottom: 60px;
  }
  @include desktop {
    margin-bottom: 0;
  }

  .alert-zone-container {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #dddddd;
    border-radius: 16px;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    overflow: hidden;

    @include tablet {
      max-width: 100%;
      height: auto;
    }

    h2 {
      font-size: 16px;
      padding-bottom: 10px;
      @include tablet {
        font-size: 24px;
      }
    }

    .alert-zone-slides {
      display: flex;
      transition: transform 0.5s ease-in-out;
      width: 100%; // Necessary for sliding effect.
    }

    .alert-slide {
      min-width: 100%;
      transition: opacity 0.5s ease-in-out;
      opacity: 0;

      &:not(.active) {
        display: none;
      }

      &.active {
        opacity: 1;
        display: block;
      }

      img {
        height: 230px;
        width: 100%;
        @include tablet {
          width: 300px;
          height: 300px;
        }
        @include tablet {
          width: auto;
        }
      }
    }

    .alert-zone-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fff;
      border: none;
      padding: 10px;
      cursor: pointer;
      z-index: 10;

      svg {
        fill: currentColor;
        width: 24px;
        height: 24px;
      }

      &.prev-btn {
        left: 10px;
      }

      &.next-btn {
        right: 10px;
        @include tablet {
          right: auto;
          left: 306px;
        }
      }
    }
  }
}

@import '/src/styles/media.scss';

.info {
  .subTitle {
    margin: 30px 0 15px 0;
    font-size: var(--fs_18);
    font-weight: var(--fw_bold);
    color: var(--main_gray);
    
    @include tablet {
      font-size: var(--fs_24);
      margin: 45px 0 30px 0;
    }
    @include desktop {
      margin: 60px 0 40px 0;
      font-size: var(--fs_32);
    }
  }
}
.newBmonthcompPdf {
  position: relative;
  max-width: 1440px;
  min-width: 793px;
  min-height: 1122px;
  margin: 0 auto;
  padding: 40px 30px 80px;
  background-color: var(--white_ff);

  h1 {
    text-align: center;
    font-size: var(--fs_28);
    font-weight: var(--fw_normal);
    margin-bottom: 20px;
  }
  .newBmonthcompInfo {
    display: flex;
    gap: 12px;
    border-top: solid 1px var(--main_blue);
    border-bottom: solid 1px var(--main_blue);
    padding: 10px 20px;
    background-color: rgba(52, 73, 94, 0.1);
  }
  .pdfTable {
    margin-top: 10px;
    thead {
      tr {
        &:nth-child(2) {
          border-top: solid 1px var(--main_blue);
        }

        th {
          &:first-child {
            border-left: solid 1px var(--main_blue);
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-child(1) {
            width: 5%;
          }
          &:nth-child(2) {
            width: 4%;
          }
          &:nth-child(3) {
            width: 5%;
          }
          &:nth-child(4) {
            width: 5%;
          }
          &:nth-child(5) {
            width: 5%;
          }
          &:nth-child(6) {
            width: 6%;
          }
          &:nth-child(7) {
            width: 6%;
          }
          &:nth-child(8) {
            width: 6%;
          }
          &:nth-child(9) {
            width: 6%;
          }
          &:nth-child(10) {
            width: 6%;
          }
          &:nth-child(11) {
            width: 6%;
          }
          &:nth-child(12) {
            width: 6%;
          }
          &:nth-child(13) {
            width: 6%;
          }
          &:nth-child(14) {
            width: 6%;
          }
          &:nth-child(15) {
            width: 6%;
          }
          &:nth-child(16) {
            width: 8%;
          }
          &:nth-child(17) {
            width: 8%;
          }
        }
      }
    }
  }
}
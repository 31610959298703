@import './media.scss';

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video , input {
	margin: 0;
	padding: 0;
	border: 0;
  box-sizing: border-box;
}
html {
  font-size: 18px;
}
body {
  position: relative;
  font-family: globalFont;
  font-size: 18px;
  color: var(--main_black);
}
ol, ul {
	list-style: none;
}
img {
  border: none;
  vertical-align: top;
  max-width: 100%;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
    display: block;
    text-decoration: none;
    color: var(--main_black);
}
button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    font-family: globalFont;
}
input {
    outline: none;
    border: none;
}
address {
  font-style: normal;
}
.hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
    overflow: hidden;
    margin: -1px;
    clip: rect(0,0,0,0);
    clip-path: inset(50%);
}

// 서브페이지 
.subCon{
  padding: 0 20px;
  @include desktop {
    padding: 0;
    margin: 0 auto;
    max-width: 1440px;
  }
  
  &.pullContent {
    padding: 0;
    margin: 0;
    max-width: 100%;
  }
}

// 테이블
table {
  width: 100%;
}
.tablebox {
  white-space: nowrap;
  overflow-x: auto;
}
.tablebox table {
  width: 100%;
  border : solid 1px var(--sub_gray);
  text-align: center;
}
.tablebox table th {
  border: solid 1px var(--main_right_gray);
  background-color: var(--main_green);
  color: var(--gray_f8);
  padding: 4px;
  font-size: var(--fs_14);
  font-weight: var(--fw_bold);
  @include tablet {
    font-size: var(--fs_16);
  }
  @include desktop {
    font-size: var(--fs_18);
  }
}
.tablebox td {
  border: solid 1px var(--main_right_gray);
  padding: 3px;
  font-size: var(--fs_12);
  @include tablet {
    font-size: var(--fs_14);
  }
  @include desktop {
    font-size: var(--fs_16);
  }
}
.tablebox tbody tr {
  &:nth-child(odd) {
    background-color: var(--gray_e8);
  }
}
#skipnav {
    position: relative;
    z-index: 9999;
    
    a {
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      padding: 8px 0;
      background: #333;
      color: #fff;
      text-align: center;
      
      &:focus {
        top: 0;
      }
    }
  }
.monthCode {
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: var(--fs_16);
  }
  input {
    height: 100%;
    width: 55px;
    border: solid 1px var(--gray_df);
    border-radius: 4px;
  }
  button {
    background-color: var(--main_green);
    color: var(--white_ff);
    padding: 2px 4px;
    border-radius: 4px;
  }
}
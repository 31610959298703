.login-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f3640;
}

.login-container {
  position: relative;
  width: 400px;
  box-sizing: border-box;
}

.login-box {
  background: white;
  padding: 40px;
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.login-title {
  margin-bottom: 40px;
  font-family: 'Nanum Square', sans-serif;
  font-size: 40px;
  text-align: center;
  line-height: 100%;
  color: #333;
  font-weight: 600;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group {
  position: relative;
  margin-bottom: 8px;
}

.form-label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.form-input {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #ef554d;
  }

  &::placeholder {
    color: #999;
  }
}

.login-button {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  background-color: #ef554d;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken(#ef554d, 5%);
  }

  &:active {
    background-color: darken(#ef554d, 10%);
  }
}

.error-message {
  display: block;
  margin-top: 5px;
  color: #ef554d;
  font-size: 12px;
}

// 반응형 디자인
@media (max-width: 480px) {
  .login-container {
    width: 90%;
  }

  .login-box {
    padding: 30px 20px;
  }

  .login-title {
    font-size: 32px;
    margin-bottom: 30px;
  }

  .form-input {
    height: 36px;
  }

  .login-button {
    height: 45px;
    font-size: 15px;
  }
}

@import '/src/styles/media.scss';

.footer {
  background-color: var(--main_blue);
  padding: 30px 0;

  .footerInner {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
    @include desktop {
      flex-direction: row;
      gap: 70px;
    }

    .footerLogo {
      display: none;
      @include desktop {
        display: block;
      }
    }

    address {
      font-size: var(--fs_14);
      color: var(--sub_gray);
      @include desktop {
        flex: 1;
      }

      ul {
        display: grid;
        grid-template-areas:
          'a a a a'
          'b b c c';
        gap: 5px;
        @include tablet {
          display: flex;
          gap: 15px;
        }

        li {
          
          &:nth-child(1){
            grid-area: a;
          }
          &:nth-child(2){
            grid-area: b;
          }
          &:nth-child(3){
            grid-area: c;
          }

          a {
            display: inline-block;
            color: var(--sub_gray);
          }
        }
      }
      p {
        text-align: center;
        @include desktop {
          text-align: start;
        };
      }
    }

    .corp {
      display: flex;
      gap: 20px;
      font-weight: var(--fw_bold);
      font-size: var(--fs_16);
      
      li {

        &:first-child {
          position: relative;
          
          &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 60%;
            top: 50%;
            transform: translateY(-50%);
            right: -10px;
            background-color: var(--white_ff);
          }
        }
        a {
          color: var(--white_ff);
        }
      }
    }
  }
}
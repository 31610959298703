@import '/src/styles/media.scss';

.qnaTechnicalInfo {
  &.subCon {
    margin-bottom: 40px;
    @include tablet {
      margin-bottom: 60px;
    }
    @include desktop {
      margin-bottom: 80px;
    }
  }

  .subTitle {
    display: none;
  }
  .oxDataTab {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 24px;
    @include tablet {
      justify-content: flex-start;
      margin-top: 40px;
      gap: 16px;
    }
    @include desktop {
      max-width: 1440px;
      margin: 40px auto 0;
      padding-left: 0;
    }

    li {
      a{
        font-weight: var(--fw_bold);
        font-size: var(--fs_12);
        color: var(--gray_e6);
        padding: 3px;
        border-bottom: solid 2px transparent;
        &:hover,
        &.current {
          color: var(--main_gray);
          border-bottom: solid 2px var(--main_green);
          @include tablet {
            border-bottom: solid 4px var(--main_green);
          }
        }
        @include tablet {
          border-bottom: solid 4px transparent;
          font-size: var(--fs_20);
          padding: 12px;
        }
        @include desktop {
          color: var(--gray_de);
        }
      }
    }
  }
  .qnaHomeTab {
    display: grid;
    grid-template-columns:  repeat(2, 1fr);
    margin: 20px 0;
    @include tablet {
      grid-template-columns: repeat(3 , 1fr);
      margin: 30px 0;
    }
    @include desktop {
      grid-template-columns: repeat(4, 1fr);
      margin: 40px 0;
    }

    li {
      border: solid 1px var(--sub_gray);
      text-align: center;
      
      button {
        width: 100%;
        padding: 8px 0;
        &.current {
          background-color: var(--main_green);
          color: var(--white_ff);
        }

        @include tablet {
          font-size: var(--fs_16);
        }
        @include desktop {
          font-size: var(--fs_18);
        }
      }
    }
  }
  .qnaHomeList {
    display: flex;
    flex-direction: column;
    gap: 12px;

    li {
      a {
        position: relative;
        padding-left: 12px;
        font-size: var(--fs_14);
        @include desktop {
          font-size: var(--fs_18);
          padding-left: 20px;
        }
        &:hover {
          text-decoration: underline;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 8px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--main_green);
          @include desktop {
            top: 10px;
            width: 8px;
            height: 8px;
          }

        }
      }
    }
  }
}
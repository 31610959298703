@import '/src/styles/media.scss';

.publication {
  
  .subTitle {
    display: none;
  }
  .publicationTab {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 24px;
    @include tablet {
      justify-content: flex-start;
      margin-top: 40px;
      gap: 16px;
    }
    @include desktop {
      max-width: 1440px;
      margin: 40px auto 0;
      padding-left: 0;
    }

    li {
      button{
        font-weight: var(--fw_bold);
        font-size: var(--fs_12);
        color: var(--gray_e6);
        padding: 3px;
        border-bottom: solid 2px transparent;
        &:hover,
        &.current {
          color: var(--main_gray);
          border-bottom: solid 2px var(--main_green);
          @include tablet {
            border-bottom: solid 4px var(--main_green);
          }
        }
        @include tablet {
          border-bottom: solid 4px transparent;
          font-size: var(--fs_20);
          padding: 12px;
        }
        @include desktop {
          color: var(--gray_de);
        }
      }
    }
  }
  .adobeDownload {
    margin: 24px 0 40px 0;
    padding: 28px 8px;
    background-color: var(--gray_fb);
    border: solid 1px var(--gray_dd);
    font-size: var(--fs_14);
    @include tablet {
      font-size: var(--fs_18);
      padding: 28px 50px;
    }
    @include desktop {
      margin: 50px 0 60px 0;
    }
    
    p {
      font-weight: var(--fw_bold);
    }
    span {
      display: block;
      color: var(--main_gray);
    } 
    a {
      display: inline-block;
      position: relative;
      font-size: var(--fs_12);
      font-weight: var(--fw_medium);
      margin-top: 20px;
      padding: 8px 10px 8px 40px;
      color: var(--main_right_gray);
      border: solid 1px var(--gray_fb);
      background-color: var(--white_ff);
      box-shadow: 1px 1px 4px 0 rgba(0,0,0,.25);
    
      &::before {
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background: url('/assets/svgs/containers/data/publication/adobe_icon.svg') no-repeat center/cover;
      }
    }
  }
}
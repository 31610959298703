@import '/src/styles/media.scss';

.noticeCon {
  margin-top: 40px;
  padding: 0 20px;
  @include tablet {
    margin-bottom: 80px;
  }
  @include desktop {
    margin-top: 0;
    flex: 0 0 auto;
    padding: 0;
    width: 40%;
    margin-right: 2.7rem;
    border-radius: 1rem;
  }
  margin-bottom: 40px;
}

.rowCon {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }
}

.notice-container {
  @include desktop {
    height: 100%;
  }
  border: 1px solid #ddd;
  border-radius: 16px;
  padding: 20px 15px;
  h2 {
    font-size: 16px;
    margin-bottom: 10px;
    @include tablet {
      font-size: 24px;
    }
    @include desktop {
      font-size: 32px;
    }
  }
  @include tablet {
    padding: 30px 40px;
  }

  .notice-tabs {
    display: flex;
    justify-content: space-between;
    @include tablet {
      gap: 30px;
    }

    .notice-tab {
      font-weight: bold;
      color: #555555;

      &.active {
        color: #34495e;
        transition: ease-in-out 300ms;
      }
    }
  }

  .notice-list {
    max-width: 700px;
    font-size: 14px;

    .notice-item {
      &:nth-child(5) {
        border: none;
      }
      width: 100%;
      box-sizing: border-box;
      padding: 20px 0;
      border-bottom: 1px dashed rgb(221, 221, 221);

      a {
        display: block; // a 태그를 블록 요소로 만듭니다.
        overflow: hidden; // 내용이 넘칠 경우 숨깁니다.
        white-space: nowrap; // 텍스트를 한 줄로 만듭니다.
        text-overflow: ellipsis; // 넘치는 텍스트에 말줄임표를 적용합니다.
        width: 100%; // 너비를 100%로 설정하여 부모의 너비를 꽉 채우게 합니다.
      }

      @include desktop {
        font-size: 17px;
        padding: 25px 0;
      }

      .icon-news {
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        background-color: green;
        color: white;
        border-radius: 17px;
        padding: 6px 15px;
        margin-right: 10px;
      }
    }
  }
}

@import '/src/styles/media.scss';

.email.subCon {

   
   @include desktop {
    
   padding-top:100px;

   }


.email_notice {

    overflow:hidden;
    display:flex;
    align-items:center;
    padding:30px 10px;
    background-color:var(--sub_gray);
    border:1px solid var(--gray_de);
    font-size:var(--fs_14);
    font-weight:var(--fw_bold);
    line-height:170%;

    @include desktop {
        font-size:var(--fs_18);

        margin-top:30px;
    }
    

    img {display:none;
    
    
    @include tablet {
        display:block;
        float:left;
        padding-left:30px;
        padding-right:50px;
    }
    
    }

    p {
        
        
        @include tablet{
            float:left;
            padding:10px 20px 10px 50px;
            border-left:1px solid var(--gray_de);
        }

        @include desktop {

           padding-right:315px;
            
        }

    }


}

.email_contents {

        padding-top:30px;
        padding-right:20px;
        padding-bottom:80px;
        line-height:170%;


    h2 {

        font-size:var(--fs_18);
        padding-bottom:15px;
        
        @include tablet {

            background:url(/assets/svgs/containers/guide/personalInfo/dot.svg);
            background-repeat:no-repeat;
            background-position:0% 5px;
            padding-left:25px;
        }

        @include desktop {
            font-size:var(--fs_20);
        }
    }

    p {

        padding-bottom:30px;
        
    }


    .line3 {
        list-style-type:disc;
        padding-top:10px;
        padding-bottom:30px;

        li {
            margin-left:40px;
            
        }
    }

    
            
        
    
}

}
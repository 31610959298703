@import '/src/styles/media.scss';

.descendantCow {
  .subTitle {
    padding-left: 20px;
    @include desktop {
      max-width: 1440px;
      padding-left: 0;
      margin: 100px auto 30px;
    }
  }
  .descendantTab {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 24px;
    @include tablet {
      justify-content: flex-start;
      margin-top: 40px;
      padding-left: 20px;
    }
    @include desktop {
      padding-bottom: 30px;
      border-bottom: solid 1px var(--sub_gray);
      max-width: 1440px;
      margin: 40px auto 0;
      padding-left: 0;
    }

    li {
      button {
        padding: 4px 8px;
        font-weight: var(--fw_bold);
        font-size: var(--fs_12);
        color: var(--white_ff);
        background-color: var(--main_green);
        border: solid 1px var(--main_green);
        border-radius: 4px;
        &:hover {
          color: var(--main_green);
          background-color: var(--white_ff);
        }
        @include tablet {
          font-size: var(--fs_20);
          border: solid 2px var(--main_green);
        }
      }
    }
  }
  .descendantCowTitle {
    padding: 0 20px;
    margin-bottom: 80px;
    @include tablet {
      margin-bottom: 120px;
    }
    @include desktop {
      padding: 0;
      max-width: 1440px;
      margin: 0 auto 100px;
    }
    strong {
      @include tablet {
        font-size: var(--fs_40);
      }
    }
    img {
      width: 100%;
      margin: 30px 0 24px 0;
      @include tablet {
        margin: 30px 0 40px 0;
      }
    }
    dl {
      dt {
        font-size: var(--fs_16);
        font-weight: var(--fw_bold);
        margin-bottom: 12px;
        @include tablet {
          font-size: var(--fs_20);
          margin-bottom: 40px;
        }
        @include desktop {
          margin-bottom: 0;
        }
      }
      dd {
        font-size: var(--fs_14);
        line-height: var(--lh_170);
        @include tablet {
          font-size: var(--fs_18);
        }
        @include desktop {
          width: 1160px;
          margin-left: auto;
          transform: translateY(-35px);
        }
      }
    }
  }
  .oxSelection {
    margin-bottom: 70px;
    padding: 0 20px;
    @include tablet {
      margin-bottom: 120px;
    }
    @include desktop {
      max-width: 1440px;
      margin: 0 auto;
      text-align: end;
    }

    h5 {
      font-size: var(--fs_16);
      font-weight: var(--fw_bold);
      margin-bottom: 30px;
      @include tablet {
        margin-bottom: 50px;
      }
      @include desktop {
        font-size: var(--fs_20);
        margin-bottom: 0;
        text-align: start;
      }
    }
    img {
      width: 100%;
      max-width: 1000px;
      @include desktop {
        transform: translateY(-40px);
      }
    }
  }
  .freshFarm {
    margin-bottom: 120px;
    @include desktop {
      max-width: 1440px;
      margin: 0 auto;
    }
  }
  .freshFarmJoinSequnce {
    text-align: center;
    margin-top: 32px;
    @include desktop {
      margin-top: 60px;
    }

    p {
      font-size: var(--fs_20);
      font-weight: var(--fw_bold);
      margin-bottom: 30px;
      color: var(--main_green);
      @include desktop {
        text-align: start;
      }
    }
    img {
      width: 50%;
      max-width: 280px;
      @include desktop {
        width: 90%;
        max-width: none;
      }
    }
  }
  .descendantFarm {
    margin-bottom: 80px;
    @include desktop {
      max-width: 1440px;
      margin: 100px auto;
    }

    .dlBox {
      @include desktop {
        display: flex;
        gap: 10px;
        margin: 50px 0 60px 0;
      }
      dl {
        @include desktop {
          width: 100%;
          margin: 0;
          transform: none;
        }
      }
    }
    .descendantFarmJoinSequnce {
      text-align: center;

      p {
        font-size: var(--fs_20);
        font-weight: var(--fw_bold);
        margin-bottom: 30px;
        color: var(--main_green);
        @include desktop {
          text-align: start;
        }
      }
      .imgBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        @include desktop {
          flex-direction: row;
          justify-content: center;
          gap: 100px;
        }
        img {
          width: 40%;
          max-width: 280px;
          @include tablet {
            width: 100%;
            max-width: 726px;
            padding-top: 20px;
          }
        }
      }
    }
  }

  // 공통
  .freshFarm,
  .descendantFarm {
    .freshFarmInner,
    .descendantFarmInner {
      padding: 0 20px;
      @include desktop {
        padding: 0;
      }
      h5 {
        font-size: var(--fs_14);
        margin-bottom: 20px;
        @include tablet {
          font-size: var(--fs_20);
          margin-bottom: 40px;
        }
      }
      p {
        font-weight: var(--fw_bold);
        @include tablet {
          font-size: var(--fs_40);
        }
        @include desktop {
          width: 1012px;
        }
      }
      img {
        width: 100%;
        margin: 30px 0 24px 0;
        @include tablet {
          margin: 40px 0;
        }
      }
      strong {
        display: block;
        font-size: var(--fs_14);
        margin-bottom: 40px;
        @include tablet {
          font-size: var(--fs_20);
        }
        @include desktop {
          margin-bottom: 0;
        }
      }
    }
    dl {
      margin-bottom: 50px;
      @include desktop {
        max-width: 960px;
        margin-left: auto;
        transform: translateY(-30px);
      }

      dt {
        font-size: var(--fs_20);
        font-weight: var(--fw_bold);
        color: var(--main_green);
        padding-left: 20px;
        margin-bottom: 32px;
        @include desktop {
          padding-left: 0;
        }
      }
      dd {
        background-color: var(--gray_fb);
        font-size: var(--fs_14);
        line-height: var(--lh_170);
        padding: 0 20px;
        @include tablet {
          font-size: var(--fs_18);
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 20px 0;

          li {
            position: relative;
            padding-left: 15px;
            &::before {
              content: '';
              position: absolute;
              top: 10px;
              left: 0;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: var(--main_green);
              @include tablet {
                top: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@import '/src/styles/media.scss';

.subTitle {
  margin: 24px 0;
  color: var(--main_right_gray);
  font-size: var(--fs_14);
  @include tablet {
    margin: 46px 0;
    font-size: var(--fs_20);
  }
  @include desktop {
    margin: 0;
  }
}
.error-boundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;

  h1 {
    color: #dc3545;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    color: #6c757d;
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  button {
    padding: 12px 24px;
    font-size: 1rem;
    color: #fff;
    background-color: #0d6efd;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #0b5ed7;
    }

    &:active {
      background-color: #0a58ca;
    }
  }
}

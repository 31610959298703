@import '/src/styles/media.scss';
.businessCon {
  @include tablet {
    margin: 80px 0;
  }
  @include desktop {
    position: relative;
    // margin-bottom: 150px;
  }
  .row-con {
    @include desktop {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 150px;
      .info-group {
        padding-left: 236px;
        width: 50%;
      }
    }
  }
  .bgCon {
    padding: 20px;
    background-color: #edeff4;
  }
  margin-bottom: 30px;

  .business-slider {
    @include desktop {
      padding: 0;
    }
    // @include tablet {
    //   padding: 0 20px;
    // }
    .business-info {
      text-align: center;
      @include tablet {
        text-align: start;
      }
      margin: 20px;
      @include desktop {
        margin: 0;
      }

      h2 {
        padding-top: 60px;
        margin-bottom: 15px;
        font-size: 16px;
        padding-bottom: 10px;
        @include tablet {
          font-size: 24px;
        }
        @include desktop {
          font-size: 32px;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px dashed #ddd;
        }

        span {
          font-weight: 500;
        }
      }

      h3 {
        font-size: 14px;
        @include tablet {
          font-size: 18px;
        }
        margin-bottom: 20px;
        @include desktop {
          margin-bottom: 20px;
          font-size: 24px;
        }
      }

      p {
        margin-bottom: 20px;
        font-size: 14px;
        padding: 0 18px;
        @include tablet {
          padding: 0;
        }
        @include desktop {
          margin-bottom: 20px;
          padding-right: 95px;
          font-size: 16px;
        }
      }

      .info-list {
        list-style: none;
        text-align: left;
        padding: 0;
        margin-bottom: 20px;
        position: relative;

        .info-item {
          cursor: pointer;
          // display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          transition:
            background-color 0.3s,
            color 0.3s;
          margin-bottom: 10px;
          position: relative;

          &:hover {
            // background-color: #3498db;
            color: #ffffff;

            .info-plus {
              transform: translateY(-50%) rotate(90deg);
              background-color: #34495e;
            }

            span {
              &::after {
                width: 100%;
              }
            }
          }

          .info-link {
            position: relative;
            display: inline-block;

            span {
              margin-left: 50px;
              position: relative;
              transition: color 0.3s;
              font-weight: bold;
              @include desktop {
                font-size: 18px;
              }

              &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 2px;
                bottom: -5px;
                left: 0;
                background-color: #4e995a;
                transition: width 0.3s ease-in-out;
              }
            }

            &:hover span::after {
              width: 100%;
            }
          }

          .info-plus {
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            background-color: #222222;
            box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            transition:
              transform 0.3s,
              background-color 0.3s;

            &::before,
            &::after {
              content: '';
              position: absolute;
              background-color: currentColor;
            }

            &::before {
              height: 12px;
              // height: 100%;
              width: 2px;
              left: 50%;
              color: #fff;
              transform: translateX(-50%);
            }

            &::after {
              color: #fff;
              width: 12px;
              // width: 100%;
              height: 2px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      @include tablet {
        font-size: 15px;
      }
      @include desktop {
        font-size: 18px;
      }
      margin-bottom: 10px;
      position: relative; // 화살표를 절대적 위치로 설정하기 위한 기준점
      &:hover {
        // color: #34495e;
        // font-weight: bold;
      }

      .info-arrow {
        position: absolute;
        right: 0; // 오른쪽 끝에 배치
        top: 50%; // 상단에서 50% 위치에 배치

        @include desktop {
          position: absolute;
          top: 50%;
          transform: none; // 화살표를 수직 중앙에 위치시킴
        }
      }
    }
  }

  .image-container {
    display: flex;
    overflow: hidden;
    justify-content: center;
    margin-bottom: 20px;
    transition: transform 0.5s ease-in-out; // 부드러운 전환 효과 추가
    &.slide-animation {
      transform: translateX(-100%); // 왼쪽으로 슬라이드
    }

    img {
      width: 100%;
      height: auto;
      max-width: 140px;
      @include tablet {
        max-width: none;
      }
      margin: 0 10px;
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;

    button {
      background: none;
      border: none;
      margin: 0 10px;
      cursor: pointer;
    }
  }
}
.shape {
  display: none;
  @include desktop {
    display: block;
    position: absolute;
    bottom: 82px;
    left: -113px;
    // display: none;
  }
}

// box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,.1);
// box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,.1);

@import 'styles/media.scss';

.dciInfo {
  background-color: var(--gray_f8);
  height: calc(100vh - 22px);
  overflow-x: scroll;

  .dciInfoInner {
    padding: 20px;
    text-align: center;
    min-width: 300px;

    .dciInfoTitle {
      font-size: var(--fs_24);
      font-weight: var(--fw_bold);
    }
    .dciInfoDesc {
      font-size: var(--fs_16);
      margin: 10px 0;
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;
      @include tablet {
        flex-direction: row
      };
      @include desktop {
        gap: 20px;
      }
  
      li {
        display: flex;
        align-items: center;
        gap: 10px;
        @include desktop {
          gap: 20px;
        }
        input {
          flex: 1;
          width: 100%;
          font-size: var(--fs_14);
          font-weight: var(--fw_medium);
          color: var(--main_gray);
          border: solid 1px var(--sub_gray);
          padding: 4px;
          &:focus {
            border: solid 1px var(--main_black);
          }
          @include tablet {
            font-size: var(--fs_18);
          }
        }
        label {
          position: relative;
          font-size: var(--fs_14);
          font-weight: var(--fw_medium);
          width: 120px;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
          }
          @include tablet {
            width: auto;
          }
          @include desktop {
            font-size: var(--fs_18);
          }
        } 
        &:nth-child(1) {
          label {
            padding-left: 24px;
            &::before {
              width: 19px;
              height: 12px;
              transform: translateY(-40%);
              background: url('/assets/svgs/containers/performance/barcode.svg');
            }
          }
        }
        &:nth-child(2) {
          label {
            padding-left: 18px;
            &::before {
              width: 13px;
              height: 16px;
              transform: translateY(-50%);
              background: url('/assets/svgs/containers/performance/calendar.svg');
            }
          }
        }
      }
    }
    button {
      margin-top: 40px;
      width: 80px;
      background-color: var(--main_green);
      color: var(--white_ff);
      padding: 4px 0;
      border-radius: 3px;
    }
  }
}
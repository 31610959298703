@import '/src/styles/media.scss';

.publicationReport {
  margin: 40px 0;
  @include tablet {
    margin: 60px 0;
  }
  @include desktop {
    margin: 80px 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      justify-content: space-between;
      gap: 50px;
      border: solid 1px var(--gray_dd);
      padding: 16px 10px;
      @include tablet {
        width: 48%;
      }
      @include desktop {
        width: 32%;
      }

      .leftArea {
        p {
          position: relative;
          font-size: var(--fs_12);
          color: var(--main_blue);
          @include tablet {
            font-size: var(--fs_14);
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -8px;
            width: 40px;
            height: 2px;
            background-color: var(--main_black);
          }
        }
        a {
          position: relative;
          display: inline-block;
          font-size: var(--fs_10);
          color: var(--main_right_gray);
          border: solid 1px var(--main_right_gray);
          padding: 2px 30px 2px 4px;
          margin-top: 40px;
          @include tablet {
            font-size: var(--fs_12);
          }

          &::before {
            content: '';
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 14px;
            background: url('/assets/svgs/containers/data/publication/PDF_download.svg')
              no-repeat center/cover;
          }
        }
      }

      img {
        width: 30%;
        max-width: 154px;
      }
    }
  }
}

@import '/src/styles/media.scss';

.milk {
  background: var(--admin_bg) url('/assets/images/common/milk_gene_bg.png') no-repeat center bottom 100px;
  background-size: 100% auto;

  @include desktop {
    display: flex;
    justify-content: center;
  }
  .milkInner {
    @include desktop {
      min-height: 100vh;
      max-width: 1000px;
      flex: 1;
    }

    .milkInnerHead {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      box-shadow: 0 8px 28px 9px rgba(72,89,102,.1);
      border-radius: 16px;
      background-color: var(--white_ff);
      padding: 10px 0;
      @include laptop {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px 25px;
      }

      .visit {
        display: flex;
        gap: 36px;
        border: solid 1px var(--admin_right_blue);
        border-radius: 12px;
        padding: 8px 25px;

        li {
          font-size: var(--fs_14);

          span {
            font-weight: var(--fw_bold);
          }
        }
      }
      .user {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        @include tablet {
          flex-direction: row;
          gap: 20px;
        }

        ul {
          display: flex;
          gap: 20px;
  
          li {
            font-size: var(--fs_14);
            font-weight: var(--fw_medium);
          }
        }
        button {
          padding: 4px 8px;
          border-radius: 4px;
          background-color: var(--admin_orange);
          color: var(--white_ff);
        }
      }
    }
    .milkBody {
      margin: 10px 0;
      background-color: var(--white_ff);
      border-radius: 16px;
      box-shadow: 0 4px 4px 0 rgba(0,0,0,.05);
      @include desktop {
        min-height: calc(100vh - 98px);
      }
    }
  }
  // 공통
  table {
    text-align: center;
    border-collapse: collapse;
    border-top: solid 1px var(--admin_black);
    
    th {
      font-size: var(--fs_14);
      font-weight: var(--fw_medium);
      background-color: var(--gray_f2);
      padding: 4px 0;
      @include laptop {
        padding: 10px 0;
      }
    }
    td {
      font-size: var(--fs_12);
      border: solid 1px var(--gray_f2);
      padding: 4px 2px;
      &.numberCon {
        text-align: end;
      }
      @include laptop {
        font-size: var(--fs_14);
        padding: 6px 4px;
      }
    }
  }
}